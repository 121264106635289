export default function filterHour(items: SearchObject[], hour?: number) {
  return typeof hour === "number"
    ? items.filter((item) => {
        const timestamp =
          item["timestamp"] ||
          item["start_date"] ||
          item["start_datetime"] ||
          null;
        if (!timestamp) return false;
        const date = new Date(timestamp);
        const hours = date.getUTCHours();
        return hours === hour;
      })
    : items;
}
