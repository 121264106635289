import { useContext } from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";
import ValueContainer from "./ValueContainer";
import { TutorialContext } from "../../providers/tutorial";
import StepPopup from "../tutorial/step";

const OssSelect = ({
  className = "",
  tutorial = false,
  areGrouped = false,
  optionsSelected,
  ...props
}) => {
  const { step, changeStep, isFinished } = useContext(TutorialContext);

  const tutorialVisible =
    tutorial &&
    !isFinished &&
    step === 5 &&
    props.placeholder === "Social Media";

  if (props.allowSelectAll) {
    return (
      <div className={`relative ${tutorialVisible ? "z-20" : ""} ${className}`}>
        <ReactSelect
          {...props}
          className="text-sm"
          options={[props.allOption, ...props.options]}
          placeholder={props.placeholder}
          value={optionsSelected}
          onChange={(selected) => {
            if (
              selected !== null &&
              selected.length > 0 &&
              selected.find((item) => item.value === "*")
            ) {
              const options = areGrouped
                ? props.options.flatMap((item) => item.options)
                : props.options;

              props.handleMultiSelectChange(props.placeholder, options);
            } else {
              props.handleMultiSelectChange(props.placeholder, selected);
            }
            tutorialVisible && changeStep();
          }}
          optionsSelected={optionsSelected}
          hideSelectedOptions={false}
          components={{ ValueContainer }}
        />
        {tutorialVisible && (
          <StepPopup
            title="Choose an object type"
            paragraph="For this example use the social media category. Expand the dropdown and choose from the available options."
            actionText="Click on an option"
            position="left-[110%] top-[120%]"
          />
        )}
      </div>
    );
  }

  return <ReactSelect {...props} />;
};

OssSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

OssSelect.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
};

export default OssSelect;
