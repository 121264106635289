import React from "react";
import { components } from "react-select";

const ValueContainer = ({ children, hasValue, ...props }) => {
  const optionsSelected = hasValue ? props.selectProps.optionsSelected : [];

  const CHIPS_LIMIT = 3;
  const [, otherChildren] = children;
  const overflowCounter = optionsSelected.slice(CHIPS_LIMIT).length;
  const displayChips = optionsSelected
    .slice(overflowCounter, overflowCounter + CHIPS_LIMIT)
    .map((chip) => (
      <span
        key={chip.value}
        className="bg-[var(--primary-color)] w-max min-w-max text-white px-1 py-0.5 rounded text-sm"
      >
        {chip.label || chip.title}
      </span>
    ));

  const plural = overflowCounter === 3 + 1 ? "" : "s";

  return (
    <components.ValueContainer
      className={
        hasValue && "!flex !items-center !flex-nowrap overflow-hidden gap-2"
      }
      {...props}
    >
      {displayChips}
      {overflowCounter > 0 && (
        <span className="text-[var(--text-color)] text-sm w-max min-w-max">
          + {overflowCounter} other{plural}
        </span>
      )}
      {otherChildren}
      {!hasValue && (
        <span className="absolute left-2 text-[var(--input-color)]">
          {props.selectProps.placeholder}
        </span>
      )}
    </components.ValueContainer>
  );
};

export default ValueContainer;
