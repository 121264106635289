export async function getCoordinatesItems(
  token: string,
  workspaceId: number,
  type: string,
  params: Record<string, string | number | null>
) {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    value !== null && searchParams.set(key, value.toString());
  });

  const url = `${
    process.env.REACT_APP_API_SERVER_URL
  }/api/getCoordinatesItems/${workspaceId}/${type}?${searchParams.toString()}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  return data;
}
