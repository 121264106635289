import { CircleHelp } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faFlagUsa,
  faUsersViewfinder,
  faLocationDot,
  faCalendarDays,
  faComment,
} from "@fortawesome/free-solid-svg-icons";

export default function Legend() {
  return (
    <Popover>
      <PopoverTrigger>
        <div className="text-[var(--text-color)] opacity-80 text-lg flex items-center gap-2 h-8 px-2 rounded-full justify-center hover:bg-[var(--primary-color-light)]">
          <CircleHelp size={16} />
          <span className="text-base">Legend</span>
        </div>
      </PopoverTrigger>
      <PopoverContent className="text-[var(--text-color)] flex flex-col gap-2 max-w-max">
        <div className="flex items-center gap-2 opacity-80 text-base">
          <div className="w-4 h-4 grid place-content-center">
            <FontAwesomeIcon icon={faFlagUsa} />
          </div>
          Works only in US
        </div>
        {/* Inject content */}
        <div className="flex items-center gap-2 opacity-80 text-base">
          <div className="w-4 h-4 grid place-content-center">
            <FontAwesomeIcon icon={faUsersViewfinder} />
          </div>
          Works on bounding box
        </div>
        <div className="flex items-center gap-2 opacity-80 text-base">
          <div className="w-4 h-4 grid place-content-center">
            <FontAwesomeIcon icon={faLocationDot} />
          </div>
          Works on radius
        </div>
        <div className="flex items-center gap-2 opacity-80 text-base">
          <div className="w-4 h-4 grid place-content-center">
            <FontAwesomeIcon icon={faCalendarDays} />
          </div>
          Supports timestamp
        </div>
        <div className="flex items-center gap-2 opacity-80 text-base">
          <div className="w-4 h-4 grid place-content-center">
            <FontAwesomeIcon icon={faComment} />
          </div>
          Supports keywords
        </div>
        <div className="flex items-center gap-2 opacity-80 text-base">
          <div className="w-4 h-4 grid place-content-center">
            <FontAwesomeIcon icon={faClock} />
          </div>
          Might take longer time to return results
        </div>
      </PopoverContent>
    </Popover>
  );
}
