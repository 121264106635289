import { Card, CardTitle } from "@/components/ui/card";
import { ICON_TYPES, MODULES } from "../../../const/search";
import PriceChart from "../ui/price";
import Timestamp from "../ui/timestamp";
import UserActivity from "../ui/user-activity";

export default function EventBriteStatsList({
  activity,
  price,
  ...records
}: EventBriteStats) {
  const { color } = MODULES.find((item) => item.value === "eventbrite");
  return (
    <Card className="p-4 space-y-4 w-max rounded flex-row flex gap-4">
      <div className="flex flex-col justify-between gap-4">
        <div className="flex items-center gap-4 h-max">
          <div className="w-11 h-11 rounded overflow-hidden relative">
            <img
              className="absolute inset-0 w-full h-full object-cover object-center"
              src={ICON_TYPES["eventbrite"]}
              alt=""
            />
          </div>
          <div>
            <CardTitle className="text-xl">Eventbrite</CardTitle>
            <p className="text-[var(--text-color)] opacity-80 text-lg">Stats</p>
          </div>
        </div>
        {records.first && records.last && (
          <Timestamp stroke={color} {...records} />
        )}
      </div>
      {activity && (
        <div className="self-end">
          <UserActivity
            stroke={color}
            className="max-h-[10rem]"
            activity={activity}
          />
        </div>
      )}
      <PriceChart color={color} price={price} />
    </Card>
  );
}
