import ObjectRef from "../../components/tile-items/ObjectRef";
import { Card, CardTitle } from "@/components/ui/card";
import DataTable from "@/components/tile-items/data-table";

type Props = {
  index: number;
  items: SearchObject[];
  authorDataEnabled: boolean;
  colSpan?: number;
};

export default function FeaturedBox(props: Props) {
  const { items, index, authorDataEnabled, colSpan = 1 } = props;
  if (!Array.isArray(items) || items.length === 0) return <></>;

  return items.some((item) => item.photo_url || item.screenshot) ? (
    <Card style={{ gridColumn: `span ${colSpan} / span ${colSpan}` }}>
      <CardTitle>Featured {index + 1}</CardTitle>
      <div className="grid gap-6 grid-cols-[repeat(auto-fill,minmax(340px,1fr))]">
        {items.map((event) => (
          <ObjectRef
            {...(event as any)}
            showTypeIcon
            authorDataEnabled={authorDataEnabled}
            key={event.type + event.id}
          />
        ))}
      </div>
    </Card>
  ) : (
    <div className="col-span-2">
      <DataTable
        records={items}
        type={items[0].type}
        authorDataVisible={authorDataEnabled}
      />
    </div>
  );
}
