import { DATE_KEYS } from "../../const/general";

export default function AttributeField({
  name,
  value,
  cols = 1,
  width = undefined,
}) {
  return (
    <td colSpan={cols} style={{ width }}>
      <div className="flex flex-col gap-1 text-[var(--text-color)]">
        <span className="opacity-80 text-sm">{name}</span>
        <span className="text-base break-words text-ellipsis line-clamp-6">
          {DATE_KEYS.includes(name)
            ? new Date(value).toLocaleDateString() +
              " " +
              new Date(value).toLocaleTimeString()
            : value || "None"}
        </span>
      </div>
    </td>
  );
}
