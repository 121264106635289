export default function WikilocIcon() {
  return (
    <svg
      height="100%"
      width="100%"
      version="1.1"
      viewBox="0 0 290 290"
      stroke="inherit"
    >
      <g>
        <g>
          <g>
            <g>
              <path
                fill="inherit"
                d="M242.7,100.2c3.3-9.6,3.3-17,3.3-17c-0.6-43.6-54.5-57.3-63.6-59.3c8.7,5.2,22.1,18.8,29,
30.8c10.4,21.4,3,34.7-8.5,43c2.2-4.4,4.6-11,4.9-19.4c0.5-11-1.5-29.7-29.5-56.3c-15.4-7.4-32.6-11.5-50.8-11.5C62.6,10.5,10,63.1,
10,128c0,12.3,1.9,24.2,5.4,35.4c-6.6,56.8,67,73.5,74.5,76c-7.9-4.2-16.7-10.2-20.8-14.5c-30.7-27.1,1.8-53.4,1.8-53.4C94.9,150,142,
149.4,142,149.4c23.6-0.6,53.7-9.2,53.7-9.2c21.1-4.8,33.3-15.7,40.4-26.6c-21.5,41.7-80,44.7-80.7,44.7c-27.3,1.3-44.5,6.9-44.7,6.9c-36.4,
11.8-36.1,35.8-36.4,37.5c-0.2,19.9,10.3,30.7,21.6,38c10.4,3,20.1,4.6,31.5,4.6c64.9,0,117.5-52.6,117.5-117.5c0-9-1-17.7-2.9-26.2L242.7,100.2z
M168.6,110.3c-17.5,2-72.2,5-72.2,5c-57.7,6.1-74.1,33.3-77.1,39.5c-2.1-8.6-3.3-17.5-3.3-26.7C16,66.6,66,16.6,127.5,16.6c21,0,40.7,5.8,57.4,16c14,
14.8,21.1,30.3,20.5,45.5c-0.4,11.1-4.7,19.1-6.8,22.4C185,108.3,168.6,110.3,168.6,110.3z M127.5,239.5c-11.1,
0-21.9-1.7-32.1-4.7l-1.6-0.9c-0.2-0.1-17-9.6-17-31.1c-0.1-0.9-1.4-23.2,34.7-35.2c0.2,0,17.1-5.5,44.1-6.8c0.2,0,20.1-0.8,38.3-8.7c22.5-9.7,36.8-22,44.1-38.4c0.6,4.7,0.9,9.5,0.9,14.4C238.9,189.5,189,239.5,127.5,239.5z"
              />
            </g>
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </g>
      </g>
    </svg>
  );
}
