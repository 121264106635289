export default function SurveillanceIcon() {
  return (
    <svg
      fill="inherit"
      height="100%"
      width="100%"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 503.607 503.607"
    >
      <g transform="translate(1 1)">
        <g>
          <path
            d="M494.213,240.731h-16.787c-14.269,0-25.18,10.911-25.18,25.18v43.646H338.611c-9.237-10.287-22.618-16.787-37.447-16.787
			c-1.619,0-3.219,0.085-4.8,0.236l-30.033-30.033l97.784-97.784c3.357-3.357,3.357-8.393,0-11.751l-44.743-45.986
			c-0.378-0.656-0.847-1.283-1.421-1.857c-0.511-0.511-1.064-0.934-1.64-1.289l-42.845-44.035c-1.679-1.679-3.357-2.518-5.875-2.518
			c-2.518,0-4.197,0.839-5.875,2.518L22.502,293.61c-1.679,1.679-2.518,3.357-2.518,5.875c0,2.518,0.839,4.197,2.518,5.875
			l5.875,5.875L11.59,328.023c-1.679,1.679-2.518,3.357-2.518,5.875s0.839,4.197,2.518,5.875l6.295,6.295L1.518,362.436
			c-3.357,3.357-3.357,8.393,0,11.751l47.843,47.843c1.679,1.679,3.357,2.518,5.875,2.518c1.679,0,4.197-0.839,5.036-2.518
			l16.787-16.787l5.875,5.875c1.679,1.679,3.357,2.518,5.875,2.518c2.518,0,5.036-0.839,5.875-2.518l16.787-16.787l5.875,5.875
			c1.679,1.679,3.357,2.518,5.875,2.518s4.197-0.839,5.875-2.518l90.229-90.229l31.517,31.517c-0.018,0.545-0.042,1.088-0.042,1.637
			c0,27.698,22.662,50.361,50.361,50.361c14.829,0,28.21-6.5,37.447-16.787h113.635v41.967c0,14.269,10.911,25.18,25.18,25.18
			h16.787c5.036,0,8.393-3.357,8.393-8.393V249.125C502.607,244.088,499.249,240.731,494.213,240.731z M54.397,403.564
			l-35.252-35.253l10.911-10.072l35.253,35.253L54.397,403.564z M88.81,393.492l-29.36-29.779l-23.519-23.939
			c-0.229-0.229-0.467-0.439-0.711-0.637l-5.165-5.239l10.072-10.911l59.593,59.593L88.81,393.492z M123.223,382.58l-5.875-5.875
			l-71.344-71.344l-5.875-5.875L267.59,77.898l32.633,33.675l-18.365,18.364c-3.357,3.357-3.357,8.393,0,11.751
			c1.679,1.679,3.357,2.518,5.875,2.518c1.679,0,4.197-0.839,5.875-2.518l18.18-18.18l11.566,11.935L293.61,165.19
			c-3.357,3.357-3.357,8.393,0,11.751c1.679,1.679,3.357,2.518,5.875,2.518c1.679,0,4.197-0.839,5.875-2.518l29.562-29.562
			l11.566,11.935L123.223,382.58z M231.498,298.646L255,275.144l23.212,23.212c-10.085,5.208-18.208,13.719-22.919,24.084
			L231.498,298.646z M301.164,376.705c-18.466,0-33.574-15.108-33.574-33.574c0-13.531,8.117-25.251,19.719-30.553
			c2.276-1.001,4.669-1.746,7.14-2.182c0.459-0.115,0.899-0.249,1.325-0.398c1.757-0.287,3.556-0.442,5.39-0.442
			c11.515,0,21.723,5.876,27.778,14.777c2.589,3.946,4.423,8.255,5.268,12.926c0.029,0.161,0.061,0.322,0.087,0.484
			c0.042,0.255,0.078,0.513,0.114,0.77c0.039,0.281,0.076,0.562,0.108,0.845c0.024,0.212,0.047,0.424,0.067,0.637
			c0.032,0.345,0.058,0.691,0.08,1.038c0.011,0.172,0.024,0.343,0.032,0.516c0.024,0.52,0.04,1.042,0.04,1.568
			c0,0.004,0,0.009,0,0.013s0,0.009,0,0.013c0,0.526-0.016,1.048-0.04,1.568c-0.008,0.173-0.022,0.344-0.032,0.516
			c-0.022,0.347-0.047,0.694-0.08,1.038c-0.02,0.213-0.043,0.425-0.067,0.637c-0.032,0.283-0.069,0.564-0.108,0.845
			c-0.036,0.258-0.072,0.515-0.114,0.771c-0.026,0.162-0.058,0.322-0.087,0.483c-0.845,4.671-2.679,8.98-5.268,12.926
			C322.888,370.828,312.679,376.705,301.164,376.705z M349.007,359.918c1.679-5.036,3.357-10.911,3.357-16.787
			s-1.679-11.751-3.357-16.787h103.239v33.574H349.007z M485.82,427.066h-8.393c-5.036,0-8.393-3.357-8.393-8.393v-50.361v-50.361
			v-52.039c0-5.036,3.357-8.393,8.393-8.393h8.393V427.066z"
          />
        </g>
      </g>
    </svg>
  );
}
