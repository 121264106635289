import useSearch from "@/hooks/search/useSearch";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

interface FiltersContextType extends ReturnType<typeof useSearch> {
  keyword?: string;
  startDate?: Date;
  endDate?: Date;
  setKeyword: Dispatch<SetStateAction<string | undefined>>;
  setStartDate: Dispatch<SetStateAction<Date | undefined>>;
  setEndDate: Dispatch<SetStateAction<Date | undefined>>;
}

const FiltersContext = createContext<FiltersContextType>(null!);

export default function useFilters() {
  const filters = useContext(FiltersContext);
  return filters;
}

export function FiltersProvider({ children }: { children: React.ReactNode }) {
  const [keyword, setKeyword] = useState<string>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const search = useSearch();
  return (
    <FiltersContext.Provider
      value={{
        keyword,
        setKeyword,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        ...search,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}
