import { faArrowDown, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function StepPopup({
  title,
  paragraph,
  actionText,
  position = "left-[120%] top-[120%]",
  icon = faArrowDown,
  iconSize = "1x",
  iconPosition = "right-full bottom-full rotate-[120deg]",
}) {
  return (
    <div
      className={`absolute ${position} dark:bg-[var(--body-color)] bg-white py-8 px-12 w-[5in] flex flex-col items-center gap-6 z-10 text-center rounded border-[1px] border-[var(--primary-color-light)]`}
    >
      <div className="h-12 w-12 grid place-content-center text-[var(--input-font)] bg-pink-shade dark:bg-[var(--primary-color-light)] rounded-sm">
        <FontAwesomeIcon icon={faWarning} />
      </div>
      <h3 className="text-[var(--text-color)] text-2xl">{title}</h3>
      {paragraph && (
        <p className="text-[var(--text-color)] opacity-80 text-lg">
          {paragraph}
        </p>
      )}
      <p className="text-[var(--primary-color2)] text-sm font-medium animate-pulse">
        {actionText}
      </p>
      <div
        className={`absolute ${iconPosition} text-[var(--primary-color2)] pointer-events-none`}
      >
        <div className="animate-point-to">
          <FontAwesomeIcon icon={icon} size={iconSize} />
        </div>
      </div>
    </div>
  );
}
