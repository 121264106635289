import { MouseEvent, useEffect, useMemo } from "react";
import YearsNav from "./years-nav";
import HourButton from "./hour-button";

type Props = {
  items: SearchObject[];
  dates: string[];
  activeDate: string | null;
  activeHour?: number;
  changeDate: (date: string) => void;
  changeHour: (hour: number) => void;
};

export default function Timeline({
  items,
  dates,
  activeHour,
  changeHour,
  activeDate,
  changeDate,
}: Props) {
  const hours = useMemo(
    () =>
      new Set(
        items
          .map((item) =>
            new Date(
              item["timestamp"] || item["start_date"] || item["start_datetime"]
            ).getUTCHours()
          )
          .sort((a, b) => a - b)
      ),
    [items]
  );

  const years = new Set(dates.map((date) => new Date(date).getFullYear()));

  useEffect(() => {
    const element = document.getElementById(`tl-button-${activeDate}`);
    element?.scrollIntoView({
      inline: "center",
      behavior: "smooth",
      block: "nearest",
    });
  }, [activeDate]);

  const handleYearScroll = (e: MouseEvent<HTMLButtonElement>, year: number) => {
    e.preventDefault();
    const date = dates.find((date) => new Date(date).getFullYear() === year);
    if (!date) return;
    const element = document.getElementById(`tl-button-${date}`);
    element?.scrollIntoView({
      behavior: "smooth",
      inline: "start",
      block: "nearest",
    });
  };

  return (
    <div className="flex flex-col gap-3 bg-[var(--sidebar-color)] rounded p-6 col-span-2">
      <h3 className="text-[var(--text-color)] font-medium">Timeline</h3>
      <div className="max-w-full overflow-x-auto overflow-y-hidden py-12 flex-1 flex items-center">
        <div className="flex items-start min-w-max gap-16 w-full justify-between relative">
          <div className="absolute right-0 left-0 w-full from-[#00E1FF] to-[#FF00CD] bg-gradient-to-r h-px rounded-full" />
          <div className="h-2.5 w-2.5 rounded-full bg-[var(--primary-color2)] relative -top-1" />
          {Array.from(dates).map((date, k, arr) => (
            <DateButton
              date={date}
              activeDate={activeDate}
              changeDate={changeDate}
              prevDate={new Date(arr[k - 1])}
              key={`date-button-${date}`}
            />
          ))}
          <div className="h-2.5 w-2.5 rounded-full bg-[var(--primary-color2)] relative -top-1" />
        </div>
      </div>
      <div className="flex justify-center">
        <YearsNav years={years} handleYearScroll={handleYearScroll} />
      </div>
      <div className="max-w-full overflow-x-auto overflow-y-hidden py-12 flex-1 flex items-center min-h-max">
        <div className="flex items-start min-w-max gap-16 w-full justify-between relative">
          <div className="absolute right-0 left-0 w-full from-[#00E1FF] to-[#FF00CD] bg-gradient-to-r h-px rounded-full" />
          <div className="h-2.5 w-2.5 rounded-full bg-[var(--primary-color2)] relative -top-1" />
          {Array.from(hours).map((hour) => (
            <HourButton
              items={items}
              hour={hour}
              activeHour={activeHour}
              changeHour={changeHour}
              key={`hour-button-${activeDate}-${hour}`}
            />
          ))}
          <div className="h-2.5 w-2.5 rounded-full bg-[var(--primary-color2)] relative -top-1" />
        </div>
      </div>
    </div>
  );
}

type DateButtonProps = {
  date: string;
  activeDate: string | null;
  changeDate: (date: string) => void;
  prevDate?: Date;
};

const today = new Date();

today.setHours(0, 0, 0, 0);

const DateButton = ({
  changeDate,
  date,
  prevDate,
  activeDate,
}: DateButtonProps) => {
  const timestamp = new Date(date);
  const year = timestamp.getFullYear();
  const month = timestamp.getMonth();
  const isFirstYear = year !== prevDate?.getFullYear();
  const isFirstMonth = month !== prevDate?.getMonth();
  const isFuture =
    timestamp.getTime() > today.getTime() &&
    (prevDate?.getTime() || 0) <= today.getTime();
  return (
    <button
      onClick={() => changeDate(date)}
      className="flex flex-col items-center relative -top-1.5 gap-2 w-6"
      id={`tl-button-${date}`}
    >
      {activeDate === date && (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 47 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute bottom-full"
        >
          <path
            d="M23.5 35L0.550327 0.500015L46.4497 0.500015L23.5 35Z"
            fill="#FF00CD"
          />
        </svg>
      )}
      <div
        className={`h-3.5 w-3.5 rounded-full ${
          activeDate === date
            ? "bg-[var(--primary-color)]"
            : "bg-[var(--primary-color2)]"
        }`}
      />
      <p className="text-[var(--text-color)] text-base">{date}</p>
      {(isFirstYear || isFirstMonth || isFuture) && activeDate !== date && (
        <div className="absolute w-px h-8 flex flex-col items-center bottom-full bg-[var(--primary-color2)]">
          <p className="text-base font-medium bg-[var(--sidebar-color)] text-[var(--primary-color2)]">
            {isFuture
              ? "Today"
              : isFirstYear
              ? year
              : isFirstMonth &&
                new Intl.DateTimeFormat("en-US", { month: "long" }).format(
                  timestamp
                )}
          </p>
        </div>
      )}
    </button>
  );
};
