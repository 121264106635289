export async function searchUsername(token, body) {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/searchUsername/`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  const data = await response.json();
  return data;
}

export async function getAllUsernames(token) {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/showUsernames/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = await response.json();
  return data;
}

export async function deleteUsername(token, body) {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/deleteUsername/`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  const data = await response.json();
  return data;
}

export async function getUsername(token, body) {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/getUsername/`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  const data = await response.json();
  return data;
}
