import { useContext, useEffect, useRef, useState } from "react";
import { changeLocation } from "../../utils/single-item/lib";
import { useAuth0 } from "@auth0/auth0-react";
import toast from "react-hot-toast";
import Toast from "../../components/Toast";
import { WorkspaceContext } from "../../providers/workspace";

export default function useLocation(
  params: SingleItemParams,
  map: google.maps.Map | null,
  initialLocation?: Coords
) {
  const { workspace } = useContext(WorkspaceContext);
  const { getAccessTokenSilently } = useAuth0();
  const bounds = useRef<google.maps.LatLngBounds | null>(null);
  const [location, setLocation] = useState<Coords | undefined>(initialLocation);
  const [chosenPlace, setChosenPlace] = useState<Coords | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [searchBox, setSearchBox] =
  //   useState<google.maps.places.SearchBox | null>(null);

  async function handleChange() {
    if (!chosenPlace || !workspace) return;
    setIsLoading(true);
    const loading = toast.custom((t) => (
      <Toast
        {...t}
        title="Changing location"
        subtitle="Location is being changed for the given object"
      />
    ));
    const token = await getAccessTokenSilently();
    const { error } = await changeLocation(
      token,
      workspace.id,
      params.type,
      params.item_id,
      chosenPlace
    );
    setIsLoading(false);
    toast.dismiss(loading);
    if (error) {
      toast.custom((t) => (
        <Toast {...t} isError title="Error" subtitle={error} />
      ));
    } else {
      setLocation(chosenPlace);
      setChosenPlace(null);
    }
  }

  const onPlaceChange = (coords: Coords) => setChosenPlace(coords);

  // const onSearchBoxLoad = (box: google.maps.places.SearchBox) =>
  //   setSearchBox(box);

  // function onSearch() {
  //   if (!searchBox) return;
  //   const query = searchBox.getPlaces();
  //   if (!query) return;
  //   const lat = query[0].geometry?.location?.lat() as number;
  //   const lng = query[0].geometry?.location?.lng() as number;
  //   setChosenPlace({ lat, lng });
  //   map?.setCenter({ lat, lng });
  // }

  useEffect(() => {
    if (!initialLocation) return;
    setLocation(initialLocation);
  }, [initialLocation]);

  return {
    isLoading,
    location,
    // bounds: bounds.current,
    chosenPlace,
    onPlaceChange,
    handleChange,
    // onSearch,
    // onSearchBoxLoad,
    setBounds: (bnds: google.maps.LatLngBounds | null) =>
      (bounds.current = bnds),
  };
}
