import { Label } from "@/components/ui/label";
import useFilters from "@/providers/search/filters";

export default function KeywordInput() {
  const { keyword, setKeyword } = useFilters();
  return (
    <div className="flex flex-col gap-2">
      <Label className="text-sm font-normal opacity-80" htmlFor="keyword">
        Keyword
      </Label>
      <div>
        <input
          type="text"
          placeholder="Keyword"
          className="px-3 py-2 max-w-[232px] border border-slate-200 bg-white dark:border-[#1F242F] dark:bg-[#0C111D] rounded-md text-font text-base outline-none placeholder:text-slate-500 dark:placeholder:text-slate-400"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>
    </div>
  );
}
