import { useState, useEffect, useContext, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { WorkspaceContext } from "../../providers/workspace";
import Map from "../../components/map/Map";
import ObjectRef from "../../components/tile-items/ObjectRef";
import filterDate from "../../utils/timeline/filter-hour";
import TypeCounterChart from "../../components/timeline/type-counter-chart";
import Timeline from "../../components/timeline/timeline";
import { InfoWindowF, MarkerClustererF } from "@react-google-maps/api";
import CustomMarker from "../../components/map/CustomMarker";
import { clusterIcons } from "../../const/search";
import useMap from "../../hooks/useMap";
import InfoWindowContent from "../../components/tile-items/InfoWindowContent";
import filterHour from "../../utils/timeline/filter-hour";
import constructDateString from "../../utils/construct-date";

export default function TimelinePage({ sidebarShown }: PageProps) {
  const { workspace } = useContext(WorkspaceContext);
  const { getAccessTokenSilently } = useAuth0();
  const [infoWindowId, setInfoWindowId] = useState<number | null>(null);
  const [items, setItems] = useState<SearchObject[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [hour, setHour] = useState<number>();
  const [date, setDate] = useState<string>(constructDateString(new Date()));
  const [timelineDates, setTimelineDates] = useState<string[]>([]);
  const { fitBounds } = useMap();
  const [isLoading, setIsLoading] = useState({
    page: true,
    removal: false,
  });

  useEffect(() => {
    if (!workspace) return;
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/getTimeline/${workspace.id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ date }),
          }
        );
        const data = await response.json();
        const items = data.objects;
        const timeline_dates = data.dates;
        setItems(items);
        setTimelineDates(timeline_dates);
        // if there's no date (initial state) set the date to the most recent
        // if (!date && timeline_dates.length > 0) {
        //   setDate(timeline_dates[timeline_dates.length - 1]);
        // }
        // disable loading only when there are results (first request is only for dates)
        setIsLoading((prev) => ({ ...prev, page: false }));
        // }
      } catch (err) {
        // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
        console.error(err);
        setError(err as Error);
        setIsLoading((prev) => ({ ...prev, page: false }));
      }
    })();
  }, [getAccessTokenSilently, workspace, date]);

  const markers = useMemo(
    () =>
      filterHour(items, hour)
        // reduce items to create a { [type]: number } counter
        .reduce((prev, curr) => {
          const newArr = [...prev];
          // find index of type count of this type
          const index = newArr.findIndex((item) => item.type === curr.type);
          if (index !== -1) {
            // if it exists, update the count value to prev + 1
            const newObj = Object.assign({}, newArr[index]);
            newObj.objects = [...newObj.objects, curr];
            newArr[index] = newObj;
            return newArr;
          } else {
            // if it doesn't exist, push a new object with item's type
            return [...prev, { type: curr.type, objects: [curr] }];
          }
        }, [] as Marker[]),
    [items, hour]
  );

  // every time markers change, fit bounds of the map for each object
  useEffect(() => {
    if (items.length === 0) return;
    const coords = filterHour(items, hour).map((item) => item.location);
    fitBounds(coords);
  }, [items, hour, fitBounds]);

  if (isLoading.page) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <l-waveform size={48} color="white" />
      </div>
    );
  }

  if (error) return <div>Error: {error.message}</div>;

  return (
    <section
      className={`home transition-all ${
        sidebarShown ? "md:ml-[250px]" : "md:ml-[88px]"
      } flex flex-col gap-4 w-full sm:px-8 py-8 database_wrapper lg:grid grid-cols-[2fr_1fr]`}
    >
      <div className="flex flex-col gap-3 bg-[var(--sidebar-color)] rounded p-6">
        <h3 className="text-[var(--text-color)] font-medium">Map</h3>
        <div className="min-h-[3in] flex-1">
          <Map>
            {markers.map(({ type, objects }) => (
              <MarkerClustererF
                styles={[
                  {
                    backgroundPosition: "3% -14%",
                    height: 74,
                    textColor: "#FFFFFF",
                    width: 74,
                    url: clusterIcons[type as keyof typeof clusterIcons],
                  },
                ]}
                key={type}
              >
                {(clusterer) =>
                  objects.map((obj) => (
                    <CustomMarker
                      {...obj}
                      clusterer={clusterer}
                      position={obj.location}
                      onClick={() => setInfoWindowId(obj.id)}
                      key={type + obj.id}
                    >
                      {infoWindowId === obj.id && (
                        <InfoWindowF
                          position={obj.location}
                          onCloseClick={() => setInfoWindowId(null)}
                        >
                          <InfoWindowContent obj={obj} />
                        </InfoWindowF>
                      )}
                    </CustomMarker>
                  )) as any
                }
              </MarkerClustererF>
            ))}
          </Map>
        </div>
      </div>
      <TypeCounterChart markers={markers} date={date} />
      <Timeline
        items={items}
        dates={timelineDates}
        activeDate={date}
        activeHour={hour}
        changeHour={(hr) => setHour(hr)}
        changeDate={(dt) => {
          setDate(dt);
          setHour(undefined);
        }}
      />
      {date && (
        <div className="grid gap-6 grid-cols-[repeat(auto-fill,minmax(340px,1fr))] col-span-2">
          {filterDate(items, hour).map((item) => (
            <ObjectRef
              {...(item as any)}
              showTypeIcon
              key={item.type + item.id}
            />
          ))}
        </div>
      )}
    </section>
  );
}
