import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardTitle } from "../ui/card";

export default function Places({ places }: { places: string[] }) {
  if (!places || places.length === 0) return <></>;
  return (
    <Card className="p-4 space-y-2 w-max rounded flex flex-col">
      <div>
        <CardTitle>Places</CardTitle>
      </div>
      <div className="flex-1 flex flex-col justify-center gap-2">
        <div className="flex items-center gap-1.5">
          <div style={{ color: "#376187" }} className="h-6 w-5">
            <FontAwesomeIcon icon={faList} size="sm" />
          </div>
          <h4 className="text-[var(--text-color)] opacity-40 text-base">
            List
          </h4>
        </div>
        <ol className="rounded flex-1 list-decimal list-inside px-4 py-4 max-h-[8rem] hide-scroll bg-[#D9D9D9]/10 gap-1 self-center flex flex-col overflow-y-scroll overflow-x-hidden max-w-[14rem]">
          {places
            .filter((place) => place)
            .map((place, i) => (
              <li
                className="text-lg font-medium text-[var(--text-color)]"
                key={`place:${i}`}
              >
                {place}
              </li>
            ))}
        </ol>
      </div>
    </Card>
  );
}
