import { AUTHOR_EXCLUSIONS, FIELD_COL_SPAN } from "../../const/single-item";
import AttributeField from "../tile-items/AttributeField";
import { ICON_TYPES } from "../../const/search/index";
import { cn } from "@/utils/cn";
import { Button } from "../ui/button";
import { Link } from "react-router-dom";

export default function AuthorRef({ person, wasSearched = false }) {
  const keys = Object.keys(person).filter(
    (key) => !AUTHOR_EXCLUSIONS.includes(key) && typeof person[key] !== "object"
  );
  const withoutLong = keys.filter((key) => !FIELD_COL_SPAN.includes(key));
  const onlyLong = keys.filter((key) => FIELD_COL_SPAN.includes(key));
  const hasImageOrName = !!(
    person["photo_url"] ||
    person["username"] ||
    person["first_name"] ||
    person["last_name"]
  );
  return (
    <div
      className={cn(
        "h-max",
        person["post_photo_url"]
          ? `grid grid-cols-[2in_1fr] gap-6 ${wasSearched ? "col-span-2" : ""}`
          : "flex flex-col gap-3",
        wasSearched
          ? "rounded bg-[var(--sidebar-color)] px-6 py-6"
          : "py-4 border-b last:border-b-0 dark:border-white/20 border-black/20"
      )}
    >
      {wasSearched && (
        <div className="col-span-2">
          <img
            className="max-w-[24px] relative z-10"
            src={ICON_TYPES[person.type]}
            alt=""
          />
        </div>
      )}
      {(person["post_photo_url"] || person["post_url"]) && (
        <div className="flex flex-col gap-3">
          {person["post_photo_url"] && (
            <img
              className="h-full object-contain object-top"
              src={person["post_photo_url"]}
              alt=""
            />
          )}
          {person["post_url"] && (
            <Button asChild>
              <Link to={person["profile_url"]}>Visit post</Link>
            </Button>
          )}
        </div>
      )}
      <div className={cn("grid grid-cols-[96px_1fr] gap-3")}>
        <div className="flex flex-col gap-6 justify-between items-center">
          {hasImageOrName && (
            <div className="flex flex-col items-center gap-3">
              {person["photo_url"] && (
                <div className="h-20 w-20 relative rounded-full overflow-hidden">
                  <img
                    className="w-full h-full"
                    src={person["photo_url"]}
                    alt=""
                  />
                </div>
              )}
              <p className="text-base break-words text-ellipsis text-font text-center">
                {person["username"] ||
                  [person["first_name"], person["last_name"]].join(" ") ||
                  ""}
              </p>
            </div>
          )}
          {person.url && (
            <Button asChild>
              <Link to={person.url} rel="noreferrer" target="_blank">
                Visit URL
              </Link>
            </Button>
          )}
        </div>
        <table className="table-fixed w-full">
          <tbody>
            {Array.from(Array(Math.ceil(withoutLong.length / 2))).map(
              (_, k, rows) => (
                <RowRef
                  withoutLong={withoutLong}
                  person={person}
                  rows={rows}
                  k={k}
                  key={`author-row-${k}`}
                />
              )
            )}
            {onlyLong.map((key, k) => (
              <LongRowRef
                name={key}
                value={person[key]}
                key={`author-long-row-${k}`}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const RowRef = ({ withoutLong, k, person, rows }) => (
  <tr className="[&>td]:py-2 even:bg-black/5 dark:even:bg-white/5 [&>td]:px-1 [&>td:first-child]:pl-3 [&>td:last-child]:pr-3">
    {withoutLong.slice(k * 2, k * 2 + 2).map((key, i) => (
      <AttributeField
        width="50%"
        colSpan={FIELD_COL_SPAN.includes(key) ? 2 : 1}
        name={key}
        value={person[key]}
        key={key + k + i}
      />
    ))}
    {k === rows.length - 1 && <td style={{ width: "50%" }}></td>}
  </tr>
);

const LongRowRef = ({ value, name }) => (
  <tr className="[&>td]:py-2 even:bg-black/5 dark:even:bg-white/5 [&>td]:px-1 [&>td:first-child]:pl-3 [&>td:last-child]:pr-3">
    <AttributeField cols={2} name={name} value={value} />
  </tr>
);
