import { ReactNode, useContext, useState } from "react";
import { createContext } from "react";

type TutorialContextType = {
  isFinished: boolean;
  step: number;
  changeStep: () => void;
  changeFinished: (finished: boolean) => void;
};

export const TutorialContext = createContext<TutorialContextType>(null!);

export function useTutorial() {
  const tutorialCtx = useContext(TutorialContext);
  return tutorialCtx;
}

const tutorialFromStorage = localStorage.getItem("tutorial-finished");
const parsedTutorial = tutorialFromStorage
  ? tutorialFromStorage === "true"
  : false;

export default function TutorialProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [step, setStep] = useState(-1);
  const [isFinished, setIsFinished] = useState<boolean>(parsedTutorial);

  function changeFinished(finished: boolean) {
    localStorage.setItem("tutorial-finished", String(finished));
    setIsFinished(Boolean(finished));
  }

  function changeStep() {
    const allFinished = step >= 7;
    if (allFinished) changeFinished(true);
    else setStep((prev) => prev + 1);
  }

  return (
    <TutorialContext.Provider
      value={{ isFinished, changeFinished, step, changeStep }}
    >
      {children}
    </TutorialContext.Provider>
  );
}
