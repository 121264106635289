import { Card, CardTitle } from "@/components/ui/card";
import { ICON_TYPES } from "../../../const/search";
import Timestamp from "../ui/timestamp";
import UserActivity from "../ui/user-activity";

export default function AlltrailsStatsList({
  stroke,
  duration,
  activity,
  ...records
}: AlltrailsStats & Pick<UI, "stroke">) {
  return (
    <Card className="p-4 w-max rounded grid grid-cols-2 gap-4">
      <div className="flex flex-col h-full">
        <div className="flex items-center gap-4 h-max">
          <div className="w-11 h-11 rounded overflow-hidden relative">
            <img
              className="absolute inset-0 w-full h-full object-cover object-center"
              src={ICON_TYPES["alltrails"]}
              alt=""
            />
          </div>
          <div>
            <CardTitle className="text-xl">Alltrails</CardTitle>
            <p className="text-[var(--text-color)] opacity-80 text-lg">
              User activity
            </p>
          </div>
        </div>
        <Timestamp stroke={stroke} {...records} />
      </div>
      <div className="flex flex-col gap-4 max-h-full">
        <UserActivity
          stroke={stroke}
          activity={activity}
          className="max-h-[6rem]"
        />
        <div className="gap-2 flex flex-col justfiy-end">
          <div className="flex items-center gap-1.5">
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0C15.514 0 20 4.486 20 10C20 15.514 15.514 20 10 20C4.486 20 0 15.514 0 10C0 4.486 4.486 0 10 0ZM10 1.5C5.313 1.5 1.5 5.313 1.5 10C1.5 14.687 5.313 18.5 10 18.5C14.687 18.5 18.5 14.687 18.5 10C18.5 5.313 14.687 1.5 10 1.5ZM9.6612 5.0954C10.0762 5.0954 10.4112 5.4314 10.4112 5.8454V10.2674L13.8162 12.2974C14.1712 12.5104 14.2882 12.9704 14.0762 13.3264C13.9352 13.5614 13.6862 13.6924 13.4312 13.6924C13.3002 13.6924 13.1682 13.6584 13.0472 13.5874L9.2772 11.3384C9.0512 11.2024 8.9112 10.9574 8.9112 10.6934V5.8454C8.9112 5.4314 9.2472 5.0954 9.6612 5.0954Z"
                fill="#65F67A"
              />
            </svg>
            <h4 className="text-[var(--text-color)] opacity-40 text-base">
              Duration
            </h4>
          </div>
          <div className="rounded px-3 py-2 bg-[#D9D9D9]/10">
            <p className="text-[var(--text-color)] text-center text-xl">
              {duration.toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
}
