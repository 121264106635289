import { Card, CardTitle } from "@/components/ui/card";
import { ICON_TYPES } from "../../../const/search";
import Timestamp from "../ui/timestamp";
import UserActivity from "../ui/user-activity";

export default function VKontakteStatsList({
  stroke,
  activity,
  ...records
}: VKontakteStats & Pick<UI, "stroke">) {
  return (
    <Card className="p-4 gap-4 w-max rounded flex flex-col">
      <div className="flex items-center gap-4 h-max">
        <div className="w-11 h-11 rounded overflow-hidden relative">
          <img
            className="absolute inset-0 w-full h-full object-cover object-center"
            src={ICON_TYPES["vkontakte"]}
            alt=""
          />
        </div>
        <div>
          <CardTitle className="text-xl">VKontakte</CardTitle>
          <p className="text-[var(--text-color)] opacity-80 text-lg">
            User activity
          </p>
        </div>
      </div>
      <div className="grid grid-cols-2 items-end gap-4 flex-1">
        <UserActivity
          stroke={stroke}
          className="max-h-[7rem]"
          activity={activity}
        />
        <Timestamp stroke={stroke} {...records} />
      </div>
    </Card>
  );
}
