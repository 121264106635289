export default function WorldcamIcon() {
  return (
    <svg
      fill="inherit"
      height="100%"
      width="100%"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 511.999 511.999"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <g>
            <path d="M481.812,361.585L266.04,145.813V10.041C266.04,4.496,261.546,0,255.999,0c-5.547,0-10.041,4.496-10.041,10.041v115.691 L182.787,62.56c-3.923-3.921-10.279-3.921-14.201,0L47.268,183.878c-3.921,3.921-3.921,10.279,0,14.199l20.238,20.238 l-37.321,37.321c-1.882,1.882-2.94,4.437-2.94,7.1s1.058,5.217,2.94,7.1l239.221,239.221c1.961,1.96,4.532,2.941,7.101,2.941 c2.569,0,5.14-0.98,7.101-2.941l25.355-25.355l20.24,20.241c1.884,1.884,4.437,2.941,7.101,2.941s5.216-1.058,7.101-2.941 l68.353-68.352c1.882-1.882,2.94-4.437,2.94-7.1c0-2.663-1.058-5.217-2.941-7.1l-1.556-1.556 c26.2-8.582,50.822-23.26,71.613-44.051C485.734,371.864,485.734,365.506,481.812,361.585z M255.999,240.256 c12.574,0,22.802,10.23,22.802,22.802c0,12.573-10.23,22.802-22.802,22.802s-22.802-10.23-22.802-22.802 C233.197,250.486,243.427,240.256,255.999,240.256z M301.862,462.403l-25.355,25.355L51.485,262.738l30.222-30.22l143.268,143.268 c31.033,31.033,70.594,48.463,111.21,52.296L301.862,462.403z M336.304,482.644l-13.14-13.142l40.936-40.936 c7.884-0.461,15.744-1.426,23.525-2.908l2.832,2.833L336.304,482.644z M239.176,361.585L68.568,190.978L175.687,83.859 l70.272,70.272v67.242c-18.817,4.533-32.843,21.497-32.843,41.686c0,23.647,19.237,42.884,42.884,42.884 s42.884-19.238,42.884-42.884c0-20.189-14.026-37.153-32.843-41.686v-47.16l194.254,194.254 C396.928,424.477,299.77,422.184,239.176,361.585z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
