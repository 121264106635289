export default function OutdooractiveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.4em"
      height="1.4em"
      viewBox="0 0 48 48"
      strokeWidth="3"
      stroke="inherit"
    >
      <ellipse
        cx={24}
        cy={24}
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
        rx={16.834}
        ry={19.5}
      ></ellipse>
      <ellipse
        cx={24}
        cy={24}
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
        rx={8.81}
        ry={11.691}
      ></ellipse>
    </svg>
  );
}
