// name of an array = name of type or shodan_type of module

export const TILE_ATTRIBUTE_EXCLUSIONS = {
  airbnb: [
    "id",
    "coordinates",
    "date_created",
    "search_request",
    "type",
    "listing_id",
    "notes",
    "photo_url",
    "url",
    "is_favorite",
  ],
  alltrails: [
    "coordinates",
    "search_request",
    "type",
    "slug",
    "url",
    "notes",
    "date_created",
    "all_trails_id",
    "photo_url",
    "url",
    "first_name",
    "last_name",
    "user_id",
    "is_favorite",
    "location",
  ],
  snapchat: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "id",
    "snap_id",
    "photo_url",
    "url",
    "is_favorite",
    "date_created",
  ],
  sports_tracker: [
    "photo_url",
    "url",
    "coordinates",
    "date_created",
    "search_request",
    "type",
    "notes",
    "distance",
    "duration",
    "workout_title",
    "workout_key",
    "stop_time",
    "timestamp",
    "description",
    "is_favorite",
  ],
  weibo: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "profile_url",
    "profile_image_url",
    "date_created",
    "id",
    "photo_url",
    "url",
    "is_favorite",
  ],
  flickr: [
    "coordinates",
    "date_created",
    "search_request",
    "type",
    "date_created",
    "notes",
    "id",
    "photo_url",
    "url",
    "is_favorite",
  ],
  vkontakte: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "id",
    "photo_url",
    "url",
    "is_favorite",
  ],
  diveboard: [
    "notes",
    "dive_notes",
    "spot_id",
    "dive_id",
    "search_request",
    "type",
    "duration",
    "user_id",
    "coordinates",
    "date_created",
    "photo_url",
    "url",
    "is_favorite",
    "location",
  ],
  youtube: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "id",
    "date_created",
    "photo_url",
    "url",
    "is_favorite",
  ],
  strava: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "start_position",
    "stop_position",
    "location",
    "photo_url",
    "url",
    "is_favorite",
  ],
  instagram: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "location",
    "date_created",
    "photo_url",
    "url",
    "is_favorite",
  ],

  // Public Cameras
  surveillance: [
    "url",
    "image_url",
    "notes",
    "live",
    "camera_id",
    "search_request",
    "type",
    "coordinates",
    "description",
    "camera_type",
    "date_created",
    "photo_url",
    "url",
    "is_favorite",
    "location",
  ],
  worldcam: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "live",
    "description",
    "camera_type",
    "timestamp",
    "id",
    "photo_url",
    "url",
    "is_favorite",
  ],
  traffic: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "live",
    "description",
    "camera_type",
    "date_created",
    "id",
    "photo_url",
    "url",
    "is_favorite",
  ],
  windy: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "camera_type",
    "date_created",
    "live",
    "id",
    "photo_url",
    "url",
    "is_favorite",
  ],

  ics: ["shodan_type", "url", "is_favorite", "location", "asn", "isp"],
  vulnerabilities: [
    "shodan_type",
    "indicator",
    "url",
    "is_favorite",
    "location",
    "asn",
    "isp",
  ],
  iot: ["shodan_type", "url", "is_favorite", "asn", "isp", "location"],

  crimes: [
    "title",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "description",
    "event_id",
    "event_type",
    "url",
    "photo_url",
    "is_favorite",
    "location",
  ],
  bluetooth: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "wigle_type",
    "is_favorite",
    "date_created",
    "location",
    "house_number",
  ],

  vessel: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "wigle_type",
    "type",
    "date_created",
    "is_favorite",
    "shipxplorer_id",
    "photo_url",
    "data_source",
  ],
  network: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "wigle_type",
    "type",
    "is_favorite",
    "date_created",
    "location",
    "house_number",
    "postal_code",
  ],
  wifi: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "wigle_type",
    "type",
    "is_favorite",
    "date_created",
    "location",
    "house_number",
    "postal_code",
  ],

  planes: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "radarbox_id",
    "squawk_code",
    "radar_id",
    "photo_url",
    "date_created",
    "is_favorite",
    "url",
    "wake",
    "radarbox_id",
  ],

  cell_tower: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "wigle_type",
    "is_favorite",
  ],
  exposed_cameras: [
    "indicator",
    "url",
    "type",
    "coordinates",
    "shodan_type",
    "screenshot",
    "is_favorite",
    "location",
    "asn",
    "isp",
  ],
  screenshots: [
    "url",
    "id",
    "indicator",
    "type",
    "coordinates",
    "shodan_type",
    "searched",
    "screenshot",
    "is_favorite",
  ],
  rtsp: [
    "url",
    "id",
    "indicator",
    "type",
    "coordinates",
    "shodan_type",
    "searched",
    "screenshot",
    "is_favorite",
  ],
  amber_alerts: [
    "crime_type",
    "description",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "event_type",
    "event_id",
    "is_favorite",
  ],
  amtrak: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "description",
    "is_favorite",
  ],
  incidents: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "description",
    "is_favorite",
    "location",
    "verified",
    "source",
    "start_datetime",
    "end_datetime",
    "incident_id",
    "road_closed",
    "origin",
    "destination",
  ],
  military: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "polygon",
    "description",
    "place_type",
    "way_id",
    "landuse",
    "is_favorite",
  ],
  power: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "polygon",
    "description",
    "place_type",
    "way_id",
    "landuse",
    "is_favorite",
  ],
  water: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "polygon",
    // "description",
    "place_type",
    "way_id",
    "landuse",
    "is_favorite",
  ],
  wikiloc: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
  ],
  offenders: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "aid",
    "is_favorite",
  ],
  ticketmaster: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "seatmap",
    "event_id",
  ],
  eventbrite: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "event_id",
    "end_date",
    "is_online",
    "tickets_url",
  ],
  zillow: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
  ],
  craigslist: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
  ],
  meetup: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "group_id",
    "photo_url_group",
  ],
  nextdoor: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "timestamp",
  ],
  sacral: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "polygon",
    "description",
    "place_type",
    "way_id",
    "landuse",
    "is_favorite",
  ],
  emergency: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "polygon",
    "description",
    "place_type",
    "way_id",
    "landuse",
    "is_favorite",
  ],
  communication: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "polygon",
    "description",
    "place_type",
    "way_id",
    "landuse",
    "is_favorite",
  ],
  transportation: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "polygon",
    "description",
    "place_type",
    "way_id",
    "landuse",
    "is_favorite",
  ],
  experiences: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
  ],
  outdooractive: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "event_id",
    "user_id",
  ],
  waze: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "uuid",
    "municipality_user",
    "text",
    "additional_info",
    "comments",
    "rating",
  ],
  police: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "uuid",
    "municipality_user",
  ],
  news: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "logo",
    "location",
    "news_id",
  ],
  findpenguins: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "photo_url",
    "is_favorite",
    "location",
  ],
  bikemap: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
  ],
  protests: [
    "tags",
    "event_id",
    "civilian_targeting",
    "geo_precision",
    "source_scale",
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "fatalities",
    "disorder",
  ],
  events1: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "event_id",
    "description",
  ],
  events2: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "event_id",
    "description",
    "details",
  ],
  events3: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "source",
  ],
  photo_geolocation: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "screenshot",
  ],
  crimes2: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
    "location",
    "irn",
  ],
  events4: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
    "event_id",
  ],
  events5: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
  ],
  booking: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
    "page_name",
    "place_id",
    "country_code",
  ],
  tiktok: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
  ],
  twitter: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
    "tweet_id",
    "quotes",
    "replies",
    "hashtags",
  ],
  facebook: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
    "quotes",
    "replies",
    "hashtags",
  ],
  telegram: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
    "thumbnail",
  ],
  trestle: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
    "trestle_id",
    "resident_id",
  ],
};

export const NO_MAP_MODULES = [""];
