import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { ICON_TYPES, MODULES } from "../../../const/search";
import List from "../ui/list";
import Timestamp from "../ui/timestamp";
import { Card, CardTitle } from "@/components/ui/card";

export default function CrimesStatsList({ types, ...records }: CrimesStats) {
  const { color } = MODULES.find((item) => item.value === "crimes");
  return (
    <Card className="p-4 space-y-4 w-max rounded grid grid-cols-2 gap-4">
      <div className="flex gap-4">
        <div className="w-11 h-11 rounded overflow-hidden relative">
          <img
            className="absolute inset-0 w-full h-full object-cover object-center"
            src={ICON_TYPES["crimes"]}
            alt=""
          />
        </div>
        <div>
          <CardTitle className="text-xl">Crimes</CardTitle>
          <p className="text-[var(--text-color)] opacity-80 text-lg">Stats</p>
        </div>
      </div>
      <Timestamp {...records} stroke={color} />
      <div className="col-[2/3] row-[1/3] self-end">
        <List
          records={Object.keys(types).map((key) => `${key}: ${types[key]}`)}
          title="Types"
          icon={faMagnifyingGlass}
          color={color}
        />
      </div>
    </Card>
  );
}
