import {
  Area,
  AreaChart as Chart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Card, CardTitle } from "../card";
import CustomTooltip from "./CustomTooltip";
import { cn } from "@/utils/cn";

type Props = {
  title: string;
  data: { value: number }[];
  isLoading?: boolean;
  className?: string;
  height?: number;
};

export default function AreaChart({
  title,
  data,
  className,
  isLoading,
  height = 320,
}: Props) {
  return (
    <Card className={cn(className)}>
      <CardTitle>
        {title.charAt(0).toUpperCase() + title.substring(1)}
      </CardTitle>
      {isLoading ? (
        <div className="w-full h-80 bg-[var(--body-color)] grid place-content-center">
          <img
            className="animate-spin"
            width={48}
            height={48}
            src="https://cdn.auth0.com/blog/hello-auth0/loader.svg"
            alt=""
          />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={height}>
          <Chart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 5,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#B692F6" stopOpacity={0.2} />
                <stop offset="95%" stopColor="#B692F6" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeWidth={1} stroke="#1F242F" vertical={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) =>
                new Intl.DateTimeFormat("en-US", {
                  month: "short",
                  day: "numeric",
                }).format(new Date(value))
              }
              tickMargin={12}
              tick={{ fill: "#94969C", fontSize: 14 }}
              type="category"
            />
            <YAxis
              tickFormatter={(tick) => Math.round(tick).toString()}
              axisLine={false}
              tickLine={false}
              tickMargin={12}
              tick={{ fill: "#94969C", fontSize: 14 }}
              type="number"
            />
            <Tooltip
              cursor={{ fill: "transparent" }}
              content={<CustomTooltip />}
            />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#ff00cd"
              strokeWidth={2}
              fill="url(#colorUv)"
            />
          </Chart>
        </ResponsiveContainer>
      )}
    </Card>
  );
}
