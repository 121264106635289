import { MouseEvent, useState } from "react";
import { Button } from "../ui/button";

type Props = {
  years: Set<number>;
  handleYearScroll: (e: MouseEvent<HTMLButtonElement>, year: number) => void;
};

export default function YearsNav({ years, handleYearScroll }: Props) {
  const [yearListIndex, setYearListIndex] = useState(0);
  const yearsPrevPage = years.size > 8 && yearListIndex > 0;
  const yearsNextPage = years.size > 8 && (yearListIndex + 1) * 8 < years.size;
  return (
    <div className="flex items-center gap-2">
      {yearsPrevPage && (
        <button
          onClick={() => setYearListIndex((prev) => prev - 1)}
          className="font-semibold text-xl text-[var(--primary-color2)] h-full"
        >
          ...
        </button>
      )}
      {[...years]
        .slice(yearListIndex * 8, yearListIndex * 8 + 8)
        .map((year) => (
          <Button
            variant="outline"
            onClick={(e) => handleYearScroll(e, year)}
            className="px-2 py-1 text-sm h-max"
            key={year}
          >
            {year}
          </Button>
        ))}
      {yearsNextPage && (
        <button
          onClick={() => setYearListIndex((prev) => prev + 1)}
          className="font-semibold text-xl text-[var(--primary-color2)] h-full"
        >
          ...
        </button>
      )}
    </div>
  );
}
