import { ICON_TYPES } from "../const/search";

const RADIAN = Math.PI / 180;

export default function renderCustomizedLabel(props: any, size: number = 16) {
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    payload: { name, category },
    percent,
  } = props;
  if (percent < 0.05) return <></>;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <image
      x={x - size / 2}
      y={y - size / 2}
      dominantBaseline="central"
      height={size}
      width={size}
      href={ICON_TYPES[(name || category) as keyof typeof ICON_TYPES]}
    />
  );
}
