import { useContext, useEffect, useMemo, useState } from "react";
import {
  InfoWindowF,
  MarkerClustererF,
  PolygonF,
} from "@react-google-maps/api";
import { useLocation, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ResponsiveContainer,
  BarChart,
  YAxis,
  XAxis,
  Tooltip,
  Bar,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";
import CustomTooltip from "../../components/ui/charts/CustomTooltip";
import { createTheme } from "react-data-table-component";
import Pagination from "../../components/pagination";
import { WorkspaceContext } from "../../providers/workspace";
import ObjectRef from "../../components/tile-items/ObjectRef";
import { PIE_CHART_COLORS, POLYGON_MODULES } from "../../const/general";
import {
  NO_MAP_MODULES,
  TILE_ATTRIBUTE_EXCLUSIONS,
} from "../../const/tile-items";
import CustomMarker from "../../components/map/CustomMarker";
import InfoWindowContent from "../../components/tile-items/InfoWindowContent";
import Map from "../../components/map/Map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFileArchive,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { SEARCH_OPTIONS, clusterIcons } from "../../const/search";
import useMap from "../../hooks/useMap";
import { Card, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button as ShadcnButton } from "@/components/ui/button";
import DataTable from "@/components/tile-items/data-table";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { cn } from "@/utils/cn";
import AreaChart from "@/components/ui/charts/area";

const google = (window.google = window.google ? window.google : {});

createTheme(
  "solarized",
  {
    text: {
      primary: "var(--primary-color2)",
      secondary: "var(--primary-color2)",
    },
    background: {
      default: "var(--sidebar-color)",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#073642",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const sortOptions = [
  { label: "Latest", value: "newest" },
  { label: "Oldest", value: "oldest" },
];

const limitOptions = [40, 50, 100, 200];

export default function CoordinatesItems(props) {
  const { pathname } = useLocation();
  const { map } = useMap();
  const [infoWindowId, setInfoWindowId] = useState(null);
  const { workspace } = useContext(WorkspaceContext);
  const [limit, setLimit] = useState(40); // Default limit
  const { type } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [pieChartData, setPieChartData] = useState([]);
  const [totalResults, setTotalResults] = useState(0); // Total number of results for the current search query
  const [barChartData, setBarChartData] = useState([]);
  const [authorDataEnabled, setAuthorDataEnabled] = useState(true);
  const [onlyFavourites, setOnlyFavourites] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [items1, setItems1] = useState([]);
  const [statsName1, setStatsName1] = useState("");
  const [statsName2, setStatsName2] = useState("");
  const [statsName3, setStatsName3] = useState("");
  const [timelineChartData, setTimelineChartData] = useState([]);
  //   const { map } = useMap();

  useEffect(() => {
    setIsLoading(true);
    setItems1([]);
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        let url = `${
          process.env.REACT_APP_API_SERVER_URL
        }/api/getCoordinatesItems/${
          workspace.id
        }/${type}?sort=${sortOption}&page=${currentPage}&limit=${limit}${
          onlyFavourites ? "&favorite=true" : ""
        }`;

        // Add search query to the URL if it's not empty
        if (searchQuery) {
          url += `&search=${searchQuery}`;
        }

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setItems1(data.results);
        setTotalPages(data.total_pages);
        // setPieChartData(data.statistics1.filter((item) => item.name));
        // setBarChartData(data.statistics2);
        setTotalResults(data.total_results);
        // setStatsName1(data.statistics1_name);
        // setStatsName2(data.statistics2_name);
      } catch (e) {
        // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    getAccessTokenSilently,
    workspace,
    type,
    searchQuery,
    onlyFavourites,
    sortOption,
    currentPage,
    limit,
  ]);

  useEffect(() => {
    setIsLoading(true);
    setPieChartData([]);
    setBarChartData([]);
    setTimelineChartData([]);
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        let url = `${process.env.REACT_APP_API_SERVER_URL}/api/getStatistics/${workspace.id}/${type}`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        // setItems1(data.results);
        // setTotalPages(data.total_pages);
        setPieChartData(data.statistics1.filter((item) => item.name));
        setBarChartData(data.statistics2);
        setTimelineChartData(data.statistics3);
        setStatsName3(data.statistics3_name);
        // setTotalResults(data.total_results);
        setStatsName1(data.statistics1_name);
        setStatsName2(data.statistics2_name);
      } catch (e) {
        // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [getAccessTokenSilently, workspace, type]);

  useEffect(() => {
    setSearchQuery("");
  }, [pathname]);

  useEffect(() => {
    if (!map || items1.length === 0) return;
    map.setCenter(items1[0].location);
  }, [map, items1]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(parseInt(newLimit));
  };

  // Handler function to update the sorting option
  const handleSort = (option) => {
    setSortOption(option);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleButtonClick = async (position) => {
    const token = await getAccessTokenSilently();
    const url =
      `${process.env.REACT_APP_API_SERVER_URL}/api/export/` +
      type +
      "/" +
      workspace.id +
      "/";
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "text/csv",
          //            "Access-Control-Allow-Credentials": "true",
          "Content-Disposition": "attachment; filename=" + type + ".csv",
          "Access-Control-Allow-Origin": "*", // Required for CORS support to work
          "Access-Control-Allow-Headers": "Authorization", // Required for cookies, authorization headers with HTTPS
        },
        responseType: "blob", // Specify the expected response type as blob
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "export.csv";
        a.click();

        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const doesPhotoExist = items1.some(
    (item) =>
      (item.hasOwnProperty("photo_url") && item.photo_url) ||
      (item.hasOwnProperty("screenshot") && item.screenshot)
  );

  const module = SEARCH_OPTIONS.flatMap(({ options }) => options).find(
    (opt) => opt.value === type
  );

  const authorDataVisible = useMemo(
    () =>
      SEARCH_OPTIONS.find((item) => {
        const foundType = item.options.find((item) => item.value === type);
        return foundType && foundType.authorData !== false;
      })?.authorData,
    [type]
  );

  const validData = useMemo(
    () =>
      items1.map((item) => {
        const newObj = { ...item };
        Object.keys(newObj)
          .filter((key) =>
            (
              TILE_ATTRIBUTE_EXCLUSIONS[
                newObj.shodan_type ? newObj.shodan_type : newObj.type
              ] || []
            ).includes(key)
          )
          .forEach((key) => delete newObj[key]);
        return {
          ...newObj,
          location: `${item.location.lat} ${item.location.lng}`,
        };
      }),
    [items1]
  );

  return (
    <section
      className={`home transition-all overflow-x-hidden ${
        props.sidebarShown ? "md:ml-[250px]" : "md:ml-[88px]"
      } flex flex-col gap-4 md:gap-6 px-4 md:px-8 w-full md:pt-6 pb-8`}
    >
      <div className="flex items-center gap-4 justify-between">
        <div className="flex items-center gap-4">
          {module && (
            <h1 className="text-[var(--text-color)] text-center text-2xl sm:text-3xl font-medium">
              {module.label}
            </h1>
          )}
          <h2 className="text-xl sm:text-2xl text-font opacity-80">
            {workspace.title}
          </h2>
        </div>
        <div className="flex flex-col gap-2 md:grid grid-cols-[2fr_1fr_1fr_max-content]">
          <Input
            placeholder="Search items"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            startContent={<FontAwesomeIcon width={12} icon={faSearch} />}
          />
          <Select
            defaultValue={limit.toString()}
            onValueChange={handleLimitChange}
          >
            <SelectTrigger>
              <SelectValue placeholder="Limit" />
            </SelectTrigger>
            <SelectContent>
              {limitOptions.map((value) => (
                <SelectItem value={value.toString()} key={`limit-${value}`}>
                  {value.toString()}
                </SelectItem>
              ))}
              {!doesPhotoExist && <SelectItem value="500">500</SelectItem>}
            </SelectContent>
          </Select>
          <Select onValueChange={handleSort}>
            <SelectTrigger>
              <SelectValue placeholder="Sort by" />
            </SelectTrigger>
            <SelectContent>
              {sortOptions.map(({ label, value }) => (
                <SelectItem value={value} key={`sort-${value}`}>
                  {label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <ShadcnButton onClick={handleButtonClick}>
            <FontAwesomeIcon icon={faFileArchive} />
            Export CSV
          </ShadcnButton>
        </div>
      </div>
      <div className="flex flex-col gap-4 xl:grid grid-cols-[2fr_1fr]">
        {!NO_MAP_MODULES.includes(type) && (
          <Card
            className={pieChartData.length > 0 ? "col-span-1" : "col-span-2"}
          >
            <CardTitle>Map</CardTitle>
            {isLoading ? (
              <div className="w-full h-[4in] bg-[var(--body-color)] rounded-lg grid place-content-center">
                <img
                  className="animate-spin"
                  width={48}
                  height={48}
                  src="https://cdn.auth0.com/blog/hello-auth0/loader.svg"
                  alt=""
                />
              </div>
            ) : (
              <div className="h-full w-full min-h-[4in] rounded-lg overflow-hidden border border-slate-200 dark:border-slate-800 relative">
                <Map zoom={12}>
                  <MarkerClustererF
                    options={{
                      styles: [
                        {
                          backgroundPosition: "3% -14%",
                          height: 74,
                          textColor: "#FFFFFF",
                          width: 74,
                          url: clusterIcons[type],
                        },
                      ],
                    }}
                  >
                    {(clusterer) =>
                      items1.map((obj) => {
                        const polygonModule = POLYGON_MODULES.find(
                          (item) => item.value === type
                        );
                        return polygonModule ? (
                          <>
                            {infoWindowId &&
                              typeof infoWindowId === "object" &&
                              infoWindowId.id === obj.id && (
                                <InfoWindowF
                                  position={infoWindowId}
                                  onCloseClick={() => setInfoWindowId(null)}
                                  key={`info-window-${obj.id}-${type}`}
                                >
                                  <InfoWindowContent
                                    obj={obj}
                                    key={`info-window-content-${obj.id}-${type}`}
                                  />
                                </InfoWindowF>
                              )}
                            <PolygonF
                              paths={JSON.parse(obj.polygon)}
                              options={{
                                fillColor: polygonModule.color,
                                fillOpacity: 0.7,
                                strokeColor: polygonModule.color,
                              }}
                              onClick={(e) => {
                                const location = {
                                  lat: e.latLng.lat(),
                                  lng: e.latLng.lng(),
                                };
                                const latLng = new google.maps.LatLng(
                                  location.lat,
                                  location.lng
                                );
                                const bounds = new google.maps.LatLngBounds();
                                bounds.extend(latLng);
                                map.fitBounds(bounds);
                                setInfoWindowId({ id: obj.id, ...location });
                              }}
                              key={type + obj.id}
                            />
                          </>
                        ) : (
                          <CustomMarker
                            {...obj}
                            position={obj.location}
                            clusterer={clusterer}
                            onClick={() => setInfoWindowId(obj.id)}
                            onMouseOver={() => setInfoWindowId(obj.id)}
                            key={type + obj.id}
                          >
                            {infoWindowId === obj.id && (
                              <InfoWindowF
                                position={obj.location}
                                onCloseClick={() => setInfoWindowId(null)}
                                key={`info-window-${obj.id}-${type}`}
                              >
                                <InfoWindowContent
                                  obj={obj}
                                  key={`info-window-content-${obj.id}-${type}`}
                                />
                              </InfoWindowF>
                            )}
                          </CustomMarker>
                        );
                      })
                    }
                  </MarkerClustererF>
                </Map>
              </div>
            )}
          </Card>
        )}
        {pieChartData.length > 0 && (
          <Card>
            <CardTitle>
              {statsName1.charAt(0).toUpperCase() + statsName1.substring(1)}
            </CardTitle>
            {isLoading ? (
              <div className="w-full h-[4in] bg-[var(--body-color)] grid place-content-center">
                <img
                  className="animate-spin"
                  width={48}
                  height={48}
                  src="https://cdn.auth0.com/blog/hello-auth0/loader.svg"
                  alt=""
                />
              </div>
            ) : (
              <ResponsiveContainer
                width="100%"
                height={500}
                className="relative"
              >
                <PieChart width={800} height={500}>
                  <Pie
                    data={pieChartData}
                    cx="50%"
                    cy="50%"
                    outerRadius="80%"
                    innerRadius="40%"
                    fill="#8884d8"
                    dataKey="value"
                    strokeWidth={0}
                  >
                    {pieChartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltip type="pie" />} />
                  <Legend
                    content={({ payload }) =>
                      payload && (
                        <ul className="grid grid-cols-3 gap-1">
                          {payload.map((props, k) => (
                            <LegendRef
                              {...props}
                              key={`legend-${props.value}-${k}`}
                            />
                          ))}
                        </ul>
                      )
                    }
                  ></Legend>
                </PieChart>
              </ResponsiveContainer>
            )}
          </Card>
        )}
      </div>
      <div className="flex flex-col gap-4 xl:grid grid-cols-2">
        {barChartData.length > 0 && (
          <Card
            className={cn(
              "px-0",
              timelineChartData.length > 0 ? "col-span-1" : "col-span-2"
            )}
          >
            <CardTitle className="px-6">
              {statsName2.charAt(0).toUpperCase() + statsName2.substring(1)}
            </CardTitle>
            {isLoading ? (
              <div className="w-full h-80 bg-[var(--body-color)] grid place-content-center">
                <img
                  className="animate-spin"
                  width={48}
                  height={48}
                  src="https://cdn.auth0.com/blog/hello-auth0/loader.svg"
                  alt=""
                />
              </div>
            ) : (
              <ResponsiveContainer width="100%" height={320}>
                <BarChart
                  data={barChartData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid
                    vertical={false}
                    strokeWidth={1}
                    stroke="#1F242F"
                  />
                  <XAxis
                    dataKey="name"
                    tickMargin={12}
                    tick={{ fill: "#94969C", fontSize: 14 }}
                    type="category"
                    tickLine={false}
                    axisLine={false}
                  />
                  <YAxis
                    tickLine={false}
                    axisLine={false}
                    tick={{ fill: "#94969C", fontSize: 14 }}
                    type="number"
                  />
                  <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={<CustomTooltip />}
                  />
                  <Bar dataKey="value" fill="#FF00CD" radius={[24, 24, 0, 0]} />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Card>
        )}
        {timelineChartData.length > 0 && (
          <div
            className={cn(
              "parent-container-class w-full",
              barChartData.length > 0 ? "col-span-1" : "col-span-2"
            )}
          >
            <AreaChart
              data={timelineChartData}
              title={statsName3}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col items-end gap-4">
        <div className="flex w-full items-center justify-between gap-4">
          <h2 className="text-[var(--text-color)] font-medium text-xl">
            Results{" "}
            {items1.length > 0 && (
              <span className="text-[var(--text-color)] font-normal opacity-80">
                {items1.length} of {totalResults}
              </span>
            )}
          </h2>
          <div className="flex items-center gap-8">
            <label
              className="flex items-center gap-2 cursor-pointer"
              htmlFor="favourites"
              onClick={() => setAuthorDataEnabled((prev) => !prev)}
            >
              <div
                className={`h-4 w-4 grid place-content-center rounded-[2px] border-[var(--primary-color)] border-[1px] text-white ${
                  onlyFavourites
                    ? "bg-[var(--primary-color)]"
                    : "bg-[var(--background-color)]"
                }`}
              >
                {onlyFavourites && <FontAwesomeIcon size="sm" icon={faCheck} />}
              </div>
              <span className="text-[var(--text-color)] text-base select-none">
                Only favourites
              </span>
              <input
                type="checkbox"
                id="favourites"
                className="opacity-0 absolute -z-50"
                value={onlyFavourites}
                onChange={(e) => setOnlyFavourites(e.target.checked)}
              />
            </label>
            <div className="flex items-center gap-2">
              <Label htmlFor="author-info">Author info</Label>
              <Switch
                id="author-info"
                checked={authorDataEnabled}
                onCheckedChange={(checked) => setAuthorDataEnabled(checked)}
              />
            </div>
            <Pagination
              currentPage={currentPage}
              totalPage={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        <div
          className={`flex flex-col w-full ${
            doesPhotoExist ? "md:grid" : ""
          } grid-cols-[repeat(auto-fill,minmax(340px,1fr))] gap-8 items-start`}
        >
          {doesPhotoExist ? (
            items1.map((item) => (
              <ObjectRef
                {...item}
                authorDataEnabled={authorDataEnabled}
                key={item.type + item.id}
              />
            ))
          ) : (
            <DataTable
              authorDataVisible={authorDataVisible && authorDataEnabled}
              records={validData}
              type={type}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col self-end gap-4">
        <Pagination
          currentPage={currentPage}
          totalPage={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </section>
  );
}

const LegendRef = ({ value, color }) => (
  <li className="text-lg text-[#94969C] flex items-center gap-2">
    <div
      style={{ backgroundColor: color }}
      className="h-2.5 w-2.5 rounded-full"
    ></div>
    {value
      .toString()
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ")}
  </li>
);
