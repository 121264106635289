import { cn } from "@/utils/cn";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ScrollArea } from "../ui/scroll-area";
import { ICON_TYPES } from "@/const/search";

type Props = {
  module: string;
  isOpen: boolean;
  handleClose: () => void;
  isSingle: boolean;
  children: React.ReactNode[];
};

export default function Drawer({
  module,
  isOpen,
  isSingle,
  handleClose,
  children,
}: Props) {
  return (
    <div
      className={cn(
        "absolute px-16 right-0 pointer-events-none transition-transform duration-500 z-10",
        isOpen ? "translate-x-0" : "translate-x-full",
        isSingle ? "top-1/2 -translate-y-1/2" : "bottom-0 top-0 my-12"
      )}
    >
      <div className="bg-[var(--body-color)] w-full sm:w-[500px] rounded-lg overflow-hidden px-4 relative pointer-events-auto">
        <div className="h-16 bg-[var(--body-color)] flex items-center justify-between absolute z-20 left-0 right-0 top-0 px-6">
          <div className="flex items-center gap-4">
            <img
              width={24}
              height={24}
              src={ICON_TYPES[module as keyof typeof ICON_TYPES]}
              alt={module}
            />
            <h3 className="text-[var(--text-color)] font-medium">
              {module ? formatModule(module) : "No module"}
            </h3>
          </div>
          {handleClose && (
            <button
              className="flex text-[var(--text-color)] h-5 w-5 items-center justify-center bg-[var(--body-color)] rounded-sm hover:bg-[var(--primary-color)]"
              onClick={handleClose}
            >
              <FontAwesomeIcon className="max-h-[1em]" icon={faXmark} />
            </button>
          )}
        </div>
        {isSingle ? (
          <ScrollArea className="mt-16 pb-6 max-h-[calc(100vh-216px)] [&>div]:max-h-[calc(100vh-216px)]">
            {children}
          </ScrollArea>
        ) : (
          <ScrollArea className="mt-16 h-[calc(100vh-216px)]">
            <div className="flex flex-col gap-4 pb-6">{children}</div>
          </ScrollArea>
        )}
      </div>
    </div>
  );
}

const formatModule = (module: string) =>
  module.charAt(0).toUpperCase() + module.substring(1);
