import { Card, CardTitle } from "@/components/ui/card";
import { ICON_TYPES } from "../../../const/search";
import PriceChart from "../ui/price";

export default function AirbnbStatsList({ rating, price }: AirbnbStats) {
  return (
    <Card className="p-4 space-y-4 w-max rounded flex-row flex items-stretch gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="w-11 h-11 rounded overflow-hidden relative">
            <img
              className="absolute inset-0 w-full h-full object-cover object-center"
              src={ICON_TYPES["airbnb"]}
              alt=""
            />
          </div>
          <div>
            <CardTitle className="text-xl">Airbnb</CardTitle>
            <p className="text-[var(--text-color)] opacity-80 text-lg">Stats</p>
          </div>
        </div>
        <div className="space-y-2 flex-1 flex flex-col">
          <div className="flex items-center gap-1.5">
            <svg
              width="16"
              height="14"
              viewBox="0 0 21 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 0.0424805L13.8968 5.73653L20.493 7.13113L15.9962 12.0449L16.676 18.6008L10.5 15.9436L4.32405 18.6008L5.00391 12.0449L0.50708 7.13113L7.10324 5.73653L10.5 0.0424805Z"
                stroke="#FF5A60"
                strokeWidth={2}
              />
            </svg>
            <h4 className="text-[var(--text-color)] opacity-40 text-base mb-0.5">
              Rating
            </h4>
          </div>
          <div className="rounded p-4 bg-[#D9D9D9]/10 w-max space-y-2 flex-1 flex flex-col justify-center">
            <div className="flex items-center gap-2">
              {Array.from(Array(5)).map((_, star) => (
                <svg
                  width="21"
                  height="19"
                  viewBox="0 0 21 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 0.0424805L13.8968 5.73653L20.493 7.13113L15.9962 12.0449L16.676 18.6008L10.5 15.9436L4.32405 18.6008L5.00391 12.0449L0.50708 7.13113L7.10324 5.73653L10.5 0.0424805Z"
                    fill={`url('#F1g${star}')`}
                    stroke="#F6AD85"
                    strokeWidth={1}
                  />
                  <defs>
                    <linearGradient
                      y2="0%"
                      x2="100%"
                      y1="0%"
                      x1="0%"
                      id={`F1g${star}`}
                    >
                      <stop
                        stopColor="#F6AD85"
                        offset={rating - star >= 1 ? "100%" : rating - star}
                        id={`F1gst1${star}`}
                      />
                      <stop
                        stopColor="transparent"
                        offset="0%"
                        id={`F1gst2${star}`}
                      />
                    </linearGradient>
                  </defs>
                </svg>
              ))}
            </div>
            <p className="text-center text-[var(--text-color)] text-xl font-medium">
              {rating.toFixed(2)}
            </p>
          </div>
        </div>
      </div>
      <PriceChart price={price} color={"#FF5A60"} />
    </Card>
  );
}
