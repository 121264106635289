import React, { useContext, useEffect } from "react";
import Map from "../../components/map_with_search";
import { WorkspaceContext } from "../../providers/workspace";

export const AddLocation = (props) => {
  const { setWorkspace } = useContext(WorkspaceContext);

  useEffect(() => {
    if (!setWorkspace) return;
    setWorkspace(null);
  }, [setWorkspace]);

  return (
    <section
      className={`home transition-all ${
        props.sidebarShown ? "md:ml-[250px]" : "md:ml-[88px]"
      } flex flex-col gap-4 w-full pt-4 xl:pt-6 xl:gap-6`}
    >
      <Map />
    </section>
  );
};
