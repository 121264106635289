export default function DiveboardIcon() {
  return (
    <svg
      height="100%"
      width="100%"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 256 256"
      fill="inherit"
    >
      <g>
        <path
          id="XMLID_9_"
          d="M24.7,70.4c-5.1,0.3-9.5-3.7-9.8-8.8c-0.3-5.1,3.7-9.5,8.8-9.8c5.1-0.3,9.5,3.7,9.8,8.8
	C33.8,65.7,29.9,70.1,24.7,70.4z M30.3,87.1c-0.1-2.6-2.3-4.5-4.9-4.4c-2.6,0.1-4.5,2.3-4.4,4.9c0.1,2.6,2.3,4.5,4.9,4.4
	S30.4,89.7,30.3,87.1z M31.5,109.6c1.7-0.1,2.9-1.5,2.9-3.2s-1.5-2.9-3.2-2.9c-1.7,0.1-2.9,1.5-2.9,3.2S29.8,109.7,31.5,109.6z
	 M42.4,82.7c4.2-0.2,7.5-3.8,7.3-8.1c-0.2-4.2-3.8-7.5-8.1-7.3c-4.2,0.2-7.5,3.8-7.3,8.1C34.5,79.7,38.2,82.9,42.4,82.7z M141.6,107
	l-19.9-28.7l-18.9,13.1l19.9,28.7L141.6,107z M67,217.7c-2.6-12-13.8-19.8-25.7-18.5c-2-22.6,7.5-28.3,20.5-36.1
	c5.6-3.7,57.4-40.4,57.4-40.4L99.3,93.9l-51.5,35.7c-7.9,5.5-9.9,16.4-4.4,24.3c1.4,2,3.1,3.6,5.1,4.9c-11.3,7.7-20.6,18.3-17.5,44
	c-8.1,5.2-12.6,15.1-10.4,25.1c1.5,6.9,5.9,12.4,11.6,15.6l2.2,9.8l23.2-5.1l-1.2-5.3c0.9-0.5,1.7-1.1,2.4-1.7
	c4.6,0.8,9.1-1.8,10.5-6.4c1-3.2,0.1-6.6-2-9C67.7,223.2,67.6,220.4,67,217.7z M230.2,22.3c-8.6,2.5-20.4-11.9-22.2-14.1
	c-7.7,16-14.3,60.7-14.3,60.7c-4.8,10.8-10.2,24.8-15.3,35.5c-2.9-14.4-9.7-47.2-9.9-47.6l11.9-48.7c-2.2-1.1-19.2-8.9-25.8-2.7
	c-6.6,6.2-24-1.7-26.3-2.8c2.1,15.2,13.4,51.6,16.2,58.9c0,1.1,7.9,34.6,11.1,50.4c-14.2,7-80.9,49.7-91.2,59.8
	C51,185.4,62.1,203.9,76.6,207c2.4,1.4,56.6-3.2,80.7-6.3c0.1,0,0.2,0,0.3-0.1c0.7-0.1,1.4-0.3,2.1-0.4c4.5-1.3,8-4.9,9.3-9.5
	c1.2-7.3-4.3-14.7-13.5-15.7c-0.2,0-0.4-0.1-0.6-0.1c-7.5-0.5-31.2,3.3-38.8,3.7c7.1-3.9,60.3-28.1,60.3-28.1
	c8.4-1.6,14-9.5,15.1-17.6c8.4-19.1,26.7-57.8,26.8-58.3c8.1-8.3,24.8-28.5,33.6-38.6C249.6,33.1,238.6,19.9,230.2,22.3z"
        />
      </g>
    </svg>
  );
}
