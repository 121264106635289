export default function EyeSlashIcon() {
  return (
    <svg
      width="36"
      height="32"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9049 4.4872C16.5823 4.38705 17.281 4.33333 18.0007 4.33333C26.509 4.33333 32.0922 11.8414 33.9678 14.8114C34.1949 15.1708 34.3084 15.3506 34.3719 15.6278C34.4196 15.836 34.4196 16.1644 34.3718 16.3726C34.3082 16.6498 34.1939 16.8308 33.9653 17.1926C33.4656 17.9836 32.7036 19.0952 31.6942 20.3008M9.2072 7.19173C5.60375 9.63617 3.15742 13.0323 2.03518 14.8088C1.80714 15.1698 1.69312 15.3503 1.62957 15.6275C1.58183 15.8357 1.58181 16.1641 1.62952 16.3723C1.69305 16.6495 1.80655 16.8292 2.03356 17.1886C3.90924 20.1586 9.49235 27.6667 18.0007 27.6667C21.4314 27.6667 24.3865 26.446 26.8147 24.7943M3.00069 1L33.0007 31M14.4652 12.4645C13.5603 13.3693 13.0007 14.6193 13.0007 16C13.0007 18.7614 15.2393 21 18.0007 21C19.3814 21 20.6314 20.4404 21.5362 19.5355"
        stroke="inherit"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
