import { Cell, Pie, PieChart } from "recharts";
import { ICON_TYPES, MODULES } from "../../const/search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import renderCustomizedLabel from "../../utils/render-customized-label";
import { Card, CardTitle } from "../ui/card";

type PieChartData<T> = {
  name: string;
  value: T;
};

export default function AllStatsList({ total, ...rest }: Count) {
  const data: PieChartData<number>[] = Object.keys(rest).map((key) => ({
    name: key,
    value: rest[key as keyof Omit<Count, "total">]!,
  }));
  return (
    <Card className="p-4 w-max rounded grid grid-cols-[max-content_1fr] gap-x-4 gap-y-2 self-stretch">
      <div>
        <CardTitle className="text-xl">All items</CardTitle>
        <PieChart
          width={144}
          height={144}
          className="!mt-0"
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            label={renderCustomizedLabel}
            labelLine={false}
          >
            {data.map((entry) => (
              <Cell
                fill={
                  MODULES.find((item) => item.value === entry.name)?.color ||
                  "#D9D9D9"
                }
              />
            ))}
          </Pie>
        </PieChart>
      </div>
      <div className="rounded px-4 py-3 bg-[#D9D9D9]/10 gap-y-1 gap-x-4 max-h-full grid grid-cols-2 overflow-y-auto overflow-x-hidden hide-scroll">
        {data.map((item) => (
          <ModuleRef {...item} key={item.name} />
        ))}
      </div>
    </Card>
  );
}

const ModuleRef = ({ name, value }: PieChartData<number>) => {
  const obj = MODULES.find((item) => item.value === name);
  const title = obj?.label || name;
  const icon = ICON_TYPES[name as keyof typeof ICON_TYPES];
  return (
    <div className="flex items-center gap-2 text-[var(--text-color)] text-lg">
      {icon ? (
        <div className="h-3 w-3">
          <img className="h-full object-contain" src={icon} alt="" />
        </div>
      ) : (
        obj && (
          <FontAwesomeIcon
            width={12}
            height={12}
            icon={obj.icon}
            color={obj.color}
          />
        )
      )}
      <span>{title}</span>
      <span className="font-medium">{value}</span>
    </div>
  );
};
