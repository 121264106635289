import { cn } from "@/utils/cn";

type Props = {
  isOSM: boolean;
  changeMode: (osm: boolean) => void;
};

export default function ModeSwitch({ isOSM, changeMode }: Props) {
  return (
    <div className="rounded-lg overflow-hidden border border-slate-300 bg-white dark:bg-[var(--body-color)] dark:border-[#333741] grid grid-cols-[1fr_1px_1fr]">
      <button
        onClick={() => changeMode(true)}
        className={cn(
          "h-8 w-9 grid place-content-center",
          !isOSM
            ? "text-[#98A2B3]"
            : "bg-pink/5 dark:bg-[#1F242F] text-[var(--primary-color2)]"
        )}
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.16797 11.9054C2.62517 12.586 1.66797 13.5344 1.66797 14.5834C1.66797 16.6545 5.39893 18.3334 10.0013 18.3334C14.6037 18.3334 18.3346 16.6545 18.3346 14.5834C18.3346 13.5344 17.3774 12.586 15.8346 11.9054M15.0013 6.66675C15.0013 10.0532 11.2513 11.6667 10.0013 14.1667C8.7513 11.6667 5.0013 10.0532 5.0013 6.66675C5.0013 3.90532 7.23988 1.66675 10.0013 1.66675C12.7627 1.66675 15.0013 3.90532 15.0013 6.66675ZM10.8346 6.66675C10.8346 7.12699 10.4615 7.50008 10.0013 7.50008C9.54107 7.50008 9.16797 7.12699 9.16797 6.66675C9.16797 6.20651 9.54107 5.83341 10.0013 5.83341C10.4615 5.83341 10.8346 6.20651 10.8346 6.66675Z"
            stroke="currentColor"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <div className="bg-slate-300 dark:bg-[#333741]"></div>
      <button
        onClick={() => changeMode(false)}
        className={cn(
          "h-8 w-9 grid place-content-center",
          isOSM
            ? "text-[#98A2B3]"
            : "bg-pink/5 dark:bg-[#1F242F] text-[var(--primary-color2)]"
        )}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.82125 3.72239C1.19097 3.01796 0.875826 2.66574 0.863939 2.3664C0.853612 2.10636 0.965359 1.85643 1.16603 1.69074C1.39704 1.5 1.86966 1.5 2.81491 1.5H15.184C16.1293 1.5 16.6019 1.5 16.8329 1.69074C17.0336 1.85643 17.1453 2.10636 17.135 2.3664C17.1231 2.66574 16.808 3.01796 16.1777 3.72239L11.4225 9.03703C11.2968 9.17745 11.234 9.24766 11.1892 9.32756C11.1495 9.39843 11.1203 9.47473 11.1027 9.55403C11.0828 9.64345 11.0828 9.73766 11.0828 9.92609V14.382C11.0828 14.5449 11.0828 14.6264 11.0565 14.6969C11.0333 14.7591 10.9955 14.8149 10.9463 14.8596C10.8907 14.9102 10.815 14.9404 10.6637 15.001L7.83039 16.1343C7.5241 16.2568 7.37096 16.3181 7.24802 16.2925C7.14052 16.2702 7.04617 16.2063 6.98551 16.1148C6.91613 16.0101 6.91613 15.8452 6.91613 15.5153V9.92609C6.91613 9.73766 6.91613 9.64345 6.89623 9.55403C6.87858 9.47473 6.84943 9.39843 6.8097 9.32756C6.76491 9.24766 6.70209 9.17745 6.57645 9.03703L1.82125 3.72239Z"
            stroke="currentColor"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
}
