import { useContext } from "react";
import TimestampIcon from "../../../const/search/imgs/timestamp";
import { ActiveRecordContext } from "../../../providers/stats/ActiveRecordProvider";

export default function Timestamp({ stroke, first, last }: UI & Records) {
  const { activeRecord, changeActiveRecord } = useContext(ActiveRecordContext);
  const earliest = JSON.parse(first) as SingleRecord;
  const latest = JSON.parse(last) as SingleRecord;
  const isEarliestActive =
    activeRecord &&
    activeRecord.id === earliest.id &&
    activeRecord.type === earliest.type;
  const isLatestActive =
    activeRecord &&
    activeRecord.id === latest.id &&
    activeRecord.type === latest.type;

  return (
    <div className="flex-1 flex flex-col gap-2 justify-end">
      <div className="flex items-center gap-2">
        <div style={{ stroke }} className="h-4">
          <TimestampIcon />
        </div>
        <span className="text-[var(--text-color)] opacity-40 text-sm">
          Timestamp
        </span>
      </div>
      <button
        onClick={() => changeActiveRecord(earliest)}
        style={{ color: stroke }}
        className="rounded px-3 py-2 bg-[#D9D9D9]/10 flex items-center justify-between gap-8 group"
      >
        <span
          className={`${
            isEarliestActive
              ? "opacity-40"
              : "opacity-60 group-hover:opacity-40"
          } text-[var(--text-color)] transition-opacity text-sm`}
        >
          From:{" "}
        </span>
        <span
          className={`${
            isEarliestActive
              ? "text-inherit"
              : "text-[var(--text-color)] group-hover:text-inherit"
          } text-base transition-colors`}
        >
          {new Date(
            earliest.timestamp || earliest.start_date || earliest.start_datetime
          ).toLocaleDateString()}
        </span>
      </button>
      <button
        onClick={() => changeActiveRecord(latest)}
        style={{ color: stroke }}
        className="rounded px-3 py-2 bg-[#D9D9D9]/10 flex items-center justify-between gap-8 group"
      >
        <span
          className={`${
            isLatestActive ? "opacity-40" : "opacity-60 group-hover:opacity-40"
          } text-[var(--text-color)] transition-opacity text-sm`}
        >
          To:{" "}
        </span>
        <span
          className={`${
            isLatestActive
              ? "text-inherit"
              : "text-[var(--text-color)] group-hover:text-inherit"
          } text-base transition-colors`}
        >
          {new Date(
            latest.timestamp || latest.end_date || latest.end_datetime
          ).toLocaleDateString()}
        </span>
      </button>
    </div>
  );
}
