import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import * as Sentry from "@sentry/react";
import { Toaster } from "react-hot-toast";
import { HashRouter } from "react-router-dom";
import AuthProvider from "./providers/auth";
import WorkspaceProvider from "./providers/workspace";

Sentry.init({
  dsn: "https://314546ba7e70c656add974275bd59538@o4505200724082688.ingest.sentry.io/4506207004000256",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  sendDefaultPii: true,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <AuthProvider>
      <WorkspaceProvider>
        <App />
      </WorkspaceProvider>
      <Toaster position="bottom-right" />
    </AuthProvider>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
