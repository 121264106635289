import { Card, CardTitle } from "@/components/ui/card";
import { ICON_TYPES } from "../../../const/search";
import Timestamp from "../ui/timestamp";

export default function SnapchatStatsList({
  stroke,
  ...records
}: SnapchatStats & Pick<UI, "stroke">) {
  return (
    <Card className="p-4 gap-4 w-max rounded flex flex-col">
      <div className="flex items-center gap-4 h-max">
        <div className="w-11 h-11 rounded overflow-hidden relative">
          <img
            className="absolute inset-0 w-full h-full object-cover object-center"
            src={ICON_TYPES["snapchat"]}
            alt=""
          />
        </div>
        <div>
          <CardTitle className="text-xl">Snapchat</CardTitle>
          <p className="text-[var(--text-color)] opacity-80 text-lg">Records</p>
        </div>
      </div>
      <div className="flex-1 flex items-center">
        <Timestamp stroke={stroke} {...records} />
      </div>
    </Card>
  );
}
