import React, { useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LocationRef from "../../components/database/LocationRef";
import { WorkspaceContext } from "../../providers/workspace";
import Popup from "../../components/Popup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Toast from "../../components/Toast.jsx";
import ReservoirLogo from "../../const/search/imgs/reservoir.png";

export const Reservoir = (props) => {
  const navigate = useNavigate();
  const { setWorkspace } = useContext(WorkspaceContext);
  const { getAccessTokenSilently } = useAuth0();
  const [results, setResults] = useState([]);
  const [youtube, setYoutube] = useState([]);
  const [strava, setStrava] = useState([]);
  const [instagram, setInstagram] = useState([]);
  const [flickr, setFlickr] = useState([]);
  const [wigle, setWigle] = useState([]);
  const [vkontakte, setVkontakte] = useState([]);
  const [shodan, setShodan] = useState([]);
  const [windy, setWindy] = useState([]);
  const [weather, setWeather] = useState([]);
  const [redis, setRedis] = useState([]);
  const [message, setMessage] = useState("");
  const [error] = useState(null);
  const [loading, setLoading] = useState({
    page: true,
    removal: false,
  });

  const checkAccounts = async (account_type) => {
    toast.custom((t) => <Toast {...t} title="" subtitle="Checking..." />);
    try {
      const token = await getAccessTokenSilently();

      // Send a POST request to the server with the data
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/reservoir/checkAccount/` +
          account_type,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          method: "GET",
        }
      );
      const data = await response.json();
      toast.custom((t) => (
        <Toast {...t} title="" subtitle={`${data.message}`} />
      ));
    } catch (error) {
      // Handle errors here, e.g., show an error message to the user
      console.error("Password change failed:", error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/reservoir/getAccounts`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setYoutube(data.youtube);
        setStrava(data.strava);
        setInstagram(data.instagram);
        setFlickr(data.flickr);
        setWigle(data.wigle);
        setVkontakte(data.vkontakte);
        setShodan(data.shodan);
        setWindy(data.windy);
        setWeather(data.weather);
        setRedis(data.redis);

        setLoading((prev) => ({ ...prev, page: false }));
      } catch (e) {
        // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently]);

  if (loading.page) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const saveAccount = async (account_type) => {
    if (account_type == "redis") {
      var account_details1 = "";
      var account_details2 = "";
    } else {
      var account_details1 = document.getElementById(
        "account_details1 " + account_type
      ).value;
      var account_details2 = document.getElementById(
        "account_details2 " + account_type
      ).value;
    }

    const requestData = {
      account_details1,
      account_details2,
      account_type,
    };

    try {
      const token = await getAccessTokenSilently();

      // Send a POST request to the server with the data
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/reservoir/saveAccount/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          method: "POST",
          body: JSON.stringify(requestData),
        }
      );
      const data = await response.json();
      setMessage(data.message);
      toast.custom((t) => (
        <Toast {...t} title="" subtitle={` ${data.status}`} />
      ));
    } catch (error) {
      // Handle errors here, e.g., show an error message to the user
      console.log(error);
    }
  };

  return (
    <section
      className={`home transition-all ${
        props.sidebarShown ? "md:ml-[250px]" : "md:ml-[88px]"
      } flex flex-col gap-4 px-4 md:px-8 w-full pt-16 pb-8`}
    >
      <h1 className="text-[var(--text-color)] font-medium text-xl flex-1 text-center">
        <img
          src={ReservoirLogo}
          width="250"
          height="250"
          style={{ marginTop: "-50px", margin: "auto" }}
        />
      </h1>

      <div className="flex flex-col gap-8 xl:grid grid-cols-6">
        <div className="flex flex-col gap-6 bg-[var(--sidebar-color)] rounded-lg p-6">
          <div className="flex flex-col gap-3">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
              alt=""
              onClick={() => {
                checkAccounts("instagram");
              }}
            />
          </div>
          <h3
            className="text-[var(--text-color)] font-medium"
            style={{ color: instagram ? "green" : "red" }}
          >
            {" "}
            {instagram ? "Operational" : "Non operational"}
          </h3>
          <input
            className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            id="account_details1 instagram"
            placeholder="Cookie"
            //               onChange={(e) => setNewEmail(e.target.value)}
          />
          <input
            className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            id="account_details2 instagram"
            placeholder="Description"
            //               onChange={(e) => setNewEmail(e.target.value)}
          />
          <div className="w-max mt-2">
            <Button
              //                 icon={faCheck}
              reverseFill
              onClick={() => {
                saveAccount("instagram");
              }}
              className="!py-2 !px-4"
            >
              Save
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-6 bg-[var(--sidebar-color)] rounded-lg p-6">
          <div className="flex flex-col gap-3">
            <img
              src="https://wigle.net/images/planet-bubble.png"
              alt=""
              onClick={() => {
                checkAccounts("wigle");
              }}
            />
          </div>
          <h3
            className="text-[var(--text-color)] font-medium"
            style={{ color: wigle ? "green" : "red" }}
          >
            {" "}
            {wigle ? "Operational" : "Non operational"}
          </h3>
        </div>

        <div className="flex flex-col gap-6 bg-[var(--sidebar-color)] rounded-lg p-6">
          <div className="flex flex-col gap-3">
            <img
              src="https://www.flickr.com/images/opensearch-flickr-logo.png"
              alt=""
              onClick={() => {
                checkAccounts("flickr");
              }}
            />
          </div>
          <h3
            className="text-[var(--text-color)] font-medium"
            style={{ color: flickr ? "green" : "red" }}
          >
            {" "}
            {flickr ? "Operational" : "Non operational"}
          </h3>
          <input
            className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            id="account_details1 flickr"
            placeholder="API Key"
            //               onChange={(e) => setNewEmail(e.target.value)}
          />
          <input
            className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            id="account_details2 flickr"
            placeholder="Description"
            //               onChange={(e) => setNewEmail(e.target.value)}
          />
          <div className="w-max mt-2">
            <Button
              //                 icon={faCheck}
              reverseFill
              onClick={() => {
                saveAccount("flickr");
              }}
              className="!py-2 !px-4"
            >
              Save
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-6 bg-[var(--sidebar-color)] rounded-lg p-6">
          <div className="flex flex-col gap-3">
            <img
              src="https://pbs.twimg.com/profile_images/1105606704090267648/oyZUgnFr_400x400.png"
              alt=""
              onClick={() => {
                checkAccounts("shodan");
              }}
            />
          </div>
          <h3
            className="text-[var(--text-color)] font-medium"
            style={{ color: shodan ? "green" : "red" }}
          >
            {" "}
            {shodan ? "Operational" : "Non operational"}
          </h3>
        </div>

        <div className="flex flex-col gap-6 bg-[var(--sidebar-color)] rounded-lg p-6">
          <div className="flex flex-col gap-3">
            <img
              src="https://www.freeiconspng.com/uploads/youtube-logo-png-photo-0.png"
              alt=""
              onClick={() => {
                checkAccounts("youtube");
              }}
            />
          </div>
          <h3
            className="text-[var(--text-color)] font-medium"
            style={{ color: youtube ? "green" : "red" }}
          >
            {" "}
            {youtube ? "Operational" : "Non operational"}
          </h3>
          <input
            className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            id="account_details1 youtube"
            placeholder="API Key"
            //               onChange={(e) => setNewEmail(e.target.value)}
          />
          <input
            className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            id="account_details2 youtube"
            placeholder="Description"
            //               onChange={(e) => setNewEmail(e.target.value)}
          />
          <div className="w-max mt-2">
            <Button
              //                 icon={faCheck}
              reverseFill
              //                 onClick={handleChangeEmailClick}
              className="!py-2 !px-4"
              onClick={() => {
                saveAccount("youtube");
              }}
            >
              Save
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-6 bg-[var(--sidebar-color)] rounded-lg p-6">
          <div className="flex flex-col gap-3">
            <img
              src="https://www.garagegymreviews.com/wp-content/uploads/strava-app.jpg"
              alt=""
              onClick={() => {
                checkAccounts("strava");
              }}
            />
          </div>
          <h3
            className="text-[var(--text-color)] font-medium"
            style={{ color: strava ? "green" : "red" }}
          >
            {" "}
            {strava ? "Operational" : "Non operational"}
          </h3>
          <input
            className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            id="account_details1 strava"
            placeholder="Cookie"
            //               onChange={(e) => setNewEmail(e.target.value)}
          />
          <input
            className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            id="account_details2 strava"
            placeholder="Description"
            //               onChange={(e) => setNewEmail(e.target.value)}
          />
          <div className="w-max mt-2">
            <Button
              //                 icon={faCheck}
              reverseFill
              //                 onClick={handleChangeEmailClick}
              className="!py-2 !px-4"
              onClick={() => {
                saveAccount("strava");
              }}
            >
              Save
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-6 bg-[var(--sidebar-color)] rounded-lg p-6">
          <div className="flex flex-col gap-3">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/VK.com-logo.svg/1200px-VK.com-logo.svg.png"
              alt=""
              onClick={() => {
                checkAccounts("vkontakte");
              }}
            />
          </div>
          <h3
            className="text-[var(--text-color)] font-medium"
            style={{ color: vkontakte ? "green" : "red" }}
          >
            {" "}
            {vkontakte ? "Operational" : "Non operational"}
          </h3>
          <input
            className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            //               type="email"
            id="account_details1 vkontakte"
            placeholder="API Key"
            //               onChange={(e) => setNewEmail(e.target.value)}
          />
          <input
            className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            id="account_details2 vkontakte"
            placeholder="Description"
            //               onChange={(e) => setNewEmail(e.target.value)}
          />
          <div className="w-max mt-2">
            <Button
              //                 icon={faCheck}
              reverseFill
              onClick={() => {
                saveAccount("vkontakte");
              }}
              className="!py-2 !px-4"
            >
              Save
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-6 bg-[var(--sidebar-color)] rounded-lg p-6">
          <div className="flex flex-col gap-3">
            <img
              src="https://play-lh.googleusercontent.com/qXBOdC6WVNPPZso8m5_Z_lCiNTG130ZwHAumXlLpF5r9yaPYICJw9tGGtPt5v9yh35E"
              alt=""
              onClick={() => {
                checkAccounts("windy");
              }}
            />
          </div>
          <h3
            className="text-[var(--text-color)] font-medium"
            style={{ color: windy ? "green" : "red" }}
          >
            {" "}
            {windy ? "Operational" : "Non operational"}
          </h3>
          <input
            className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            id="account_details1 windy"
            placeholder="API Key"
            //               onChange={(e) => setNewEmail(e.target.value)}
          />
          <input
            className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            id="account_details2 windy"
            placeholder="Description"
            //               onChange={(e) => setNewEmail(e.target.value)}
          />
          <div className="w-max mt-2">
            <Button
              //                 icon={faCheck}
              reverseFill
              //                 onClick={handleChangeEmailClick}
              className="!py-2 !px-4"
              onClick={() => {
                saveAccount("windy");
              }}
            >
              Save
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-6 bg-[var(--sidebar-color)] rounded-lg p-6">
          <div className="flex flex-col gap-3">
            <img
              src="https://avatars.githubusercontent.com/u/52202145?v=4"
              alt=""
              onClick={() => {
                checkAccounts("weather");
              }}
            />
          </div>
          <h3
            className="text-[var(--text-color)] font-medium"
            style={{ color: weather ? "green" : "red" }}
          >
            {" "}
            {weather ? "Operational" : "Non operational"}
          </h3>
          <input
            className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            id="account_details1 weather"
            placeholder="API Key"
            //               onChange={(e) => setNewEmail(e.target.value)}
          />
          <input
            className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            id="account_details2 weather"
            placeholder="Description"
            //               onChange={(e) => setNewEmail(e.target.value)}
          />
          <div className="w-max mt-2">
            <Button
              //                 icon={faCheck}
              reverseFill
              //                 onClick={handleChangeEmailClick}
              className="!py-2 !px-4"
              onClick={() => {
                saveAccount("weather");
              }}
            >
              Save
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-6 bg-[var(--sidebar-color)] rounded-lg p-6">
          <div className="flex flex-col gap-3">
            <img
              src="https://cdn4.iconfinder.com/data/icons/redis-2/1451/Untitled-2-512.png"
              alt=""
            />
          </div>
          <h3 className="text-[var(--text-color)] font-medium">REDIS </h3>
          <Button
            reverseFill
            className="!py-2 !px-4"
            onClick={() => {
              saveAccount("redis");
            }}
          >
            Flush
          </Button>
        </div>
      </div>
    </section>
  );
};
