import { IconDefinition, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  records: string[];
  title?: string;
  icon?: IconDefinition;
  color: string;
};

export default function List({
  records,
  title = "List",
  icon = faList,
  color,
}: Props) {
  return (
    <div className="flex-1 flex flex-col gap-2">
      <div className="flex items-center gap-1.5">
        <FontAwesomeIcon icon={icon} color={color} />
        <h4 className="text-[var(--text-color)] opacity-40 text-base mb-0.5">
          {title}
        </h4>
      </div>
      <ul className="rounded px-3 py-2 bg-[#D9D9D9]/10 overflow-y-auto overflow-x-hidden hide-scroll max-w-[14rem] max-h-[120px] flex flex-col gap-1.5 flex-1">
        {records.map((name, i) => (
          <li className="text-base text-[var(--text-color)]" key={i + name}>
            {name}
          </li>
        ))}
      </ul>
    </div>
  );
}
