import { createContext, useCallback, useState } from "react";

type MapsContextType = {
  map: google.maps.Map | null;
  onLoad: (map: google.maps.Map) => void;
  fitBounds: (points: Coords[], options?: { setZoom?: boolean }) => void;
};

export const MapsContext = createContext<MapsContextType>(null!);

export default function MapsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const onLoad = useCallback((map: google.maps.Map) => setMap(map), []);

  const fitBounds = useCallback(
    (points: Coords[], options: { setZoom?: boolean } = { setZoom: true }) => {
      if (!map) return;
      const bounds = new google.maps.LatLngBounds();

      points.forEach((point) => {
        const coords = new google.maps.LatLng(point.lat, point.lng);
        bounds.extend(coords);
      });

      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new google.maps.LatLng(
          bounds.getNorthEast().lat(),
          bounds.getNorthEast().lng()
        );
        const extendPoint2 = new google.maps.LatLng(
          bounds.getNorthEast().lat(),
          bounds.getNorthEast().lng()
        );
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }
      map.fitBounds(bounds);
      options.setZoom && map.setZoom(12);
    },
    [map]
  );

  return (
    <MapsContext.Provider value={{ map, fitBounds, onLoad }}>
      {children}
    </MapsContext.Provider>
  );
}
