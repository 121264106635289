export default function Events5Icon() {
  return (
      <svg fill="inherit" height="120%" width="100%" version="1.1" id="Layer_1" stroke="inherit" strokeWidth="10"
            viewBox="0 0 512.008 512.008">
<g>
	<g>
		<g>
			<path d="M447.988,128.008c0-52.821,18.965-78.613,19.115-78.805c3.285-4.267,2.901-10.283-0.896-14.08l-32-32
				c-3.328-3.349-8.469-4.053-12.629-1.835c-0.363,0.192-37.461,20.032-80.235,20.032c-42.773,0-79.851-19.819-80.235-20.032
				c-3.179-1.707-7.04-1.707-10.197,0c-0.363,0.192-37.461,20.032-80.235,20.032c-42.773,0-79.851-19.819-80.235-20.032
				c-4.139-2.24-9.28-1.515-12.629,1.835l-32,32c-3.755,3.776-4.139,9.771-0.939,14.016c0.192,0.256,19.136,26.048,19.136,78.869
				c0,24.021-3.904,40.661-8.384,59.904c-6.059,26.005-12.949,55.467-12.949,110.763c0,101.333,78.805,147.776,208.597,212.224
				c1.493,0.725,3.115,1.109,4.736,1.109s3.243-0.384,4.715-1.109c129.792-64.448,208.597-110.891,208.597-212.224
				c0-55.275-6.869-84.757-12.949-110.763C451.871,168.648,447.988,152.008,447.988,128.008z M256.009,489.416
				c-119.168-59.285-192-101.099-192-190.741c0-52.843,6.592-81.024,12.395-105.92c4.587-19.691,8.939-38.272,8.939-64.747
				c0-43.413-11.563-71.061-18.496-83.755l20.651-20.629c13.931,6.4,46.229,19.072,83.179,19.072
				c39.019,0,72.832-14.123,85.333-20.075c12.501,5.952,46.315,20.075,85.333,20.075c36.949,0,69.248-12.693,83.179-19.072
				l20.651,20.629c-6.933,12.693-18.496,40.341-18.496,83.755c0,26.475,4.352,45.056,8.939,64.747
				c5.803,24.896,12.395,53.077,12.395,105.92C448.009,388.296,375.177,430.109,256.009,489.416z"/>
			<path d="M364.191,202.739l-68.736-9.813l-29.653-69.163c-3.349-7.851-16.256-7.851-19.605,0l-29.653,69.163l-68.736,9.813
				c-3.989,0.576-7.317,3.349-8.597,7.189c-1.28,3.84-0.277,8.064,2.581,10.923l49.067,49.067l-19.861,79.488
				c-1.045,4.16,0.491,8.512,3.904,11.093c3.413,2.581,8.021,2.88,11.733,0.768l69.376-39.637l69.376,39.637
				c1.643,0.939,3.477,1.408,5.291,1.408c2.283,0,4.544-0.725,6.421-2.176c3.413-2.581,4.949-6.955,3.904-11.093l-19.861-79.488
				l49.067-49.067c2.859-2.859,3.861-7.083,2.581-10.923C371.529,206.088,368.201,203.315,364.191,202.739z M301.791,259.123
				c-2.645,2.645-3.712,6.507-2.795,10.133l15.275,61.077L261.3,300.083c-1.643-0.939-3.477-1.408-5.291-1.408
				s-3.648,0.469-5.312,1.408l-52.971,30.251l15.275-61.077c0.917-3.648-0.149-7.488-2.795-10.133l-38.251-38.251l53.547-7.637
				c3.691-0.533,6.827-2.923,8.299-6.357l22.208-51.776l22.187,51.776c1.472,3.413,4.608,5.824,8.299,6.357l53.547,7.637
				L301.791,259.123z"/>
		</g>
	</g>
</g></svg>
  );
}
