import {
  DirectionsRenderer,
  InfoWindowF,
  MarkerClustererF,
  MarkerF,
} from "@react-google-maps/api";
import Map from "../map/Map";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import useMap from "@/hooks/useMap";
import CustomMarker from "../map/CustomMarker";
import { clusterIcons, DIRECTIONS_MODULES } from "@/const/search";
import { useEffect, useMemo, useRef, useState } from "react";
import ObjectRef from "../tile-items/ObjectRef";
import InfoWindowContent from "../tile-items/InfoWindowContent";
import nearbyMarker from "../../const/search/imgs/nearby.png";
import renderDirections from "@/utils/render-directions";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";
import Analysis from "./analysis";
import { Button } from "../ui/button";
// import nearbyAnimMarker from "../../const/search/imgs/nearby1.gif";

export default function Nearby({
  items,
  analysis,
  item,
}: {
  items: Marker[];
  analysis: string;
  item: SearchObject;
}) {
  const [nearbyHidden, setNearbyHidden] = useState(true);
  const [timestampNearbyOnly, setTimestampNearbyOnly] = useState(false);
  const [directions, setDirections] =
    useState<google.maps.DirectionsResult | null>(null);
  const timer = useRef<number | null>(null);
  const [infoWindow, setInfoWindow] = useState<{
    id: number;
    type: string;
  } | null>(null);
  const { fitBounds, map } = useMap();

  const objects = useMemo(
    () => (items.length > 0 ? items.flatMap(({ objects }) => objects) : []),
    [items]
  );

  // const highlightStyle = {
  //   border: "2px solid #ff00cd",
  //   borderRadius: "8px",
  //   animation: "glow 1.5s infinite alternate", // Add animation
  //   boxShadow: "0 0 10px #00E1FF", // Add initial glow effect
  // };

  useEffect(() => {
    if (objects.length === 0) return;
    timer.current = window.setTimeout(
      () =>
        fitBounds(
          objects.map((obj) => obj.location),
          { setZoom: false }
        ),
      100
    );
    return () => {
      timer.current && window.clearTimeout(timer.current);
    };
  }, [objects, fitBounds, map]);

  const timestampNearbyObjects = objects.filter((obj) => obj.nearby);
  const nearbyObjects = objects.filter((obj) => !obj.nearby);

  return (
    <div className="grid gap-6">
      <Card>
        <CardHeader className="flex flex-row justify-between items-center gap-4 p-0">
          <CardTitle>Map of Nearby Activity</CardTitle>
          <div className="flex items-center gap-4">
            <div className="flex items-center">
              <Checkbox
                id="nearby"
                checked={timestampNearbyOnly}
                onCheckedChange={(checked) =>
                  setTimestampNearbyOnly(checked as boolean)
                }
              />
              <Label htmlFor="nearby" className="pl-2 cursor-pointer">
                Nearby
              </Label>
            </div>
            {analysis && <Analysis analysis={analysis} />}
          </div>
        </CardHeader>
        <CardContent className="min-h-[560px] relative">
          <Map
            zoom={15}
            defaultOptions={{
              mapTypeId: google.maps.MapTypeId.SATELLITE,
            }}
          >
            {directions && <DirectionsRenderer directions={directions} />}
            {items.map(({ type, objects }, k) => (
              <MarkerClustererF
                options={{
                  styles: [
                    {
                      backgroundPosition: "3% -14%",
                      height: 74,
                      textColor: "#FFFFFF",
                      width: 74,
                      url: clusterIcons[type as keyof typeof clusterIcons],
                    },
                  ],
                }}
                key={`clusterer-${type}-${k}`}
              >
                {(clusterer) => (
                  <>
                    {(timestampNearbyOnly
                      ? objects.filter((obj) => obj.nearby)
                      : objects
                    ).map((obj) => (
                      <CustomMarker
                        {...obj}
                        clusterer={clusterer}
                        position={obj.location}
                        onClick={() => {
                          setInfoWindow({ type: obj.type, id: obj.id });
                          if (DIRECTIONS_MODULES.includes(obj.type)) {
                            (async () => {
                              const dirs = await renderDirections(obj as any);
                              setDirections(dirs);
                            })();
                          }
                        }}
                        key={`marker-${type}-${obj.id}`}
                      >
                        {infoWindow &&
                          infoWindow.id === obj.id &&
                          infoWindow.type === obj.type && (
                            <InfoWindowF
                              position={obj.location}
                              onCloseClick={() => setInfoWindow(null)}
                              key={`info-window-${type}-${obj.id}`}
                            >
                              <InfoWindowContent
                                obj={obj}
                                key={`info-window-content-${type}-${obj.id}`}
                              />
                            </InfoWindowF>
                          )}
                      </CustomMarker>
                    ))}
                  </>
                )}
              </MarkerClustererF>
            ))}
            {item && (
              <MarkerF
                clickable={false}
                position={item.location}
                icon={{
                  url: nearbyMarker,
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
              />
            )}
          </Map>
        </CardContent>
      </Card>
      <Card>
        <CardTitle>Timestamp Nearby</CardTitle>
        <CardContent className="grid grid-cols-4 gap-4">
          {timestampNearbyObjects.map((obj) => (
            // <div style={highlightStyle}>
            <ObjectRef
              {...obj}
              showTypeIcon
              authorDataEnabled
              key={obj.type + obj.id}
            />
            // </div>
          ))}
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="p-0 gap-4 flex items-center flex-row space-y-0">
          <CardTitle>Nearby</CardTitle>
          <Button
            className="h-8"
            size="sm"
            onClick={() => setNearbyHidden((prev) => !prev)}
            variant="outline"
          >
            {nearbyHidden ? "Show" : "Hide"} objects
          </Button>
        </CardHeader>
        {!nearbyHidden && (
          <CardContent className="grid grid-cols-4 gap-4">
            {nearbyObjects.map((obj) => (
              <ObjectRef
                {...obj}
                showTypeIcon
                authorDataEnabled
                key={obj.type + obj.id}
              />
            ))}
          </CardContent>
        )}
      </Card>
    </div>
  );
}
