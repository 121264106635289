import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./App.css";
import { PageLoader } from "./components/page-loader";
import { useAuth0 } from "@auth0/auth0-react";
import { Home } from "./pages/home";
import { AddLocation } from "./pages/add_location";
import { Database } from "./pages/database";
import { Index } from "./pages/main_page";
import { Reservoir } from "./pages/reservoir";
import { SearchLocation } from "./pages/search_location";
import { SingleItem } from "./pages/single_item";
import TileItems from "./pages/tile_items";
import { Settings } from "./pages/settings";
import { Upload } from "./pages/upload_page";
import { Sidebar } from "./components/sidebar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faMap,
  faMoon,
  faSun,
  faCircleDot,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBars,
  faChevronLeft,
  faPowerOff,
  faLocationCrosshairs,
  faMagnifyingGlass,
  faDatabase,
  faTrash,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useLayoutEffect, useState } from "react";
import toast, { useToasterStore } from "react-hot-toast";
import TutorialProvider from "./providers/tutorial";
import EntryPopup from "./components/tutorial/entry";
import SearchDatabase from "./pages/search-database";
import SearchUsername from "./pages/search_username";
import MapsProvider from "./providers/MapsProvider";
import UploadPage from "./pages/upload";
import Timeline from "./pages/timeline";
import ThemeProvider from "./providers/theme";
import { FiltersProvider } from "./providers/search/filters";
import { waveform } from "ldrs";

library.add(
  fab,
  faBars,
  faMap,
  faChevronLeft,
  faPowerOff,
  faMoon,
  faSun,
  faLocationCrosshairs,
  faCircleDot,
  faMagnifyingGlass,
  faDatabase,
  faTrash,
  faHome
);

waveform.register();

const TOAST_LIMIT = 4;

function App() {
  const { toasts } = useToasterStore();
  const [sidebarShown, setSidebarShown] = useState(false);
  const { pathname } = useLocation();
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } =
    useAuth0();

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [pathname]);

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  const toggleSidebar = (bool) => {
    setSidebarShown((prev) => (typeof bool !== "undefined" ? bool : !prev));
  };

  if (isAuthenticated) {
    return (
      <ThemeProvider>
        <TutorialProvider>
          <Sidebar
            logout={logout}
            toggleSidebar={toggleSidebar}
            shown={sidebarShown}
            user={user}
          />
          <Routes>
            <Route
              path="/"
              element={<Index user={user} sidebarShown={sidebarShown} />}
            />
            <Route
              path="/dashboard"
              element={
                <MapsProvider>
                  <Home user={user} sidebarShown={sidebarShown} />
                </MapsProvider>
              }
            />
            <Route
              path="/timeline"
              element={
                <MapsProvider>
                  <Timeline user={user} sidebarShown={sidebarShown} />
                </MapsProvider>
              }
            />
            <Route
              path="/add-location"
              element={<AddLocation sidebarShown={sidebarShown} />}
            />
            <Route
              path="/reservoir"
              element={<Reservoir sidebarShown={sidebarShown} />}
            />
            <Route
              path="/search"
              element={
                <MapsProvider>
                  <FiltersProvider>
                    <SearchLocation sidebarShown={sidebarShown} />
                  </FiltersProvider>
                </MapsProvider>
              }
            />
            <Route
              path="/database"
              element={<Database sidebarShown={sidebarShown} />}
            />
            <Route
              path="/search-database"
              element={
                <MapsProvider>
                  <SearchDatabase sidebarShown={sidebarShown} />
                </MapsProvider>
              }
            />
            <Route
              path="/search-username"
              element={<SearchUsername sidebarShown={sidebarShown} />}
            />
            <Route
              path="/geolocate-photo"
              element={<UploadPage sidebarShown={sidebarShown} from_photo />}
            />
            <Route
              path="/upload-locations"
              element={<UploadPage sidebarShown={sidebarShown} from_upload />}
            />
            <Route path="*" element={<Navigate to="/database" />} />
            {/*<Route path="/flickr/" element={<CoordinatesItems bright={bright}/>}/>*/}
            <Route
              path="/module/:type"
              element={
                <MapsProvider>
                  <TileItems sidebarShown={sidebarShown} />
                </MapsProvider>
              }
            />
            <Route
              path="/module/:type/item/:item_id"
              element={
                <MapsProvider>
                  <SingleItem sidebarShown={sidebarShown} />
                </MapsProvider>
              }
            />
            <Route
              path="/settings"
              element={<Settings sidebarShown={sidebarShown} />}
            />
            <Route path="/upload" element={<Upload />} />
            {/* <Route path="/callback" element={<Callback />} /> */}
          </Routes>
          <EntryPopup />
        </TutorialProvider>
      </ThemeProvider>
    );
  } else {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    });
  }
}

export default App;
