type Props = {
  isDisabled: boolean;
  isActive: boolean;
  hashtag: string;
  onClick: () => void;
};

export default function HashtagRef({
  hashtag,
  isActive,
  isDisabled,
  onClick,
}: Props) {
  return (
    <div className="relative flex items-center group max-w-max">
      <button
        onClick={onClick}
        disabled={isDisabled}
        className={`rounded-full flex items-center gap-2 text-base px-4 py-2 border-[1px] disabled:opacity-60 ${
          isActive
            ? "bg-[var(--primary-color2)] border-[var(--primary-color2)] text-[var(--primary-color-light)]"
            : "bg-[var(--primary-color-light)] text-[var(--primary-color2)] border-[#DEEBFF] dark:border-[#376187] group-hover:bg-[var(--primary-color2)] group-hover:border-[var(--primary-color2)] dark:group-hover:border-[var(--primary-color2)] group-hover:text-[var(--primary-color-light)] disabled:group-hover:!border-[#DEEBFF] dark:disabled:!border-[#376187] disabled:group-hover:!bg-[var(var(--primary-color-light))]"
        }`}
      >
        <span className="uppercase">{hashtag}</span>
      </button>
    </div>
  );
}
