import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardTitle } from "./ui/card";
import { Button } from "./ui/button";

export default function Popup({
  isLoading = false,
  title,
  paragraph = "",
  submitText = "Delete",
  cancelText = "Cancel",
  onSubmit,
  onClose,
}) {
  return (
    <div
      className={`fixed z-50 inset-0 w-full h-full grid place-content-center`}
    >
      <Card className="w-full max-w-[6in] flex flex-col items-center gap-6 relative z-10 text-center">
        <div className="h-12 w-12 grid place-content-center border border-slate-200 bg-white hover:bg-slate-100 dark:bg-[#161B26] dark:border-[#333741] text-[var(--primary-color2)] rounded-sm">
          <FontAwesomeIcon icon={faWarning} />
        </div>
        <CardTitle>{title}</CardTitle>
        {paragraph && (
          <p className="text-[var(--text-color)] opacity-80 text-lg max-w-sm">
            {paragraph}
          </p>
        )}
        <div className="flex items-center gap-3">
          {onSubmit && (
            <button
              onClick={onClose}
              className="text-[var(--primary-color2)] font-medium py-2.5 px-5 text-sm rounded-sm border-[1px] border-white dark:border-[var(--body-color)] dark:hover:border-[var(--primary-color-light)] hover:border-pink-shade transition-colors dark:hover:bg-[var(--primary-color-light)] hover:bg-pink-shade"
            >
              {cancelText}
            </button>
          )}
          <Button disabled={isLoading} onClick={onSubmit ? onSubmit : onClose}>
            {submitText}
          </Button>
        </div>
      </Card>
      <div
        className="absolute inset-0 w-full h-full bg-black/40 backdrop-blur-[2px]"
        onClick={onClose}
      />
    </div>
  );
}
