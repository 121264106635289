import React from "react";

export const Upload = () => {
  return (
    <section className="home">
      <div className="dashboard">
        <div className="item">
          <div className="item-title">Upload CSV</div>

          <div className="item-count">
            <textarea name="postContent" />
            <button className="search_button">{"Save"}</button>
          </div>
        </div>

        <div className="item">
          <div className="item-title">Choose an icon</div>

          <div className="item-count">
            <div>
              <input type="radio" value="Male" name="gender" /> Male{" "}
              {/* get icons from /static add as a label */}
              <input type="radio" value="Female" name="gender" /> Female
              <input type="radio" value="Other" name="gender" /> Other
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
