import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { geolocateUpload } from "../../utils/upload/lib";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import Toast from "../../components/Toast";
import { WorkspaceContext } from "../../providers/workspace";
import { getCoordinates, deleteCoordinates } from "@/utils/search/lib";

const baseClassName =
  "flex-1 flex flex-col items-center gap-8 justify-center p-5 border-2 rounded-lg border-[var(--text-color)] border-dashed text-[var(--text-color)] transition-colors";

const acceptedClassName =
  "border-[var(--pink-color)] text-[var(--pink-color)] dark:border-[var(--blue-color)] dark:text-[var(--blue-color)]";

const rejectedClassName = "border-red-500 text-red-500";

export default function usePhotoGeolocation({
  from_photo,
  from_upload,
}: Partial<Pick<Area, "from_photo" | "from_upload">>) {
  const { setWorkspace } = useContext(WorkspaceContext);
  const [deleteItem, setDeleteItem] = useState<Pick<
    Area,
    "id" | "title"
  > | null>(null);
  const [results, setResults] = useState<PhotoArea[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState({
    page: true,
    removal: false,
    geolocation: false,
  });

  const handleGeolocation = useCallback(
    async (acceptedFiles: File[]) => {
      setIsLoading((prev) => ({ ...prev, geolocation: true }));
      const token = await getAccessTokenSilently();
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = async () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          const filename = file.name;
          const { error } = await geolocateUpload(token, binaryStr, filename, {
            from_upload,
          });
          setIsLoading((prev) => ({ ...prev, geolocation: false }));
          if (error) {
            toast.custom((t) => (
              <Toast
                {...t}
                isError
                title="Something went wrong!"
                subtitle={error}
              />
            ));
          } else {
            const locationSearchId = null;
            const coordinates = await getCoordinates(
              token,
              locationSearchId,
              from_photo,
              from_upload
            );
            setResults(coordinates);
          }
        };
        reader.readAsDataURL(file);
      });
    },
    [getAccessTokenSilently, from_photo, from_upload]
  );

  const { isDragAccept, isDragReject, getRootProps, getInputProps } =
    useDropzone({
      onDrop: handleGeolocation,
      accept: {
        ...(from_upload
          ? {
              "text/csv": [".csv"],
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".csv"],
              "application/vnd.ms-excel": [".csv"],
            }
          : {}),
        ...(from_photo
          ? {
              "image/*": [".png", ".jpeg", ".jpg"],
            }
          : {}),
      },
    });

  const className: string = useMemo(
    () =>
      `${baseClassName} ${isDragAccept ? acceptedClassName : ""} ${
        isDragReject ? rejectedClassName : ""
      }`,
    [isDragAccept, isDragReject]
  );

  async function handleDelete() {
    if (!deleteItem) return;
    setIsLoading((prev) => ({ ...prev, removal: true }));
    try {
      const token = await getAccessTokenSilently();
      const status = await deleteCoordinates(token, deleteItem.id);
      if (status && status < 300) {
        setResults((prev) => prev.filter((item) => item.id !== deleteItem.id));
        setDeleteItem(null);
      }
    } catch (e) {
      toast.custom((t) => (
        <Toast
          {...t}
          isError
          title="Something went wrong!"
          subtitle={(e as Error).message}
        />
      ));
    } finally {
      setIsLoading((prev) => ({ ...prev, removal: false }));
    }
  }

  useEffect(() => {
    setWorkspace(null);
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const coordinates: PhotoArea[] = await getCoordinates(
          token,
          "",
          from_photo,
          from_upload
        );
        setResults(coordinates);
        setIsLoading((prev) => ({ ...prev, page: false }));
      } catch (e) {
        // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently, setWorkspace, from_photo, from_upload]);

  const rootProps = getRootProps({ className });
  const inputProps = getInputProps();

  return {
    isLoading,
    results,
    rootProps,
    inputProps,
    deleteItem,
    setDeleteItem,
    handleDelete,
    handleGeolocation,
  };
}
