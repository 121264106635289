import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { ICON_TYPES } from "../../const/search";

export default function UsernameRef({
  data,
  isActive,
  handleSearch,
  handleDelete,
  isDisabled,
}) {
  const { username, type } = data;
  const icon = ICON_TYPES[type];
  return (
    <div className="relative flex items-center group">
      <button
        disabled={isDisabled || isActive}
        onClick={handleSearch}
        className={`rounded-full flex items-center gap-2 text-base px-4 py-2 pr-10 border-[1px] disabled:opacity-60 ${
          isActive
            ? "bg-[var(--primary-color2)] border-[var(--primary-color2)] text-[var(--primary-color-light)]"
            : "bg-[var(--primary-color-light)] text-[var(--primary-color2)] border-[#DEEBFF] dark:border-[#376187] group-hover:bg-[var(--primary-color2)] group-hover:border-[var(--primary-color2)] dark:group-hover:border-[var(--primary-color2)] group-hover:text-[var(--primary-color-light)] disabled:group-hover:!border-[#DEEBFF] dark:disabled:!border-[#376187] disabled:group-hover:!bg-[var(var(--primary-color-light))]"
        }`}
      >
        <img className="w-5 h-5" src={icon} alt={type} />
        <span className="uppercase">{username}</span>
      </button>
      <button
        className="absolute right-4"
        onClick={handleDelete}
        disabled={isDisabled}
      >
        <FontAwesomeIcon
          className={`${
            isActive
              ? "text-[var(--primary-color-light)]"
              : "text-[var(--primary-color2)] group-hover:text-[var(--primary-color-light)] disabled:!text-[var(--primary-color2)]"
          } disabled:opacity-60`}
          icon={faXmark}
        />
      </button>
    </div>
  );
}
