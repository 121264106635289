import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { createContext } from "react";

type Workspace = {
  id: number;
  title: string;
};

type WorkspaceContextType = {
  workspace: Workspace | null;
  setWorkspace: Dispatch<SetStateAction<Workspace | null>>;
};

export const WorkspaceContext = createContext<WorkspaceContextType>(null!);

export function useWorkspace() {
  const workspace = useContext(WorkspaceContext);
  return workspace;
}

const workspaceFromStorage = localStorage.getItem("workspace");
const parsedWorkspace = workspaceFromStorage
  ? JSON.parse(workspaceFromStorage)
  : null;

export default function WorkspaceProvider({ children }: { children: any }) {
  const [workspace, setWorkspace] = useState<Workspace | null>(parsedWorkspace);
  useEffect(() => {
    if (workspace) {
      localStorage.setItem("workspace", JSON.stringify(workspace));
    } else {
      localStorage.removeItem("workspace");
    }
  }, [workspace]);

  return (
    <WorkspaceContext.Provider value={{ workspace, setWorkspace }}>
      {children}
    </WorkspaceContext.Provider>
  );
}
