import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useMemo, useState } from "react";
import { WorkspaceContext } from "../../providers/workspace";
import { getCoordinates, getSearchResults } from "../../utils/search/lib";
import Button from "../../components/Button";
import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SEARCH_OPTIONS, clusterIcons } from "../../const/search";
import ObjectRef from "../../components/tile-items/ObjectRef";
import CustomMarker from "../../components/map/CustomMarker";
import Map from "../../components/map/Map";
import Bright from "../../components/map_with_search/bright.json";
import Dark from "../../components/map_with_search/dark.json";
import {
  InfoWindowF,
  MarkerClustererF,
  PolygonF,
} from "@react-google-maps/api";
import { POLYGON_MODULES } from "../../const/general";
import InfoWindowContent from "../../components/tile-items/InfoWindowContent";
import makeAnimated from "react-select/animated";
import OssSelect from "../../components/search/ossselect";
import toast from "react-hot-toast";
import Toast from "../../components/Toast";
import AuthorRef from "../../components/single-item/author-ref";
import NoMapResultsIcon from "../../const/search/imgs/no-map-results";
import EyeSlashIcon from "../../const/search/imgs/eye-slash";
import useMap from "../../hooks/useMap";
import { useTheme } from "@/providers/theme";

const animatedComponents = makeAnimated();

const google = (window.google = window.google ? window.google : {});

const selectClassNames = {
  container: () =>
    "text-sm text-[var(--text-color)] h-max rounded-sm !bg-white dark:!bg-[var(--primary-color-light)] min-w-[1in] flex-1",
  placeholder: () => "text-[var(--input-color)]",
  option: ({ isSelected }) =>
    `${
      isSelected
        ? "!bg-[var(--primary-color)] dark:!bg-[var(--primary-color)] !text-white"
        : "!bg-white dark:!bg-[var(--primary-color-light)] hover:!bg-[var(--primary-color)] dark:hover:!bg-[var(--primary-color)] hover:!text-white text-[var(--text-color)]"
    } text-sm px-4 py-2 cursor-pointer`,

  menu: () => "bg-[var(--primary-color-light)] ",
};

const dataOptions = [
  { label: "Item", isPerson: false },
  { label: "Person", isPerson: true },
];

export default function SearchDatabase({ sidebarShown }) {
  const { getAccessTokenSilently } = useAuth0();
  const { setWorkspace } = useContext(WorkspaceContext);
  const { map } = useMap();
  const [infoWIndow, setInfoWIndow] = useState(null);
  const [input, setInput] = useState("");
  const [results, setResults] = useState([]);
  const [isPerson, setIsPerson] = useState(false);
  const [types, setTypes] = useState([]);
  const [chosenWorkspace, setChosenWorkspace] = useState(null);
  const [workspaces, setWorkspaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentResultsData, setCurrentResultsData] = useState({
    workspace: null,
    isPerson: false,
    input: "",
  });
  const { isDark } = useTheme();

  useEffect(() => {
    setWorkspace(null);
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const coordinates = await getCoordinates(token);
        setWorkspaces(Array.isArray(coordinates) ? coordinates : []);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [getAccessTokenSilently, setWorkspace]);

  async function handleSearch(e) {
    e.preventDefault();
    setIsLoading(true);
    setResults([]);
    setInfoWIndow(null);
    setCurrentResultsData({
      workspace: chosenWorkspace,
      isPerson,
      input,
    });
    try {
      const token = await getAccessTokenSilently();
      const data = await getSearchResults(
        token,
        input,
        isPerson ? "person" : "item",
        types.map((item) => item.value),
        chosenWorkspace.id
      );
      if (data.message) {
        toast.custom((t) => (
          <Toast {...t} isError title="Error" subtitle={data.message} />
        ));
      } else {
        data.results.length > 0
          ? setResults(data.results)
          : toast.custom((t) => (
              <Toast
                {...t}
                title="Nothing has been found"
                subtitle="Change parameters and try again"
              />
            ));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  const typeResults = useMemo(
    () =>
      results.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.type]: prev.hasOwnProperty(curr.type)
            ? [...prev[curr.type], curr]
            : [curr],
        }),
        {}
      ),
    [results]
  );

  return (
    <section
      className={`home transition-all ${
        sidebarShown ? "md:ml-[250px]" : "md:ml-[88px]"
      } flex flex-col gap-8 xl:gap-4 w-full px-8 pb-8 database_wrapper`}
    >
      <h1 className="text-xl text-[var(--text-color)] text-center font-medium">
        Search in database
      </h1>
      <form
        onSubmit={handleSearch}
        className="flex flex-col gap-2 md:gap-4 md:grid md:items-center grid-cols-[max-content_1fr_max-content_1fr] xl:grid-cols-[max-content_1fr_max-content_1fr_max-content_1fr_max-content] grid-rows-[repeat(3,max-content)]"
      >
        <h3 className="text-base text-[var(--text-color)] opacity-80 w-max">
          Search
        </h3>
        <div className="relative flex items-center col-span-3 xl:col-span-6">
          <FontAwesomeIcon
            width={12}
            className="absolute left-4 text-[#DEEBFF] dark:text-[#376187]"
            icon={faSearch}
          />
          <input
            className="!text-[var(--text-color)] pl-10 w-full rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            type="text"
            placeholder="Keyword"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
        </div>
        <h3 className="text-base text-[var(--text-color)] opacity-80 w-max">
          Modules
        </h3>
        <OssSelect
          className="flex-1"
          options={
            isPerson
              ? SEARCH_OPTIONS.filter((item) => item.authorData).map(
                  (item) => ({
                    ...item,
                    options: [...item.options].filter(
                      (item) => item.authorData !== false
                    ),
                  })
                )
              : SEARCH_OPTIONS
          }
          isSearchable={false}
          isOptionSelected={(option) => {
            types.find((item) => item.value === option.value);
          }}
          isMulti
          areGrouped
          placeholder="Select"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={animatedComponents}
          allowSelectAll={true}
          optionsSelected={types}
          handleMultiSelectChange={(_, items) => setTypes(items)}
          formatGroupLabel={({ title }) => (
            <div className="pb-1 pt-0.5 bg-[var(--primary-color-light)]">
              <span className="text-sm uppercase text-[var(--input-color)]">
                {title}
              </span>
            </div>
          )}
          getOptionLabel={({ icon, label }) => {
            const Icon = icon;
            return (
              <div className="flex items-center gap-2 item-styled">
                {icon &&
                  (typeof icon === "function" ? (
                    <div className="fill-[var(--text-color)] stroke-[var(--text-color)] w-4">
                      <Icon />
                    </div>
                  ) : (
                    <FontAwesomeIcon icon={icon} />
                  ))}
                <span className="item-title">{label}</span>
              </div>
            );
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: !isDark ? "#fff" : "#193148",
              primary50: !isDark ? "#00e1ff" : "#193148",
              primary: !isDark ? "#00e1ff" : "#ff00cd",
              neutral0: !isDark ? "#fff" : "#193148",
              neutral5: !isDark ? "#fff" : "#00e1ff",
              neutral10: !isDark ? "#00e1ff" : "#ff00cd",
              neutral20: !isDark ? "#DEEBFF" : "#376187",
              neutral30: !isDark ? "#00e1ff" : "#ff00cd",
              neutral40: !isDark ? "#193148" : "#ff00cd",
              neutral50: !isDark ? "#193148" : "#5f8cb4",
              neutral60: !isDark ? "#193148" : "#ff00cd",
              neutral70: !isDark ? "#193148" : "#ff00cd",
              neutral80: !isDark ? "#193148" : "#ff00cd",
              neutral90: !isDark ? "#193148" : "#ff00cd",
              neutral100: !isDark ? "#193148" : "#376187",
            },
          })}
        />
        <h3 className="text-base text-[var(--text-color)] opacity-80 w-max">
          Workspace
        </h3>
        <ReactSelect
          options={workspaces?.map((wrkspace) => ({
            value: wrkspace,
            label: wrkspace.title,
          }))}
          onChange={({ value }) => setChosenWorkspace(value)}
          value={workspaces.find((item) => item === chosenWorkspace?.id)}
          placeholder="Select"
          isOptionSelected={({ value }) => chosenWorkspace?.id === value.id}
          classNames={selectClassNames}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: !isDark ? "#fff" : "#193148",
              primary50: !isDark ? "#00e1ff" : "#193148",
              primary: !isDark ? "#00e1ff" : "#ff00cd",
              neutral0: !isDark ? "#fff" : "#193148",
              neutral5: !isDark ? "#fff" : "#00e1ff",
              neutral10: !isDark ? "#00e1ff" : "#ff00cd",
              neutral20: !isDark ? "#DEEBFF" : "#376187",
              neutral30: !isDark ? "#00e1ff" : "#ff00cd",
              neutral40: !isDark ? "#193148" : "#ff00cd",
              neutral50: !isDark ? "#193148" : "#5f8cb4",
              neutral60: !isDark ? "#193148" : "#ff00cd",
              neutral70: !isDark ? "#193148" : "#ff00cd",
              neutral80: !isDark ? "#193148" : "#fff",
              neutral90: !isDark ? "#193148" : "#ff00cd",
              neutral100: !isDark ? "#193148" : "#376187",
            },
          })}
        />
        <h3 className="text-base text-[var(--text-color)] opacity-80 w-max">
          Item / Person
        </h3>
        <ReactSelect
          options={dataOptions}
          value={dataOptions.find((item) => item.isPerson === isPerson)}
          onChange={({ isPerson }) => {
            isPerson &&
              setTypes((prev) =>
                prev.filter((option) => {
                  let indexOfChild = null;
                  const parent = SEARCH_OPTIONS.find((item) => {
                    const index = item.options.findIndex(
                      (item) => item.value === option.value
                    );
                    indexOfChild = index;
                    return index !== -1;
                  });
                  return (
                    parent.authorData &&
                    indexOfChild !== -1 &&
                    parent.options[indexOfChild].authorData !== false
                  );
                })
              );
            setIsPerson(isPerson);
          }}
          isSearchable={false}
          isOptionSelected={(props) => props.isPerson === isPerson}
          classNames={selectClassNames}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: !isDark ? "#fff" : "#193148",
              primary: !isDark ? "#00e1ff" : "#ff00cd",
              neutral0: !isDark ? "#fff" : "#193148",
              neutral5: !isDark ? "#fff" : "#00e1ff",
              neutral10: !isDark ? "#00e1ff" : "#ff00cd",
              neutral20: !isDark ? "#DEEBFF" : "#376187",
              neutral30: !isDark ? "#00e1ff" : "#ff00cd",
              neutral40: !isDark ? "#193148" : "#ff00cd",
              neutral50: !isDark ? "#193148" : "#5f8cb4",
              neutral60: !isDark ? "#193148" : "#ff00cd",
              neutral70: !isDark ? "#193148" : "#ff00cd",
              neutral80: !isDark ? "#193148" : "#fff",
              neutral90: !isDark ? "#193148" : "#ff00cd",
              neutral100: !isDark ? "#193148" : "#376187",
            },
          })}
        />
        <div className="hover:opacity-80 transition-opacity mt-2 md:mt-0 col-span-2 xl:col-span-1">
          <Button
            reverseFill
            preventHover
            icon={faSearch}
            className="!w-full !px-16"
            disabled={isLoading}
          >
            Search
          </Button>
        </div>
      </form>

      <div className="flex flex-col gap-4 bg-[var(--sidebar-color)] rounded p-6">
        <h3 className="text-[var(--text-color)] font-medium">Map</h3>
        <div className="h-full w-full min-h-[4in] relative grid grid-cols-1">
          {results.length > 0 ? (
            <Map
              zoom={results.length > 0 && 14}
              center={
                results.length > 0 && currentResultsData.isPerson
                  ? results[0].associated_item.location
                  : results[0].location
              }
            >
              {Object.keys(typeResults).map((type) => (
                <MarkerClustererF
                  options={{
                    styles: [
                      clusterIcons[type] && {
                        backgroundPosition: "3% -14%",
                        height: 74,
                        textColor: "#FFFFFF",
                        width: 74,
                        url: clusterIcons[type],
                      },
                    ],
                  }}
                >
                  {(clusterer) =>
                    typeResults[type].map((obj) => {
                      const polygonModule = POLYGON_MODULES.find(
                        (item) => item.value === type
                      );
                      return polygonModule ? (
                        <>
                          {infoWIndow &&
                            infoWIndow.type === type &&
                            infoWIndow.id === obj.id && (
                              <InfoWindowF
                                position={infoWIndow}
                                onCloseClick={() => setInfoWIndow(null)}
                              >
                                <InfoWindowContent obj={obj} />
                              </InfoWindowF>
                            )}
                          <PolygonF
                            paths={JSON.parse(obj.polygon)}
                            options={{
                              fillColor: polygonModule.color,
                              fillOpacity: 0.7,
                              strokeColor: polygonModule.color,
                            }}
                            onClick={(e) => {
                              const location = {
                                lat: e.latLng.lat(),
                                lng: e.latLng.lng(),
                              };
                              const latLng = new google.maps.LatLng(
                                location.lat,
                                location.lng
                              );
                              const bounds = new google.maps.LatLngBounds();
                              bounds.extend(latLng);
                              map.fitBounds(bounds);
                              setInfoWIndow({ id: obj.id, type, ...location });
                            }}
                            key={obj.id}
                          />
                        </>
                      ) : (
                        <CustomMarker
                          {...obj}
                          position={
                            obj.associated_item
                              ? obj.associated_item.location
                              : obj.location
                          }
                          clusterer={clusterer}
                          onMouseOver={() =>
                            setInfoWIndow({
                              id: isPerson ? obj.associated_item.id : obj.id,
                              type,
                            })
                          }
                          key={obj.type + obj.id}
                        >
                          {infoWIndow &&
                            infoWIndow.id ===
                              (isPerson ? obj.associated_item.id : obj.id) &&
                            infoWIndow.type === type && (
                              <InfoWindowF
                                position={
                                  obj.associated_item
                                    ? obj.associated_item.location
                                    : obj.location
                                }
                                onCloseClick={() => setInfoWIndow(null)}
                              >
                                <InfoWindowContent obj={obj} />
                              </InfoWindowF>
                            )}
                        </CustomMarker>
                      );
                    })
                  }
                </MarkerClustererF>
              ))}
            </Map>
          ) : (
            <div className="flex flex-col items-center gap-2 self-center justify-self-center text-[var(--text-color)]">
              <div className="stroke-[var(--text-color)] opacity-80 mb-2">
                <NoMapResultsIcon />
              </div>
              <h3 className="text-2xl font-medium">No results</h3>
              <p className="text-lg opacity-80">
                Please add a new item in the search bar
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-4 mt-4">
        <h2 className="text-[var(--text-color)] font-medium">
          Results{" "}
          <span className="text-[var(--text-color)] font-normal opacity-80">
            {`(${results.length})`}
          </span>
        </h2>
        {isLoading ? (
          <div className="mx-auto flex items-center gap-2 text-[var(--primary-color2)] mt-4">
            <FontAwesomeIcon className="w-max animate-spin" icon={faSpinner} />
            <span className="text-base">Loading</span>
          </div>
        ) : results.length > 0 ? (
          <div className="flex flex-col gap-6 sm:grid grid-cols-[repeat(auto-fill,minmax(340px,1fr))]">
            {results.map((result) =>
              currentResultsData.isPerson ? (
                <AuthorRef
                  person={result}
                  wasSearched
                  workspaceId={currentResultsData.workspace.id}
                  workspaceTitle={currentResultsData.workspace.title}
                />
              ) : (
                <ObjectRef
                  {...result}
                  showTypeIcon
                  workspaceId={currentResultsData.workspace.id}
                  workspaceTitle={currentResultsData.workspace.title}
                  key={result.id + result.type}
                />
              )
            )}
          </div>
        ) : (
          <div className="flex flex-col items-center gap-2 self-center justify-self-center text-[var(--text-color)]">
            <div className="stroke-[var(--text-color)] opacity-80 mb-2">
              <EyeSlashIcon />
            </div>
            <h3 className="text-2xl font-medium">No results</h3>
            <p className="text-lg opacity-80">
              Please add a new item in the search bar
            </p>
          </div>
        )}
      </div>
    </section>
  );
}
