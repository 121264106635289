export default function FindpenguinsIconComponent() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="90%"
      width="80%"
      viewBox="0 0 30 30"
      role="img"
      stroke="inherit"
      //       strokeWidth="22.089999999999996"
    >
      <g>
        <path
          className="st0"
          d="M25.2,0H6.9C3.1,0,0,3.2,0,6.9v18.2C0,28.9,3.2,32,6.9,32h18.2c3.8,0,6.9-3.2,6.9-6.9V6.9
		C32.1,3.2,28.9,0,25.2,0z M2.7,25.2V6.9c0-2.4,1.9-4.2,4.2-4.2h18.2c2.4,0,4.2,1.9,4.2,4.2v16.8c-0.7-1-1.5-2.1-2.3-3L27,20.7
		c-1.4-1.6-3.6-3.5-5-4.3c-1.4-0.8-2.8-2.1-3.2-2.8c-0.3-0.7-1-1.6-1.6-2c-0.6-0.4-1.8-0.8-2.8-0.9c-0.9-0.1-2.3-0.5-3.1-0.8
		c-0.8-0.3-2.1-0.6-3-0.6s-1,0.1-0.2,0.3c0.8,0.2,2,0.8,2.6,1.2c0.7,0.4,1.6,1.3,2,1.9c0.4,0.6,0.9,1.4,1,1.7c0.1,0.3,0.3,1,0.4,1.5
		c0.1,0.5-0.3,2.2-0.8,3.7c-0.5,1.6-0.9,4.5-0.8,6.6v0.2c0,0.9,0.1,1.9,0.3,2.9h-6C4.6,29.4,2.7,27.5,2.7,25.2z"
        />
      </g>
    </svg>
  );
}
