import {
  faBolt,
  faDroplet,
  faPersonMilitaryRifle,
  faPlaceOfWorship,
  faTowerBroadcast,
  faTruck,
  faTruckMedical,
} from "@fortawesome/free-solid-svg-icons";

export const PIE_CHART_COLORS = [
  "#00E1FF",
  "#ff00cd",
  "#293f73",
   "#FF5733",
  "#33FFBD",
  "#FFD700",
  "#8A2BE2",
  "#00FA9A",
  "#FF4500",
  "#1E90FF",
  "#FF69B4",
  "#32CD32",
  "#FF1493",
  "#FFDAB9",
  "#7FFFD4",
  "#6A5ACD",
  "#20B2AA",
  "#FF6347",
  "#FFB6C1",
  "#4169E1",
  "#ADFF2F",
  "#FFA07A",
  "#BA55D3"
];

export const POLYGON_MODULES: PolygonModule[] = [
  {
    label: "Communication",
    value: "communication",
    color: "#9400D3",
    icon: faTowerBroadcast,
  },
  {
    label: "Emergency",
    value: "emergency",
    color: "#E0115F",
    icon: faTruckMedical,
  },
  {
    label: "Military",
    value: "military",
    color: "#9ACD32",
    icon: faPersonMilitaryRifle,
  },
  {
    label: "Power",
    value: "power",
    color: "#00E1FF",
    icon: faBolt,
  },
  {
    label: "Sacral",
    value: "sacral",
    color: "#FFEA00",
    icon: faPlaceOfWorship,
  },
  {
    label: "Transportation",
    value: "transportation",
    color: "#008080",
    icon: faTruck,
  },
  {
    label: "Water",
    value: "water",
    color: "#0064d7",
    icon: faDroplet,
  },
];

export const DATE_KEYS = ["timestamp", "date_created", "created_at"];
