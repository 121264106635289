import { useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faCheck,
  faCog,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { WorkspaceContext } from "../../providers/workspace";

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);
  }

  return stripePromise;
};

export const Index = (props) => {
  const { setWorkspace } = useContext(WorkspaceContext);
  const { getAccessTokenSilently } = useAuth0();
  const [items, setItems] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setWorkspace(null);
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/getUserInfo/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const user_info = await response.json();
        setItems(user_info);
        setIsActive(user_info.is_active);
      } catch (e) {
        // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [getAccessTokenSilently, setWorkspace]);

  const products = [
    {
      priceId: process.env.REACT_APP_PRICE_ID_MINI,
      quantity: 1,
    },
    {
      priceId: process.env.REACT_APP_PRICE_ID_MAX,
      quantity: 1,
    },
  ];

  const redirectToCheckout = async (priceId) => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();

      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/create-checkout-session/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            price_id: priceId,
            success_url: `${process.env.REACT_APP_API_SERVER_URL}/api/success/`,
            cancel_url: `${process.env.REACT_APP_API_SERVER_URL}/api/cancel/`,
            email: items.email,
          }),
        }
      );
      const session = await response.json();

      const stripe = await getStripe();

      const { error } = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (error) {
        console.log("Stripe checkout error", error);
      }
    } catch (error) {
      console.error("Error in redirectToCheckout:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <l-waveform size={48} color="var(--text-color)" />
      </div>
    );
  }

  return (
    <section
      className={`home transition-all ${
        props.sidebarShown ? "md:ml-[250px]" : "md:ml-[88px]"
      } flex flex-col gap-4 w-full py-16 px-8`}
    >
      {isActive ? (
        <div className="pricing-plans">
          <div className="pricing-card basic">
            <div className="heading">
              <h4>Subscribed!</h4>
              {/*           <p>for small websites or blogs</p> */}
            </div>
            {/*         <p className="price"> */}
            {/*           $49 */}
            {/*           <sub>/month</sub> */}
            {/*         </p> */}
            <ul className="features">
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>{items.shodan_limit}</strong>{" "}
                <span>
                  Shodan (IoT, ICS, Exposed Cameras, Vulnerabilities) requests
                </span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>{items.wigle_limit}</strong>{" "}
                <span>Wigle data points (Wifi, Bluetooth, Cell Towers)</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>{items.hashtag_limit}</strong>{" "}
                <span>Hashtag searches (Instagram, Facebook, Tiktok)</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>{items.other_limit}</strong>{" "}
                <span>requests for all other modules</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>{items.username_limit}</strong>{" "}
                <span>Username searches</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>{items.ai_geolocate_limit}</strong>{" "}
                <span>AI Geolocation searches</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>{items.facecheck_limit}</strong>{" "}
                <span>Face recognition searches</span>
              </li>
            </ul>
            <ul className="features">
              <li>
                <span>
                  <b>
                    For any questions or issues, please join discord channel{" "}
                    <a
                      href="https://discord.gg/TbNUdvxeEW"
                      target="_blank"
                      rel="noreferrer"
                    >
                      HERE
                    </a>{" "}
                    <br></br>
                    or contact me at contact@offensiveosint.io{" "}
                  </b>
                </span>
              </li>
            </ul>
            <Link className="cta-btn" to="/settings">
              <FontAwesomeIcon icon={faCog} />
              Settings
            </Link>
          </div>
        </div>
      ) : (
        <div className="pricing-plans">
          <div className="pricing-card basic hover">
            <div className="heading">
              <h4>Mini</h4>
              {/*           <p>for small websites or blogs</p> */}
            </div>
            <p className="price">
              $49
              <sub>/month</sub>
            </p>
            <ul className="features">
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>125</strong>{" "}
                <span>
                  Shodan (IoT, ICS, Exposed Cameras, Vulnerabilities) requests
                </span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>250</strong>{" "}
                <span>
                  Wigle data points to retrieve (Wifi, Bluetooth, Cell Towers)
                </span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>10</strong>{" "}
                <span>Hashtag searches (Instagram, Facebook, Tiktok)</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>50</strong> <span>for other modules</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>10</strong> <span>Username searches</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>10</strong> <span>AI Geolocation searches</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>10</strong> <span>Face recognition searches</span>
              </li>
            </ul>
            <button
              className="cta-btn"
              onClick={() => redirectToCheckout(products[0].priceId)}
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={faCartShopping} />
              {isLoading ? "Loading..." : "Purchase"}
            </button>
            {/*<ul className="features">*/}
            {/*  <li>*/}
            {/*    <span>*/}
            {/*      <b>*/}
            {/*        (You must provide same email for registration and purchase)*/}
            {/*      </b>*/}
            {/*    </span>*/}
            {/*  </li>*/}
            {/*</ul>*/}
          </div>
          <div className="pricing-card standard hover">
            <div className="heading">
              <h4>Max</h4>
              {/*           <p>for medium-sized businesses</p> */}
            </div>
            <p className="price">
              $89
              <sub>/month</sub>
            </p>
            <ul className="features">
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>250</strong>{" "}
                <span>
                  Shodan (IoT, ICS, Exposed Cameras, Vulnerabilities) requests
                </span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>500</strong>{" "}
                <span>
                  Wigle data points to retrieve (Wifi, Bluetooth, Cell Towers)
                </span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>20</strong>{" "}
                <span>Hashtag searches (Instagram, Facebook, Tiktok)</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>100</strong> <span>for other modules</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>20</strong> <span>Username searches</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>20</strong> <span>AI Geolocation searches</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>20</strong> <span>Face recognition searches</span>
              </li>
            </ul>
            <button
              className="cta-btn"
              onClick={() => redirectToCheckout(products[1].priceId)}
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={faCartShopping} />
              {isLoading ? "Loading..." : "Purchase"}
            </button>
          </div>
          <div className="pricing-card premium hover">
            <div className="heading">
              <h4>PREMIUM</h4>
              {/*           <p>for small businesses</p> */}
            </div>
            <p className="price">
              ---
              <sub>/month</sub>
            </p>
            <ul className="features">
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>Unlimited </strong> <span>API Access</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>Unlimited</strong>{" "}
                <span>
                  Shodan (IoT, ICS, Exposed Cameras, Vulnerabilities) requests
                </span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>Unlimited</strong>{" "}
                <span>
                  Wigle data points to retrieve (Wifi, Bluetooth, Cell Towers)
                </span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>Unlimited</strong> <span>for other modules</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>Multiple </strong> <span>accounts</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>Separate </strong> <span>environment</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>Training </strong> <span>included</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>Unlimited </strong> <span>Username search</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>Unlimited </strong> <span>AI Photo Geolocation</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} />
                <strong>Unlimited </strong>{" "}
                <span>Face Recognition searches</span>
              </li>
            </ul>
            <button className="cta-btn">
              <FontAwesomeIcon icon={faMessage} />
              <a href="mailto:contact@offensiveosint.io">Contact</a>
            </button>
          </div>
        </div>
      )}
    </section>
  );
};
