export default function ExposedCamerasIcon() {
  return (
    <svg
      height="100%"
      width="100%"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 470 470"
    >
      <g>
        <path d="M215.5,263h32c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-32c-4.142,0-7.5,3.358-7.5,7.5S211.358,263,215.5,263z" />
        <path
          d="M424.32,339.525c-0.215-0.108-0.436-0.205-0.66-0.292l-56.815-21.864c-5.941-2.286-10.503-7.062-12.517-13.101
		l-2.277-6.832c12.723-18.265,17.624-36.194,5.689-54.097c-5.402-8.104-12.05-13.942-20.244-17.787
		C341.063,212.308,343,198.175,343,183.5V183h0.5c8.547,0,15.5-6.953,15.5-15.5v-16c0-5.827-3.235-10.908-8-13.555V119.5
		C351,53.607,297.393,0,231.5,0S112,53.607,112,119.5v18.445c-4.765,2.647-8,7.728-8,13.555v16c0,8.547,6.953,15.5,15.5,15.5h0.5
		v0.5c0,14.675,1.937,28.808,5.504,42.053c-8.195,3.845-14.842,9.683-20.244,17.787c-11.935,17.902-7.034,35.831,5.689,54.097
		l-2.277,6.832c-2.013,6.04-6.575,10.815-12.517,13.101L39.34,339.233c-0.225,0.086-0.445,0.184-0.66,0.292
		C19.756,348.987,8,368.008,8,389.166V439.5C8,452.458,18.542,463,31.5,463h200h200c12.958,0,23.5-10.542,23.5-23.5v-50.334
		C455,368.008,443.244,348.987,424.32,339.525z M345.26,251.66c4.378,6.567,7.955,16.379-3.345,34.104
		c-5.868,9.204-14.678,18.991-24.751,29.228c-3.647-1.04-6.163-4.337-6.163-8.165V279.5c0-0.341-0.031-0.674-0.075-1.003
		c9.014-11.128,16.461-24.134,21.903-38.487C337.75,242.67,341.773,246.43,345.26,251.66z M138.352,215h1.848
		c5.284,0,10.25,2.058,14.006,5.813C160.775,227.382,169.51,231,178.8,231h105.4c9.29,0,18.024-3.618,24.613-10.207
		c3.736-3.736,8.703-5.793,13.987-5.793h1.848c-11.119,51.215-48.663,89-93.148,89S149.471,266.215,138.352,215z M160.372,323.2
		c4.141-4.262,6.628-10.055,6.628-16.372V293.97c18.219,15.756,40.485,25.03,64.5,25.03s46.281-9.274,64.5-25.03v12.857
		c0,6.317,2.486,12.109,6.627,16.371C280.811,336.821,256.337,344,231.5,344C206.664,344,182.188,336.822,160.372,323.2z M231.5,359
		c17.119,0,34.061-3.067,50.229-8.972c-20.476,20.803-39.967,43.388-50.229,68.686c-10.262-25.298-29.754-47.884-50.229-68.687
		C197.439,355.933,214.381,359,231.5,359z M344,167.5c0,0.276-0.224,0.5-0.5,0.5H327v-17h16.5c0.276,0,0.5,0.224,0.5,0.5V167.5z
		 M287,168v-17h25v17H287z M239,168v-17h33v17H239z M191,168v-17h33v17H191z M151,168v-17h25v17H151z M296,136h-33v-16.5
		c0-30.429-2.556-59.112-7.196-80.768c-1.597-7.454-3.359-13.783-5.309-19.031c9.454,4.791,18.21,13.15,25.608,24.658
		C288.934,64.319,296,91.004,296,119.5V136z M215,136v-16.5c0-27.144,2.123-53.161,5.978-73.258
		c3.876-20.206,8.362-28.397,10.522-30.734c2.16,2.337,6.646,10.528,10.522,30.734C245.877,66.339,248,92.356,248,119.5V136H215z
		 M212.505,19.702c-1.951,5.248-3.712,11.577-5.309,19.031C202.556,60.388,200,89.071,200,119.5V136h-33v-16.5
		c0-28.496,7.066-55.181,19.897-75.14C194.295,32.851,203.051,24.493,212.505,19.702z M336,119.5V136h-25v-16.5
		c0-31.337-7.912-60.903-22.279-83.251c-1.655-2.575-3.378-5.014-5.156-7.33C314.878,46.987,336,80.823,336,119.5z M179.436,28.918
		c-1.779,2.317-3.501,4.756-5.156,7.33C159.912,58.597,152,88.163,152,119.5V136h-25v-16.5
		C127,80.823,148.122,46.987,179.436,28.918z M119,167.5v-16c0-0.276,0.224-0.5,0.5-0.5H136v17h-16.5
		C119.224,168,119,167.776,119,167.5z M184.025,183c-0.011,0.166-0.025,0.331-0.025,0.5v8c0,4.142,3.358,7.5,7.5,7.5
		s7.5-3.358,7.5-7.5v-8c0-0.169-0.014-0.334-0.025-0.5h65.05c-0.011,0.166-0.025,0.331-0.025,0.5v8c0,4.142,3.358,7.5,7.5,7.5
		s7.5-3.358,7.5-7.5v-8c0-0.169-0.014-0.334-0.025-0.5H328v0.5c0,5.597-0.314,11.104-0.909,16.5H322.8
		c-9.29,0-18.024,3.618-24.613,10.207c-3.736,3.736-8.703,5.793-13.987,5.793H178.8c-5.284,0-10.25-2.058-14.006-5.813
		C158.225,203.618,149.49,200,140.2,200h-4.292c-0.594-5.396-0.909-10.903-0.909-16.5V183H184.025z M117.74,251.66
		c3.487-5.23,7.51-8.99,12.432-11.65c5.442,14.353,12.889,27.359,21.903,38.487c-0.044,0.329-0.075,0.662-0.075,1.003v27.328
		c0,3.828-2.516,7.125-6.163,8.165c-10.073-10.237-18.884-20.024-24.751-29.228C109.786,268.039,113.362,258.228,117.74,251.66z
		 M23,439.5v-50.334c0-15.323,8.451-29.112,22.079-36.068l56.463-21.729c9.337-3.592,16.669-10.793,20.455-19.958
		c8.845,10.13,19.357,20.385,30.052,30.816c31.753,30.969,67.484,65.83,71.556,105.774H103v-24.5c0-4.142-3.358-7.5-7.5-7.5
		s-7.5,3.358-7.5,7.5V448H31.5C26.813,448,23,444.187,23,439.5z M440,439.5c0,4.687-3.813,8.5-8.5,8.5H375v-24.5
		c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V448H239.395c4.072-39.944,39.802-74.805,71.556-105.774
		c10.695-10.431,21.207-20.686,30.052-30.816c3.786,9.165,11.118,16.366,20.455,19.958l56.463,21.729
		C431.549,360.054,440,373.842,440,389.166V439.5z"
        />
      </g>
    </svg>
  );
}
