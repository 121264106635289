import { ReactNode, createContext } from "react";

type ActiveRecordContextType = {
  activeRecord: SingleRecord | null;
  changeActiveRecord: (record: SingleRecord) => void;
};

export const ActiveRecordContext = createContext<ActiveRecordContextType>(
  null!
);

type Props = {
  children: ReactNode;
} & ActiveRecordContextType;

export default function ActiveRecordProvider({
  children,
  activeRecord,
  changeActiveRecord: handleChange,
}: Props) {
  return (
    <ActiveRecordContext.Provider
      value={{
        activeRecord,
        changeActiveRecord: (record) => handleChange(record),
      }}
    >
      {children}
    </ActiveRecordContext.Provider>
  );
}
