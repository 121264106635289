import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { ICON_TYPES, MODULES } from "../../const/search";
import List from "./ui/list";
import Timestamp from "./ui/timestamp";
import { Card, CardTitle } from "../ui/card";

export default function SurveillanceStatsList({
  activity_types,
  ...records
}: SurveillanceStats) {
  const { color } = MODULES.find((item) => item.value === "surveillance");
  const activityTypes = Object.keys(activity_types);
  return (
    <Card
      className={`p-4 space-y-4 w-max rounded ${
        activityTypes.length > 0 ? "grid grid-cols-2" : "flex flex-col"
      } gap-4`}
    >
      <div className="flex gap-4">
        <div className="w-11 h-11 rounded overflow-hidden relative">
          <img
            className="absolute inset-0 w-full h-full object-cover object-center"
            src={ICON_TYPES["surveillance"]}
            alt=""
          />
        </div>
        <div>
          <CardTitle>Surveillance</CardTitle>
          <p className="text-[var(--text-color)] opacity-80 text-lg">Stats</p>
        </div>
      </div>
      <Timestamp {...records} stroke={color} />
      {activityTypes.length > 0 && (
        <div className="col-[2/3] row-[1/3]">
          <List
            records={activityTypes.map(
              (key) => `${key}: ${activity_types[key]}`
            )}
            title="Activity types"
            icon={faMagnifyingGlass}
            color={color}
          />
        </div>
      )}
    </Card>
  );
}
