import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import Markdown from "react-markdown";
import { ScrollArea } from "../ui/scroll-area";
import { Button } from "../ui/button";
import { useState } from "react";

export default function Analysis({ analysis }: { analysis: string }) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      <Button
        size="sm"
        className="max-w-max"
        variant="outline"
        onClick={() => setIsOpen(true)}
      >
        Analysis
      </Button>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="px-0 max-w-4xl">
          <DialogHeader className="px-6">
            <DialogTitle>Analysis</DialogTitle>
          </DialogHeader>
          <ScrollArea className="max-h-96 px-6">
            <Markdown
              className="grid gap-2"
              components={{
                h2: ({ children, ...props }) => (
                  <h2
                    {...props}
                    className="text-2xl bg-pink dark:bg-blue text-[#F5F5F5] dark:text-[#252525] rounded-md py-2 px-4 max-w-max font-medium mt-2 mb-1"
                  >
                    {children}
                  </h2>
                ),
                h3: ({ children, ...props }) => (
                  <h3
                    {...props}
                    className="text-xl bg-pink dark:bg-blue text-[#F5F5F5] dark:text-[#252525] rounded-md py-2 px-4 max-w-max font-medium mt-2 mb-1"
                  >
                    {children}
                  </h3>
                ),
                h4: ({ children, ...props }) => (
                  <h4
                    {...props}
                    className="text-lg bg-pink dark:bg-blue text-[#F5F5F5] dark:text-[#252525] rounded-md py-2 px-4 max-w-max font-medium mt-2 mb-1"
                  >
                    {children}
                  </h4>
                ),
                p: ({ children, ...props }) => (
                  <p
                    {...props}
                    className="text-lg text-base text-[#252525] dark:text-[#F5F5F5] leading-relaxed"
                  >
                    {children}
                  </p>
                ),
                ul: ({ children, ...props }) => (
                  <ul {...props} className="grid gap-1">
                    {children}
                  </ul>
                ),
                ol: ({ children, ...props }) => (
                  <ol {...props} className="grid gap-1">
                    {children}
                  </ol>
                ),
                li: ({ children, ...props }) => (
                  <li
                    {...props}
                    className="text-base text-[#252525] dark:text-[#F5F5F5] leading-relaxed"
                  >
                    {children}
                  </li>
                ),
              }}
            >
              {analysis}
            </Markdown>
          </ScrollArea>
          <DialogFooter className="px-6">
            <DialogClose asChild>
              <Button
                type="button"
                variant="outline"
                className="text-[#252525] dark:text-[#F5F5F5]"
              >
                Close
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
