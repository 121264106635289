import { ICON_TYPES } from "../../const/search";
import { Card, CardTitle } from "../ui/card";
import Timestamp from "./ui/timestamp";
import UserActivity from "./ui/user-activity";

export default function DefaultStatsList({
  name,
  activity,
  ...records
}: Activity & Records & { name: string }) {
  if (!records.first && !records.last && !activity) return <></>;
  const icon = ICON_TYPES[name as keyof typeof ICON_TYPES];
  return (
    <Card className="p-4 space-y-4 w-max rounded flex flex-row gap-4">
      <div className="flex flex-col justify-between gap-4">
        <div className="flex items-center gap-4 h-max">
          <div className="w-11 h-11 rounded overflow-hidden relative">
            {icon && (
              <img
                className="absolute inset-0 w-full h-full object-cover object-center"
                src={icon}
                alt=""
              />
            )}
          </div>
          <div>
            <CardTitle className="text-xl">{name}</CardTitle>
            <p className="text-[var(--text-color)] opacity-80 text-lg">Stats</p>
          </div>
        </div>
        {records.first && records.last && (
          <Timestamp stroke="#376187" {...records} />
        )}
      </div>
      {activity && (
        <div className="self-end">
          <UserActivity
            stroke="#376187"
            className="max-h-[10rem]"
            activity={activity}
          />
        </div>
      )}
    </Card>
  );
}
