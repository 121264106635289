import { useAuth0 } from "@auth0/auth0-react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { getRoute } from "../../utils/search/lib";
import { TRANSPORT_MODULES, TRANSPORT_TYPES } from "../../const/search";

type RenderRouteProps = {
  item_id: number;
  type: string;
};

type State = {
  markers: Marker[];
  setMarkers: Dispatch<SetStateAction<Marker[]>>;
};

type ReducedMarkers = {
  regular: Marker[];
  transport: Marker[];
};

const UPDATE_RATE = 0.2; // seconds

const updateLocation = (markers: Marker[]): Marker[] => {
  return markers.map(({ type, ...props }) => {
    const objects = props.objects.map((obj) => {
      if (
        !obj.location ||
        !obj.heading ||
        !obj.speed ||
        (type === "planes" && !obj.altitude)
      )
        return obj;
      const { location, heading, speed } = obj;
      // Convert speed from knots to nautical miles per hour
      const transportSpeed =
        TRANSPORT_MODULES.find(({ name }) => name === type)?.speed || 1;
      const speedInNauticalMilesPerHour =
        parseInt(speed) / (10 * transportSpeed);

      // Convert time interval to hours
      const timeIntervalInHours = UPDATE_RATE / 3600; // 0.25 second

      // Calculate the distance traveled in this time interval
      const distanceTraveled =
        speedInNauticalMilesPerHour * timeIntervalInHours;
      const latitudeChange =
        distanceTraveled * Math.cos((parseFloat(heading) * Math.PI) / 180);
      const longitudeChange =
        distanceTraveled * Math.sin((parseFloat(heading) * Math.PI) / 180);
      const lat = Math.round((location.lat + latitudeChange) * 1e5) / 1e5;
      const lng = Math.round((location.lng + longitudeChange) * 1e5) / 1e5;

      return {
        ...obj,
        location: {
          lat,
          lng,
        },
      };
    });
    return {
      objects,
      type,
    };
  });
};

export default function useTransportRoute({ markers, setMarkers }: State) {
  const { getAccessTokenSilently } = useAuth0();
  const [path, setPath] = useState<Coords[]>([]);
  const timeout = useRef<number | null>(null);

  const renderRoute = async (props: RenderRouteProps) => {
    const token = await getAccessTokenSilently();
    const data = await getRoute(token, props);
    setPath(data.path);
  };

  useEffect(() => {
    const { transport, regular }: ReducedMarkers =
      Array.isArray(markers) && markers.length > 0
        ? markers.reduce(
            (prev, curr) =>
              TRANSPORT_TYPES.includes(curr.type)
                ? { ...prev, transport: [...prev.transport, curr] }
                : { ...prev, regular: [...prev.regular, curr] },
            { transport: [], regular: [] } as ReducedMarkers
          )
        : ({ transport: [], regular: [] } as ReducedMarkers);
    if (transport.length === 0) return;
    timeout.current = window.setTimeout(
      () => setMarkers([...regular, ...updateLocation(transport)]),
      UPDATE_RATE * 1000
    );
    return () => {
      timeout.current && window.clearTimeout(timeout.current);
    };
  }, [markers, setMarkers]);

  return {
    path,
    setPath,
    renderRoute,
  };
}
