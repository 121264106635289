import { ICON_TYPES, MODULES } from "../../../const/search";
import PriceChart from "../ui/price";
import List from "../ui/list";
import { Card, CardTitle } from "@/components/ui/card";

export default function CraigslistStatsList({ names, price }: CraigslistStats) {
  const { color } = MODULES.find((item) => item.value === "craigslist");
  return (
    <Card className="p-4 gap-4 w-max rounded flex items-stretch flex-row">
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="w-11 h-11 rounded overflow-hidden relative">
            <img
              className="absolute inset-0 w-full h-full object-cover object-center"
              src={ICON_TYPES["craigslist"]}
              alt=""
            />
          </div>
          <div>
            <CardTitle className="text-xl">Craigslist</CardTitle>
            <p className="text-[var(--text-color)] opacity-80 text-lg">Stats</p>
          </div>
        </div>
        <List records={names} title={"Names"} color={color} />
      </div>
      <PriceChart price={price} color={color} />
    </Card>
  );
}
