import { ICON_TYPES, MODULES } from "../../../const/search";
import PriceChart from "../ui/price";
import List from "../ui/list";
import { Card, CardTitle } from "@/components/ui/card";

export default function ZillowStatsList({ brokers, price }: ZillowStats) {
  const { color } = MODULES.find((item) => item.value === "zillow");
  return (
    <Card className="p-4 w-max rounded flex-row flex items-stretch gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="w-11 h-11 rounded overflow-hidden relative">
            <img
              className="absolute inset-0 w-full h-full object-cover object-center"
              src={ICON_TYPES["zillow"]}
              alt=""
            />
          </div>
          <div>
            <CardTitle className="text-xl">Zillow</CardTitle>
            <p className="text-[var(--text-color)] opacity-80 text-lg">Stats</p>
          </div>
        </div>
        <List
          records={Object.keys(brokers).map((key) => `${key}: ${brokers[key]}`)}
          title="Brokers"
          color={color}
        />
      </div>
      <PriceChart price={price} color={color} />
    </Card>
  );
}
