export default function TrafficIcon() {
  return (
    <svg
      height="100%"
      width="100%"
      version="1.1"
      id="Layer_1"
      fill="inherit"
      viewBox="0 0 508 508"
    >
      <g>
        <g>
          <path
            d="M205.043,105.3c-43.8,0-79.4,35.8-79.4,79.7c0,43.9,35.6,79.7,79.4,79.7s79.4-35.8,79.4-79.7S248.843,105.3,205.043,105.3
			z M205.043,236.5c-28.2,0-51.2-23.1-51.2-51.5c0-28.4,23-51.5,51.2-51.5s51.2,23.1,51.2,51.5
			C256.243,213.4,233.243,236.5,205.043,236.5z"
          />
        </g>
      </g>
      <g>
        <g>
          <ellipse cx="205.043" cy="185" rx="14.1" ry="14.2" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M493.943,415.3h-228.6c-7.8,0-14.1-6.4-14.1-14.2v-32.6h17.8c7.8,0,14.1-6.3,14.1-14.1v-42.6h72c7.8,0,14.1-6.3,14.1-14.1
			V189h9.5c7.2,0,13.3-5.4,14-12.6l17.1-160.8c0.4-4,0.1-15.6-14-15.6h-381.7c-14,0-14.4,11.6-14,15.6l17.1,160.8
			c0.8,7.2,6.8,12.6,14,12.6h9.5v108.6c0,7.8,6.3,14.1,14.1,14.1h72v42.6c0,7.8,6.3,14.1,14.1,14.1h17.8v34.1
			c0,58.2,47.2,105.5,105.1,105.5h230c7.8,0,14.1-6.3,14.1-14.1v-64.5C508.043,421.6,501.743,415.3,493.943,415.3z M40.743,72.4
			v58.1l-10.9-102.3h350.3l-10.9,102.3V72.4c0-7.8-6.3-14.1-14.1-14.1h-300.3C47.043,58.3,40.743,64.6,40.743,72.4z M68.943,283.4
			V86.5h272.1v196.9H68.943z M155.043,340.2v-28.5h99.9v28.5H155.043z M263.843,479.8c-42.4,0-76.9-34.7-76.9-77.3v-34.1h36V401
			c0,23.4,19,42.5,42.3,42.5h214.5v36.3H263.843z"
          />
        </g>
      </g>
    </svg>
  );
}
