import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useRef, useState } from "react";
import Toast from "../../components/Toast";
import toast from "react-hot-toast";
import {
  deleteUsername,
  getAllUsernames,
  getUsername,
  searchUsername,
} from "../../utils/username/lib";
import { getTaskInfo } from "../../utils/single-item/lib";
import { WorkspaceContext } from "../../providers/workspace";

const defaultStatus = {
  isLoading: false,
  iteration: 0,
  state: null,
  progress: 0,
};

export default function useUsernameSearch(username, module) {
  const { getAccessTokenSilently } = useAuth0();
  const [taskId, setTaskId] = useState(null);
  const [results, setResults] = useState([]);
  const [token, setToken] = useState(null);
  const timer = useRef(null);
  const [status, setStatus] = useState(defaultStatus);
  const [currentResultsData, setCurrentResultsData] = useState({
    username: null,
    module: null,
  });
  const [allUsernames, setAllUsernames] = useState([]);
  const { setWorkspace } = useContext(WorkspaceContext);

  async function handleSearch(e, initial) {
    e?.preventDefault();
    const body = initial || { username, module };
    setResults([]);
    setStatus((prev) => ({
      ...prev,
      iteration: 0,
      progress: 1,
      isLoading: true,
    }));
    setCurrentResultsData(body);
    try {
      const data = await searchUsername(token, body);
      if (data.error || !data.task_id) {
        setStatus(defaultStatus);
        return toast.custom((t) => (
          <Toast
            {...t}
            isError
            title="Error"
            subtitle={data.error || "Please, try again later"}
          />
        ));
      }
      setTaskId(data.task_id);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleUsernameSearch(body) {
    if (!token) return;
    setResults([]);
    setStatus((prev) => ({
      ...prev,
      progress: 20,
      isLoading: true,
      iteration: 0,
    }));
    try {
      const data = await getUsername(token, body);
      if (data.error) {
        toast.custom((t) => <Toast {...t} isError title={data.error} />);
      } else {
        setResults(data.results);
        setCurrentResultsData({ username: body.username, module: body.type });
      }
    } catch (err) {
      toast.custom((t) => (
        <Toast
          {...t}
          isError
          title="There was an error"
          subtitle="Please, try again later"
        />
      ));
    } finally {
      setStatus({ ...defaultStatus, progress: 100 });
    }
  }

  async function handleDelete(data) {
    setAllUsernames((prev) =>
      prev.filter(
        (item) => item.username !== data.username || item.type !== data.type
      )
    );
    const wasActive = username === data.username && data.type === module;
    if (wasActive) setResults([]);
    try {
      const response = await deleteUsername(token, data);
      if (response.error) {
        toast.custom((t) => <Toast {...t} isError title={response.error} />);
      }
    } catch (err) {
      toast.custom((t) => (
        <Toast
          {...t}
          isError
          title="There was an error"
          subtitle="Please, try again later"
        />
      ));
    }
  }

  useEffect(() => {
    if (!taskId || !token) return;
    timer.current = setTimeout(async () => {
      try {
        const {
          results: [{ state, result, percentage }],
        } = await getTaskInfo(token, taskId);
        switch (state) {
          case "PROGRESS":
          case "PENDING":
            setStatus((prev) => ({
              ...prev,
              state: state,
              iteration: prev.iteration + 1,
              progress: percentage || 20,
            }));
            break;
          case "SUCCESS":
            if (result.error) {
              toast.custom((t) => (
                <Toast
                  {...t}
                  isError
                  title={result.error}
                  subtitle="There was an error gathering information"
                />
              ));
            } else if (result.results.length > 0) {
              setResults(result.results);
              setAllUsernames((prev) => [
                ...prev,
                {
                  username: currentResultsData.username,
                  type: currentResultsData.module,
                },
              ]);
            }
            setStatus((prev) => ({
              ...prev,
              isLoading: false,
              state: null,
              progress: 100,
            }));
            break;
          default:
            break;
        }
      } catch (err) {
        setTaskId(null);
        setStatus(defaultStatus);
      }
    }, 1000);
    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, [token, taskId, status.iteration]);

  useEffect(() => {
    if (!getAccessTokenSilently || !setWorkspace) return;
    setWorkspace(null);
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const { usernames } = await getAllUsernames(token);
        setAllUsernames(usernames);
        setToken(token);
      } catch (err) {
        toast.custom((t) => (
          <Toast
            {...t}
            isError
            title="Error"
            subtitle="There was an error gathering information"
          />
        ));
      }
    })();
  }, [getAccessTokenSilently, setWorkspace]);

  return {
    currentResultsData,
    status,
    results,
    allUsernames,
    handleSearch,
    handleUsernameSearch,
    handleDelete,
  };
}
