import { Card, CardTitle } from "@/components/ui/card";
import { ICON_TYPES } from "../../../const/search";
import Timestamp from "../ui/timestamp";
import UserActivity from "../ui/user-activity";

export default function YoutubeStatsList({
  stroke,
  activity,
  ...records
}: YoutubeStats & Pick<UI, "stroke">) {
  return (
    <Card className="p-4 rounded max-h-full grid grid-cols-2 grid-rows-2 items-start gap-4">
      <div className="flex items-center gap-4">
        <div className="w-11 h-11 rounded overflow-hidden relative">
          <img
            className="absolute inset-0 w-full h-full object-cover object-center"
            src={ICON_TYPES["youtube"]}
            alt=""
          />
        </div>
        <div>
          <CardTitle className="text-xl">Youtube</CardTitle>
          <p className="text-[var(--text-color)] opacity-80 text-lg">
            User activity
          </p>
        </div>
      </div>
      <div className="self-end">
        <Timestamp stroke={stroke} {...records} />
      </div>
      <div className="col-[2/3] row-[1/3] h-full">
        <UserActivity stroke={stroke} activity={activity} />
      </div>
    </Card>
  );
}
