import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import CustomTooltip from "../ui/charts/CustomTooltip";
import renderCustomizedLabel from "../../utils/render-customized-label";
import { MODULES } from "../../const/search";

type Props = {
  markers: Marker[];
  date: string | null;
};

export default function TypeCounterChart({ markers, date }: Props) {
  return (
    <div className="flex flex-col gap-3 bg-[var(--sidebar-color)] rounded p-6">
      <h3 className="text-[var(--text-color)] font-medium">
        Objects by category
      </h3>
      {date ? (
        <ResponsiveContainer width={"100%"} height={500}>
          <PieChart>
            <Pie
              data={markers.map(({ type, objects }) => ({
                name: type,
                count: objects.length,
              }))}
              cx="50%"
              cy="50%"
              outerRadius="80%"
              fill="#8884d8"
              nameKey="name"
              dataKey="count"
              label={(props) => renderCustomizedLabel(props, 24)}
              labelLine={false}
              isAnimationActive={false}
            >
              {markers.map((entry, index) => (
                <Cell
                  fill={
                    MODULES.find((item) => item.value === entry.type)?.color ||
                    "#D9D9D9"
                  }
                  key={`cell-${index}`}
                />
              ))}
            </Pie>
            <Tooltip
              content={(props) => <CustomTooltip {...props} name="Count" />}
            />
            <Legend
              layout="vertical"
              verticalAlign="top"
              align="right"
              formatter={(value, { payload }) =>
                `${value} (${(payload as any)?.count})`
              }
            />
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <div className="flex-1 min-h-[4in] grid place-content-center">
          <p className="text-[var(--text-color)] text-lg">
            Pick a date on the timestamp
          </p>
        </div>
      )}
    </div>
  );
}
