export default function ExposedCamerasIcon() {
  return (
    <svg
      height="100%"
      width="100%"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 470 470"
      stroke="inherit"
      strokeWidth="22.089999999999996"
    >
      <g id="SVGRepo_bgCarrier"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <g>
            <g>
              <path d="M235,300c68.925,0,125-56.075,125-125S303.925,50,235,50s-125,56.075-125,125S166.075,300,235,300z M235,65 c60.654,0,110,49.346,110,110s-49.346,110-110,110s-110-49.346-110-110S174.346,65,235,65z"></path>
              <path d="M235,235c33.084,0,60-26.916,60-60s-26.916-60-60-60s-60,26.916-60,60S201.916,235,235,235z M235,130 c24.813,0,45,20.187,45,45s-20.187,45-45,45s-45-20.187-45-45S210.187,130,235,130z"></path>
              <path d="M235,205c16.542,0,30-13.458,30-30c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5c0,8.271-6.729,15-15,15 s-15-6.729-15-15s6.729-15,15-15c4.143,0,7.5-3.358,7.5-7.5c0-4.142-3.357-7.5-7.5-7.5c-16.542,0-30,13.458-30,30 S218.458,205,235,205z"></path>
              <path d="M410,365.755V175C410,78.505,331.495,0,235,0S60,78.505,60,175v190.755c-17.096,3.484-30,18.635-30,36.745v30 c0,20.678,16.822,37.5,37.5,37.5h335c20.678,0,37.5-16.822,37.5-37.5v-30C440,384.39,427.096,369.239,410,365.755z M425,432.5 c0,12.407-10.094,22.5-22.5,22.5h-335C55.093,455,45,444.907,45,432.5v-30c0-12.407,10.093-22.5,22.5-22.5h40 c4.142,0,7.5-3.358,7.5-7.5c0-4.142-3.358-7.5-7.5-7.5H75V175c0-88.224,71.776-160,160-160c88.225,0,160,71.776,160,160v190 H137.5c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h265c12.406,0,22.5,10.093,22.5,22.5V432.5z"></path>
              <path d="M402.5,395h-335c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h335c4.143,0,7.5-3.358,7.5-7.5 C410,398.358,406.643,395,402.5,395z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
