import { Card, CardTitle } from "@/components/ui/card";
import ModeSwitch from "./mode-switch";
import { useState } from "react";
import Regular from "./regular";
import OSM from "./osm";
import { ChevronLeft } from "lucide-react";
import { cn } from "@/utils/cn";

export default function Menu({ isMenuOpen, toggleMenu, ...props }: MenuProps) {
  const [isOSM, setIsOSM] = useState(false);

  return (
    <div className="relative z-40">
      <Card className="h-[calc(100vh-56px)] rounded-none border-0 border-t border-r px-0 !bg-background flex flex-col gap-4 overflow-hidden">
        <div className="flex items-center justify-between px-6 pb-2">
          <CardTitle>{isOSM ? "Tags" : "Filters"}</CardTitle>
          <ModeSwitch isOSM={isOSM} changeMode={(osm) => setIsOSM(osm)} />
        </div>
        {isOSM ? <OSM {...props} /> : <Regular {...props} />}
      </Card>
      <button
        onClick={toggleMenu}
        className="absolute left-full top-1/2 -translate-y-1/2 bg-sidebar text-font py-6 px-2 rounded-r-lg z-10"
      >
        <ChevronLeft
          size={24}
          className={cn(
            "transition-transform",
            isMenuOpen ? "rotate-180" : "rotate-0"
          )}
        />
      </button>
    </div>
  );
}
