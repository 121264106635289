import {
  MouseEvent,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ICON_TYPES } from "../../../const/search";
import { ActiveRecordContext } from "../../../providers/stats/ActiveRecordProvider";

import ActiveRecord from "./active-record";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import YearsNav from "../../timeline/years-nav";
import { Card, CardTitle } from "@/components/ui/card";

export default function Timeline({ timeline }: { timeline: SingleRecord[] }) {
  const sortedTimeline = timeline
    .filter((item) => item.timestamp || item.start_date || item.start_datetime)
    .sort((a, b) => {
      return (
        new Date(a.timestamp || a.start_date || a.start_datetime).getTime() -
        new Date(b.timestamp || b.start_date || b.start_datetime).getTime()
      );
    });

  const [isScrolled, setIsScrolled] = useState({
    right: true,
    left: true,
  });
  const ref = useRef<HTMLDivElement | null>(null);

  function onScroll() {
    if (!ref.current) return;
    const scroll = ref.current.scrollLeft;
    setIsScrolled({
      right:
        ref.current.scrollLeft >=
        ref.current.scrollWidth - ref.current.offsetWidth,
      left: scroll === 0,
    });
  }

  function handleScroll(
    e: MouseEvent<HTMLButtonElement>,
    direction: "left" | "right"
  ) {
    e.preventDefault();
    if (!ref.current) return;
    const scroll = ref.current.scrollLeft;
    const diff = ref.current.clientWidth / 2.5;
    ref.current.scrollTo({
      left: direction === "right" ? scroll + diff : scroll - diff,
      behavior: "smooth",
    });
  }

  function handleYearScroll(e: MouseEvent<HTMLButtonElement>, year: number) {
    e.preventDefault();
    const obj = timeline.find(
      (item) =>
        new Date(item.timestamp || item.start_date).getFullYear() === year
    );
    if (!obj) return;
    const element = document.getElementById(`tl-${obj.type}-${obj.id}`);
    element?.scrollIntoView({ behavior: "smooth", inline: "start" });
  }

  useLayoutEffect(() => {
    onScroll();
    window.addEventListener("resize", onScroll);
    return () => {
      window.removeEventListener("resize", onScroll);
    };
  }, []);

  const availableYears = useMemo(
    () =>
      new Set(
        timeline.map((item) =>
          new Date(item.timestamp || item.start_date).getFullYear()
        )
      ),
    [timeline]
  );

  return (
    <div className="bg-[var(--body-color)] hide-scroll min-w-full h-[17rem]">
      <div className="p-4 h-full flex gap-4">
        <ActiveRecord />
        <Card className="flex flex-col items-center flex-1 overflow-hidden p-0 py-2 gap-2">
          <div className="relative self-stretch flex items-center flex-1">
            <div
              ref={ref}
              onScroll={onScroll}
              className="p-12 rounded  h-full flex items-center relative hide-scroll overflow-x-auto overflow-y-hidden min-w-full"
            >
              <CardTitle className="text-lg absolute left-4 top-4">
                Timeline
              </CardTitle>
              <div className="flex items-center min-w-max gap-14 from-[#00E1FF] to-[#FF00CD] bg-gradient-to-r h-px w-full rounded-full justify-between">
                {sortedTimeline.map((record, i, list) => (
                  <RecordRef
                    {...record}
                    prevYear={new Date(
                      i === 0
                        ? record["timestamp"] || record["start_date"]
                        : list[i - 1].timestamp || list[i - 1].start_date
                    ).getFullYear()}
                    index={i}
                    key={`tl-${i}-${record.id}`}
                  />
                ))}
              </div>
            </div>

            <button
              onClick={(e) => handleScroll(e, "right")}
              className={`absolute right-8 rounded-full bg-[var(--primary-color2)] h-12 w-12 shadow z-10 transition-opacity ${
                isScrolled.right
                  ? "opacity-0 pointer-events-none"
                  : "opacity-100"
              }`}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
            <button
              onClick={(e) => handleScroll(e, "left")}
              className={`absolute left-8 rounded-full bg-[var(--primary-color2)] h-12 w-12 shadow z-10 transition-opacity ${
                isScrolled.left
                  ? "opacity-0 pointer-events-none"
                  : "opacity-100"
              }`}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          </div>
          <YearsNav
            years={availableYears}
            handleYearScroll={handleYearScroll}
          />
        </Card>
      </div>
    </div>
  );
}

const RecordRef = ({
  index,
  prevYear,
  ...props
}: SingleRecord & { index: number; prevYear: number }) => {
  const { id, type, timestamp, start_date } = props;
  const { activeRecord, changeActiveRecord } = useContext(ActiveRecordContext);
  const isEven = index % 2 === 0;
  const isActive =
    activeRecord && activeRecord.id === id && activeRecord.type === type;
  const date = new Date(timestamp || start_date);
  const icon = ICON_TYPES[type as keyof typeof ICON_TYPES];
  const year = date.getFullYear();
  const isFirstYear = prevYear !== year;

  return (
    <div
      id={`tl-${props.type}-${props.id}`}
      className={`h-4 w-4 rounded-full relative flex flex-col items-center justify-center ${
        isEven ? "bg-[#00E1FF]" : "bg-[#FF00CD]"
      }`}
    >
      <button
        onClick={() => changeActiveRecord(props)}
        className={`absolute px-2.5 py-2 rounded transition-colors ${
          isActive
            ? "bg-[var(--body-color)]"
            : "bg-[var(--sidebar-color)] hover:bg-[var(--body-color)]"
        } ${
          isEven ? "top-[150%]" : "bottom-[150%]"
        } flex flex-col gap-2 items-center`}
      >
        <span className="text-[var(--text-color)] w-max text-base">
          {date.toLocaleDateString() +
            " " +
            date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
        </span>
        {icon && (
          <div
            className={`w-9 h-9 rounded overflow-hidden relative ${
              isEven ? "order-last" : "order-first"
            }`}
          >
            <img
              className="absolute inset-0 w-full h-full object-cover object-center z-20"
              src={icon}
              alt=""
            />
          </div>
        )}
      </button>
      {isFirstYear && (
        <div
          className={`absolute w-px h-16 flex flex-col items-center ${
            isEven
              ? "bg-[#00E1FF] justify-start bottom-full"
              : "bg-[#FF00CD] justify-end top-full"
          }`}
        >
          <p
            className={`text-base font-medium bg-[var(--sidebar-color)] ${
              isEven ? "text-[#00E1FF]" : "text-[#FF00CD]"
            }`}
          >
            {year}
          </p>
        </div>
      )}
    </div>
  );
};
