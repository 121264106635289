import { useEffect, useRef, useState } from "react";

export default function ProgressBar({ title, value, maxValue }) {
  const boxRef = useRef(null);
  const [backgroundSize, setBackgroundSize] = useState(0);

  useEffect(() => {
    if (!boxRef.current) return;
    setBackgroundSize(boxRef.current.clientWidth);
    const resize = () => setBackgroundSize(boxRef.current.clientWidth);
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <h4 className="text-sm font-medium text-[var(--text-color)]">
        {title} - {value || 0} / {maxValue}
      </h4>
      <div
        ref={boxRef}
        className="shadow-[inset_0_1px_2px_rgba(0,0,0,0.25),0_1px_rgba(255,255,255,0.08)] rounded overflow-hidden bg-[rgba(0,0,0,0.25)] w-full h-6 relative"
      >
        <div
          style={{
            width:
              typeof value === "number" ? `${(value / maxValue) * 100}%` : 0,
            backgroundSize,
          }}
          className="bg-[linear-gradient(to_right,var(--primary-color2)_0%,var(--primary-color)_100%)] h-full"
        ></div>
      </div>
    </div>
  );
}
