import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ProgressBar from "../../components/home/ProgressBar";
import toast from "react-hot-toast";
import Toast from "../../components/Toast.jsx";
import Popup from "../../components/Popup.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button";

export const Settings = (props) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState({
    page: true,
    removal: false,
  });
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [, setMessage] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/getUserInfo/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setItems(data);
        setLoading((prev) => ({ ...prev, page: false }));
      } catch (e) {
        // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently]);

  if (loading.page) return <span>Loading...</span>;

  const handleSaveClick = async () => {
    // Create an object with the data you want to send to the server
    const requestData = {
      newPassword,
      newPasswordAgain,
    };

    try {
      const token = await getAccessTokenSilently();

      // Send a POST request to the server with the data
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/changePassword/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          method: "POST",
          body: JSON.stringify(requestData),
        }
      );
      const data = await response.json();
      setMessage(data.message);
      toast.custom((t) => (
        <Toast {...t} title="" subtitle={`${data.message}`} />
      ));
    } catch (error) {
      // Handle errors here, e.g., show an error message to the user
      console.error("Password change failed:", error);
    }
  };

  const handleChangeEmailClick = async () => {
    // Create an object with the data you want to send to the server
    const requestData = {
      newEmail,
    };

    try {
      const token = await getAccessTokenSilently();

      // Send a POST request to the server with the data
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/changeEmail/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          method: "POST",
          body: JSON.stringify(requestData),
        }
      );
      const data = await response.json();
      setMessage(data.message);
      toast.custom((t) => (
        <Toast {...t} title="" subtitle={` ${data.message}`} />
      ));
    } catch (error) {
      // Handle errors here, e.g., show an error message to the user
      console.error("Password change failed:", error);
    }
  };

  // open in new window
  function redirect_to_billing() {
    window.open(
      "https://billing.stripe.com/p/login/8wMeXS7ICcqp3Ti144",
      "_blank" // <- This is what makes it open in a new window.
    );
  }

  async function submitPopup() {
    setLoading((prev) => ({ ...prev, removal: true }));
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/deleteAccount/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      const data = await response.json();

      if (data.message === "Account deleted successfully") {
        // redirect to main page
        window.location.href = "https://os-surveillance.io";
      }
    } catch (error) {
      console.error("Fail:", error);
    } finally {
      setLoading((prev) => ({ ...prev, removal: true }));
    }
  }

  if (loading.page) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <l-waveform size={48} color="white" />
      </div>
    );
  }

  return (
    <section
      className={`home transition-all ${
        props.sidebarShown ? "md:ml-[250px]" : "md:ml-[88px]"
      } flex flex-col gap-4 px-4 md:px-8 w-full pt-16 pb-8`}
    >
      <div className="flex flex-col gap-8 xl:grid grid-cols-4">
        <div className="flex flex-col gap-6 bg-[var(--sidebar-color)] rounded-lg p-6">
          <h3 className="text-[var(--text-color)] font-medium">Account</h3>
          <div className="flex flex-col gap-3">
            <label htmlFor="email" className="text-[var(--text-color)] text-sm">
              Change email
            </label>
            <input
              className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
              type="email"
              id="email"
              placeholder={items.email}
              autoComplete={false}
              onChange={(e) => setNewEmail(e.target.value)}
            />
            <div className="w-max mt-2">
              <Button
                icon={faCheck}
                reverseFill
                onClick={handleChangeEmailClick}
                className="!py-2 !px-4"
              >
                Save
              </Button>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <label
              htmlFor="password"
              className="text-[var(--text-color)] text-sm"
            >
              Change password
            </label>
            <input
              className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
              type="password"
              id="password"
              placeholder="Type new password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <input
              className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
              type="password"
              placeholder="Type new password again"
              onChange={(e) => setNewPasswordAgain(e.target.value)}
            />
            <div className="w-max mt-2">
              <Button
                icon={faCheck}
                reverseFill
                onClick={handleSaveClick}
                className="!py-2 !px-4"
              >
                Save
              </Button>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <label htmlFor="email" className="text-[var(--text-color)] text-sm">
              Api key
            </label>
            <input
              className="!text-[var(--input-font)] rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
              type="api_key"
              id="api_key"
              value={items.api_key}
              // autoComplete={false}
              // onChange={(e) => setNewEmail(e.target.value)}
            />
          </div>

          <div className="flex justify-between gap-2 flex-wrap flex-1 items-end">
            <Button icon={faEdit} onClick={redirect_to_billing}>
              Manage your subscription
            </Button>

            <button
              onClick={() => setIsPopupVisible(true)}
              className="px-5 py-2.5 bg-[var(--primary-color)] text-white rounded-sm text-sm max-w-max border-[var(--primary-color)] border-[1px] hover:opacity-80 transition-opacity flex items-center gap-2"
            >
              <FontAwesomeIcon icon={faTrash} />
              Delete account
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-6 bg-[var(--sidebar-color)] rounded-lg p-6">
          <div className="text-[var(--text-color)] font-medium">
            Amount of all requests
          </div>
          <div className="flex flex-col gap-3">
            <ProgressBar
              title="Username Search"
              value={items.username_requests}
              maxValue={items.username_limit}
            />
            <ProgressBar
              title="AI Analysis"
              value={items.analysis_requests}
              maxValue={items.analysis_limit}
            />
            <ProgressBar
              title="AI Geolocation"
              value={items.ai_geolocate_requests}
              maxValue={items.ai_geolocate_limit}
            />
            <ProgressBar
              title="Face recognition searchers"
              value={items.facecheck_requests}
              maxValue={items.facecheck_limit}
            />
            <ProgressBar
              title="Address lookup"
              value={items.trestle_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Airbnb"
              value={items.airbnb_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Alltrails"
              value={items.alltrails_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Amber Alerts"
              value={items.amber_alerts_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Bikemap"
              value={items.bikemap_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Critical infrastructure"
              value={items.places_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Crimes"
              value={items.crimes_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Crimes 2"
              value={items.crimes2_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Eventbrite"
              value={items.eventbrite_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Events 1"
              value={items.events1_requests}
              maxValue={items.other_limit}
            />

            <ProgressBar
              title="Events 2"
              value={items.events2_requests}
              maxValue={items.other_limit}
            />

          </div>
        </div>
        <div className="flex flex-col gap-6 bg-[var(--sidebar-color)] rounded-lg p-6">
          <div className="text-[var(--text-color)] font-medium">
            Amount of all requests
          </div>
          <div className="flex flex-col gap-3">
              <ProgressBar
              title="Events 3"
              value={items.events3_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Events 4"
              value={items.events4_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Facebook (#)"
              value={items.facebook_requests}
              maxValue={items.hashtag_limit}
            />
            <ProgressBar
              title="Findpenguins"
              value={items.findpenguins_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Flickr"
              value={items.flickr_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Incidents"
              value={items.incidents_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Instagram (#)"
              value={items.instagram_requests}
              maxValue={items.hashtag_limit}
            />
            <ProgressBar
              title="Meetup"
              value={items.meetup_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Offenders"
              value={items.offenders_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Planes"
              value={items.planes_requests}
              maxValue={items.other_limit}
            />

            <ProgressBar
              title="Protests"
              value={items.acled_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Shodan"
              value={items.shodan_requests}
              maxValue={items.shodan_limit}
            />
            <ProgressBar
              title="Snapchat"
              value={items.snapchat_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="SportsTracker"
              value={items.sportstracker_requests}
              maxValue={items.other_limit}
            />
            {/*<ProgressBar*/}
            {/*    title="Strava"*/}
            {/*    value={items.strava_requests}*/}
            {/*    maxValue={items.other_limit}*/}
            {/*/>*/}
            <ProgressBar
              title="Surveillance cameras"
              value={items.surveillance_cameras_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Telegram"
              value={items.telegram_requests}
              maxValue={items.other_limit}
            />

          </div>
        </div>
        <div className="flex flex-col gap-6 bg-[var(--sidebar-color)] rounded-lg p-6">
          <div className="text-[var(--text-color)] font-medium">
            Amount of all requests
          </div>
          <div className="flex flex-col gap-3">
              <ProgressBar
              title="Ticketmaster"
              value={items.tickemaster_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="TikTok (#)"
              value={items.tiktok_requests}
              maxValue={items.hashtag_limit}
            />
            <ProgressBar
              title="Traffic cameras"
              value={items.traffic_cameras_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Twitter"
              value={items.twitter_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Vessel"
              value={items.vessels_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Vkontakte"
              value={items.vkontakte_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Waze"
              value={items.waze_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Weibo"
              value={items.weibo_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Wigle (data points)"
              value={items.wigle_requests}
              maxValue={items.wigle_limit}
            />
            <ProgressBar
              title="Wikiloc"
              value={items.wikiloc_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Windy"
              value={items.windy_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Worldcam"
              value={items.worldcam_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Youtube"
              value={items.youtube_requests}
              maxValue={items.other_limit}
            />
            <ProgressBar
              title="Zillow"
              value={items.zillow_requests}
              maxValue={items.other_limit}
            />
          </div>
        </div>
      </div>
      {isPopupVisible && (
        <Popup
          onClose={() => setIsPopupVisible(false)}
          onSubmit={submitPopup}
          isLoading={loading.removal}
          title="Are you sure you want to delete your account?"
          paragraph="Warning: This action is may lead to the data loss and cannot be reversed. Please, be careful!"
        />
      )}
    </section>
  );
};
