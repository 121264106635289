import { AppState, Auth0Provider } from "@auth0/auth0-react";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const redirect_uri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode[];
}) {
  const onRedirectCallback = (appState?: AppState) => {
    window.history.replaceState(
      {},
      document.title,
      appState?.returnTo || window.location.pathname
    );
  };

  if (!domain || !clientId || !audience || !redirect_uri) {
    throw new Error("One of the required environment variables is missing!");
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        audience,
        scope: "profile email read:api",
        redirect_uri: window.origin,
        // redirect_uri,
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
}
