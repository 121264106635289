import Toast from "@/components/Toast";
import { useWorkspace } from "@/providers/workspace";
import { getTaskInfo } from "@/utils/single-item/lib";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

export default function useNearby(onSuccess?: () => void) {
  const { workspace } = useWorkspace();
  const { type, item_id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [analysis, setAnalysis] = useState("");
  const [areLoading, setAreLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [results, setResults] = useState<Marker[]>([]);
  const timer = useRef<number | null>(null);

  async function onSearch() {
    if (!workspace) return;
    setAreLoading(true);
    const loading = toast.custom((t) => (
      <Toast
        isLoading
        {...t}
        title="Searching for activity nearby"
        subtitle="Activity will be added to the map"
      />
    ));
    const url =
      `${process.env.REACT_APP_API_SERVER_URL}/api/searchNearby/` +
      workspace.id +
      "/" +
      type +
      "/" +
      item_id;
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setIsDisabled(true);
        timer.current = window.setInterval(async () => {
          const taskId = data.task_id;
          const {
            results: [{ state, result }],
          } = await getTaskInfo(token, taskId);
          if (state === "SUCCESS") {
            result.analysis && setAnalysis(result.analysis);
            const results = (result.results || []) as Marker[];
            if (results.length > 0) {
              setResults(results);
              onSuccess && onSuccess();
            } else {
              toast.custom((t) => (
                <Toast {...t} isError title={"No results have been found!"} />
              ));
            }
          }
          if (state !== "PENDING" && state !== "PROGRESS") {
            timer.current && window.clearInterval(timer.current);
            toast.dismiss(loading);
            setAreLoading(false);
          }
        }, 1000);
      } else {
        setAreLoading(false);
        toast.dismiss(loading);
        data.error &&
          toast.custom((t) => (
            <Toast {...t} isError title="Error" subtitle={data.message} />
          ));
      }
    } catch (error) {
      console.error("There was an error!", error);
      setAreLoading(false);
      toast.custom((t) => (
        <Toast
          {...t}
          isError
          title="Error"
          subtitle={(error as Error).message}
        />
      ));
    }
  }

  useEffect(() => {
    setResults([]);
    setIsDisabled(false);
  }, [item_id, type]);

  return {
    analysis,
    isDisabled,
    results,
    areLoading,
    onSearch,
  };
}
