import { TILE_ATTRIBUTE_EXCLUSIONS } from "@/const/tile-items";
import AttributeField from "../tile-items/AttributeField";
import {
  FIELD_COL_SPAN,
  SINGLE_ATTRIBUTE_EXCLUSIONS,
} from "@/const/single-item";

function groupKeysIntoRows(keys: string[]): string[][] {
  const result: string[][] = [];

  for (let i = 0; i < keys.length; i++) {
    if (FIELD_COL_SPAN.includes(keys[i])) {
      result.push([keys[i]]); // Single-element row
    } else {
      // Handle a pair
      if (i + 1 < keys.length && !FIELD_COL_SPAN.includes(keys[i + 1])) {
        result.push([keys[i], keys[i + 1]]);
        i++; // Skip the next key because it's already used in this pair
      } else {
        result.push([keys[i]]); // Single-element row if no pair is possible
      }
    }
  }

  return result;
}

export default function Attributes({
  data,
  excludeKeys,
  exclusionsType = "tile",
}: {
  data: Record<string, any>;
  exclusionsType: "tile" | "single";
  excludeKeys?: string[];
}) {
  const exclusions =
    exclusionsType === "single"
      ? SINGLE_ATTRIBUTE_EXCLUSIONS
      : TILE_ATTRIBUTE_EXCLUSIONS;
  const type = (
    data.shodan_type ? data.shodan_type : data.type
  ) as keyof typeof exclusions;
  const keys = Object.keys(data).filter(
    (key) =>
      typeof data[key] !== "object" &&
      (!exclusions[type] ||
        ![...exclusions[type], ...(excludeKeys || [])].includes(key))
  );
  const tuples = groupKeysIntoRows(keys);
  return (
    <table className="w-full table-fixed">
      <tbody>
        {tuples.map((tuple, k) => (
          <RowRef tuple={tuple} data={data} key={tuple[0] + tuple[1] + k} />
        ))}
      </tbody>
    </table>
  );
}

const RowRef = ({
  tuple,
  data,
}: {
  tuple: string[];
  data: Record<string, any>;
}) => (
  <tr className="[&>td]:py-2 even:bg-black/5 dark:even:bg-white/5 [&>td]:px-1 [&>td:first-child]:pl-3 [&>td:last-child]:pr-3">
    <AttributeField
      cols={tuple.length === 1 ? 2 : 1}
      name={tuple[0]}
      value={data[tuple[0]]}
    />
    {tuple.length > 1 && (
      <AttributeField name={tuple[1]} value={data[tuple[1]]} />
    )}
  </tr>
);
