import { useContext } from "react";
import Popup from "../Popup";
import { TutorialContext } from "../../providers/tutorial";
import { useNavigate } from "react-router-dom";

export default function EntryPopup() {
  const navigate = useNavigate();
  const { isFinished, changeFinished, changeStep, step } =
    useContext(TutorialContext);
  if (isFinished || step !== -1) return;
  return (
    <Popup
      title="Welcome to Open Source Surveillance!"
      paragraph="To kick things off, we've prepared a quick and interactive tutorial to guide you through the key features and functionalities of our app."
      submitText="Show me!"
      cancelText="I know how to use the app"
      onSubmit={() => {
        navigate("/database");
        changeStep();
      }}
      onClose={() => changeFinished("true")}
    />
  );
}
