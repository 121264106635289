import { MarkerF } from "@react-google-maps/api";
import { ICON_TYPES, TRANSPORT_MODULES } from "../../const/search";

const google = (window.google = window.google ? window.google : {});

export default function CustomMarker(props) {
  const {
    position,
    clusterer,
    isAnimating,
    onClick,
    drawermarkers,
    setDrawerMarkers,
    onMouseOut,
    onMouseOver,
    isActive,
    rotation,
    children,
    visible,
    ...item
  } = props;

  const transportModule = TRANSPORT_MODULES.find(
    (mod) => mod.name === item.type
  );

  const transportTypeOption = transportModule?.type.options.find(({ label }) =>
    item[transportModule.type.field]
      ? item[transportModule.type.field].startsWith(label)
      : false
  );

  const { size, anchor, strokeColor, strokeWidth, fillColor } = transportModule
    ? transportTypeOption
      ? transportTypeOption
      : transportModule.type.default
    : { size: 5 };

  return (
    <MarkerF
      visible={visible}
      position={position}
      setDrawerMarkers={setDrawerMarkers}
      clusterer={transportModule ? undefined : clusterer}
      drawermarkers={drawermarkers}
      animation={(isAnimating || isActive) && google.maps.Animation.BOUNCE}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      icon={
        transportModule
          ? {
              scale: size / 100,
              path: ICON_TYPES[
                transportTypeOption
                  ? `${item.type}-${transportTypeOption.value}`
                  : `${item.type}-${transportModule.type.default.value}`
              ],
              rotation: item.heading ? Math.floor(parseFloat(item.heading)) : 0,
              anchor: new google.maps.Point(anchor.x, anchor.y),
              fillColor,
              fillOpacity: 1,
              strokeColor,
              strokeWeight: strokeWidth || 1,
            }
          : {
              scaledSize: new google.maps.Size(30, 30),
              url: item.searched
                ? ICON_TYPES[item.searched]
                : ICON_TYPES[item.type],
            }
      }
      options={{ item }}
    >
      {children}
    </MarkerF>
  );
}
