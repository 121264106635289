import { Link, useNavigate } from "react-router-dom";
import DatabaseMapContainer from "../map/DatabaseMapContainer";
import { useContext } from "react";
import { WorkspaceContext } from "../../providers/workspace";
import MapsProvider from "../../providers/MapsProvider";
import { Card } from "../ui/card";
import { cn } from "@/utils/cn";
import { Button } from "../ui/button";
import { Search } from "lucide-react";

type Props = {
  openPopup: (props: Pick<Area, "id" | "title">) => void;
  isNew: boolean;
};

export default function AreaRef({
  isNew,
  openPopup,
  ...area
}: PhotoArea & Props & PageProps) {
  const { setWorkspace } = useContext(WorkspaceContext);

  const navigate = useNavigate();
  const bounds = {
    north: area.north_lat,
    south: area.south_lat,
    east: area.east_lng,
    west: area.west_lng,
  };
  return (
    <Card
      className={cn(
        "relative flex flex-col rounded group",
        isNew ? "border-[var(--pink-color)]" : "border-transparent"
      )}
    >
      <div className="flex items-center justify-between relative">
        <h3 className="text-[var(--text-color)] font-medium text-xl">
          {area.title} {isNew ? <span className="new-badge">NEW</span> : ""}
        </h3>
        <div className="relative flex items-center gap-3">
          <Button
            variant="outline"
            className="h-9"
            onClick={() => openPopup({ title: area.title, id: area.id })}
          >
            Delete
          </Button>
          <Button className="h-9" asChild>
            <Link
              to="/search"
              className="flex items-center gap-2"
              onClick={() => {
                const { id, title } = area;
                setWorkspace({ id, title });
                navigate("/search");
              }}
            >
              <Search size={16} />
              Search
            </Link>
          </Button>
        </div>
      </div>
      <div
        className={`w-full h-[300px] xl:h-[360px] map-container overflow-hidden relative z-10 bg-[var(--sidebar-color)] rounded grid ${
          area.photo ? "grid-cols-2 gap-4" : "grid-cols-1"
        }`}
      >
        {area.photo && (
          <div className="h-[300px] xl:h-[360px] grid place-content-center">
            <img
              className="w-full h-full object-contain object-center max-w-full"
              src={area.photo}
              alt=""
            />
          </div>
        )}
        <MapsProvider>
          <DatabaseMapContainer items={area.items} bounds={bounds} />
        </MapsProvider>
      </div>
    </Card>
  );
}
