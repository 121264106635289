import { Card, CardTitle } from "@/components/ui/card";
import { ICON_TYPES } from "../../../const/search";
import Timestamp from "../ui/timestamp";
import UserActivity from "../ui/user-activity";

export default function FlickrStatsList({
  stroke,
  activity,
  ...records
}: FlickrStats & Pick<UI, "stroke">) {
  return (
    <Card className="p-4 space-y-4 w-max rounded grid grid-cols-2 gap-4">
      <div className="flex items-center gap-4 h-max">
        <div className="w-11 h-11 rounded overflow-hidden relative">
          <img
            className="absolute inset-0 w-full h-full object-cover object-center"
            src={ICON_TYPES["flickr"]}
            alt=""
          />
        </div>
        <div>
          <CardTitle className="text-xl">Flickr</CardTitle>
          <p className="text-[var(--text-color)] opacity-80 text-lg">Stats</p>
        </div>
      </div>
      <Timestamp stroke={stroke} {...records} />
      <div className="col-[2/3] row-[1/3] h-full flex flex-col self-end">
        <UserActivity
          stroke={stroke}
          activity={activity}
          className="flex-1 max-h-[10rem]"
        />
      </div>
    </Card>
  );
}
