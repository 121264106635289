export default function Events3Icon() {
  return (
      <svg fill="inherit" height="120%" width="100%" version="1.1" id="Layer_1" stroke="inherit" strokeWidth="10"
           viewBox="0 0 203 203">
<g>
	<g>
		<g>
			<path d="M154,157.558h-7v-36.75c0-24.124-19.638-43.75-43.777-43.75h-3.445C75.638,77.058,56,96.684,56,120.808v36.75h-7
				c-1.933,0-3.5,1.566-3.5,3.5v31.5c0,1.934,1.567,3.5,3.5,3.5h105c1.933,0,3.5-1.566,3.5-3.5v-31.5
				C157.5,159.124,155.933,157.558,154,157.558z M63,120.808c0-20.265,16.499-36.75,36.777-36.75h3.445
				c20.279,0,36.777,16.485,36.777,36.75v36.75H63V120.808z M150.5,189.058h-98v-24.5h7h84h7V189.058z"/>
          <path d="M101.5,94.558c-15.439,0-28,12.561-28,28c0,1.934,1.567,3.5,3.5,3.5s3.5-1.566,3.5-3.5c0-11.58,9.42-21,21-21
				c1.933,0,3.5-1.566,3.5-3.5C105,96.124,103.433,94.558,101.5,94.558z"/>
          <path d="M101.5,66.558c1.933,0,3.5-1.566,3.5-3.5V10.442c0-1.934-1.567-3.5-3.5-3.5s-3.5,1.566-3.5,3.5v52.616
				C98,64.993,99.567,66.558,101.5,66.558z"/>
          <path d="M149.227,84.058c0.896,0,1.791-0.342,2.475-1.025l22.273-22.275c1.367-1.367,1.367-3.582,0-4.949
				c-1.367-1.367-3.582-1.367-4.949,0l-22.273,22.275c-1.367,1.367-1.367,3.582,0,4.949
				C147.436,83.716,148.331,84.058,149.227,84.058z"/>
          <path d="M50.026,83.033c0.684,0.684,1.579,1.025,2.474,1.025s1.791-0.342,2.474-1.025c1.367-1.367,1.367-3.582,0-4.949
				L32.701,55.808c-1.367-1.367-3.582-1.367-4.949,0s-1.367,3.582,0,4.949L50.026,83.033z"/>
          <path d="M199.5,112.058H161c-1.933,0-3.5,1.566-3.5,3.5c0,1.934,1.567,3.5,3.5,3.5h38.5c1.933,0,3.5-1.566,3.5-3.5
				C203,113.624,201.433,112.058,199.5,112.058z"/>
          <path d="M42,112.058H3.5c-1.933,0-3.5,1.566-3.5,3.5c0,1.934,1.567,3.5,3.5,3.5H42c1.933,0,3.5-1.566,3.5-3.5
				C45.5,113.624,43.933,112.058,42,112.058z"/>
		</g>
	</g>
</g>
</svg>
  );
}
