import { faUserClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function UserActivity({
  stroke,
  className,
  activity,
}: UI & Activity) {
  return (
    <div className="h-full flex flex-col justify-end gap-2">
      <div className="flex items-center gap-1.5">
        <div style={{ color: stroke }} className="h-6 w-5">
          <FontAwesomeIcon icon={faUserClock} size="sm" />
        </div>
        <h4 className="text-[var(--text-color)] opacity-40 text-base">
          Activity
        </h4>
      </div>
      <div
        className={`rounded px-3 py-2 bg-[#D9D9D9]/10 overflow-y-auto overflow-x-hidden hide-scroll flex-1 flex flex-col gap-1 max-w-[14rem] ${className}`}
      >
        {Object.keys(activity).map((key) => (
          <p className="text-base text-[var(--text-color)]">
            {key}: {activity[key]}
          </p>
        ))}
      </div>
    </div>
  );
}
