import { faGlobe, faStar } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardTitle } from "../ui/card";
import { Button } from "../ui/button";

export default function FaceSearch({ photos }: { photos: FaceSearchPhoto[] }) {
  return (
    <Card>
      <CardTitle>Face Search</CardTitle>
      <div className="grid grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-8 mt-3">
        {photos.map((photo, i) => (
          <PhotoRef {...photo} key={`photo:${i}`} />
        ))}
      </div>
    </Card>
  );
}

const PhotoRef = ({ screenshot, url, score }: FaceSearchPhoto) => (
  <Card className="grid grid-rows-[16rem_1fr]">
    <div className="overflow-hidden flex items-center justify-center bg-[var(--body-color)] rounded-md relative z-10">
      <img className="object-contain w-full h-full" src={screenshot} alt="" />
    </div>
    <div className="grid grid-cols-2 items-center h-10">
      <Button variant="outline" asChild className="hover:opacity-100">
        <div>
          <FontAwesomeIcon icon={faStar} />
          Score: <span className="font-semibold">{score}</span>
        </div>
      </Button>
      <Button asChild>
        <Link to={url} target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faGlobe} />
          Source
        </Link>
      </Button>
    </div>
  </Card>
);
