import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { postLike } from "../../utils/single-item/lib";
import toast from "react-hot-toast";
import Toast from "../../components/Toast";

export default function useFavorite(
  { type, item_id, workspace_id }: SingleItemParams & { workspace_id: number },
  is_favorite = false
) {
  console.log({ is_favorite });
  const { getAccessTokenSilently } = useAuth0();
  const [isFavorite, setIsFavorite] = useState(is_favorite);

  async function handleFavorite() {
    if (!workspace_id)
      return toast.custom((t) => (
        <Toast
          {...t}
          title="No workspace found!"
          subtitle="Try resetting the workspace"
        />
      ));
    setIsFavorite((prev) => !prev);
    try {
      const token = await getAccessTokenSilently();
      const data = await postLike(
        token,
        workspace_id.toString(),
        type,
        item_id
      );
      toast.custom((t) => <Toast {...t} isSuccess title={`${data.message}`} />);
    } catch (e) {
      // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
      console.error(e);
      setIsFavorite((prev) => !prev);
    }
  }

  return {
    isFavorite,
    handleFavorite,
    setIsFavorite,
  };
}
