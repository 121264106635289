export default function TicketmasterIcon() {
  return (
    <svg
      height="100%"
      width="100%"
      version="1.1"
      id="Layer_1"
      viewBox="0 239.7 64 64"
    >
      <path d="M1.078 261.7h5.345l1.293-5.776 7.715-2.586-1.94 8.32h6.38l-1.078 5.345h-6.38l-2.586 11.982c-.216.647-.43 1.724-.43 2.76 0 1.724 1.078 2.76 2.76 2.76 1.293 0 2.586-.215 3.405-.647l-1.293 5.56c-1.293.215-2.586.647-3.836.647-4.914 0-8.32-1.94-8.32-7.284 0-2.155.43-4.267.862-6.422l2.155-9.396H0zm23.14 3.75l.647-2.974H31.5l-.647 3.62c1.94-2.76 5.13-4.267 8.534-4.267 3.2 0 6.638 1.94 7.07 5.345h.215c1.94-3.62 5.776-5.345 9.612-5.345 4.052 0 7.715 3.405 7.715 7.5 0 2.586-.647 5.345-1.293 7.5l-2.974 13.232H52.45l2.974-13.448c.43-1.724.862-4.267.862-6 0-2.155-1.5-3.405-3.405-3.405-4.914 0-6.207 5.776-7.07 9.612l-2.76 13.017h-7.284L38.74 276.4c.43-1.724.862-4.267.862-6 0-2.155-1.5-3.405-3.405-3.405-4.914 0-6.207 5.776-7.07 9.612l-2.76 13.017h-7.284z" />
    </svg>
  );
}
