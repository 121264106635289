export async function getAuthorSearchId(
  token: string,
  itemType: string,
  itemId: string
) {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/GetPeople/${itemType}/${itemId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const data = await response.json();

  if (data.error) {
    throw new Error(data.message || "Error");
  }

  return data.task_id;
}

export async function getAIGeolocationId(
  token: string,
  body: string
): Promise<ServerResponse<TaskId>> {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/geolocate-ai/`,
    {
      method: "POST",
      body,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const data = await response.json();

  if (data.error) {
    return {
      results: [],
      error: data.message,
    };
  }

  return {
    results: [{ task_id: data.task_id }],
  };
}

export async function getFaceSearch(
  token: string,
  body: SingleItemParams & { workspace_id: number }
): Promise<ServerResponse<TaskId>> {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/faceSearch`,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const data = await response.json();

  if (data.error) {
    // check if it returns "error" key or "task_id" key
    return {
      results: [],
      error: data.message,
    };
  }

  return {
    results: [data],
  };
}

export async function getTaskInfo<T>(
  token: string,
  searchId: string
): Promise<ServerResponse<TaskInfo<T>>> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/get-task-info/${searchId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      return {
        error: "An error occured, try again later!",
        results: [],
      };
    }

    const data = await response.json();

    return {
      results: [data],
    };
  } catch (err) {
    return {
      error:
        typeof err === "string" ? err : "An error occured, try again later!",
      results: [],
    };
  }
}

export async function postLike(
  token: string,
  workspaceId: string,
  itemType: string,
  itemId: string
) {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/addToFavorites/${workspaceId}/${itemType}/${itemId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data;
}

export async function changeLocation(
  token: string,
  workspaceId: number,
  itemType: string,
  itemId: string,
  location: Coords
) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/changeCoordinates`,
      {
        method: "POST",
        body: JSON.stringify({
          item_id: itemId,
          item_type: itemType,
          workspace_id: workspaceId,
          ...location,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      return {
        error: "An error occured, try again later!",
        results: [],
      };
    }

    return {
      results: [],
    };
  } catch (err) {
    return {
      error:
        typeof err === "string" ? err : "An error occured, try again later!",
      results: [],
    };
  }
}
