import { useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { TILE_ATTRIBUTE_EXCLUSIONS } from "@/const/tile-items";
import { Card } from "../ui/card";
import { Button } from "../ui/button";
import { Link } from "react-router-dom";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../ui/collapsible";
import { ArrowDown, ArrowUp, ChevronsUpDown } from "lucide-react";
import ExpandableRowsComponent from "./expandable-row";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";

type Props = {
  records: Record<string, any>[];
  type: string;
  authorDataVisible?: boolean;
};

export default function DataTable({ records, authorDataVisible, type }: Props) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const columns: ColumnDef<any>[] = useMemo(
    () =>
      records && records.length > 0
        ? Object.keys(records[0])
            .filter(
              (key) =>
                typeof records[0][key] !== "object" &&
                !(
                  TILE_ATTRIBUTE_EXCLUSIONS[
                    type as keyof typeof TILE_ATTRIBUTE_EXCLUSIONS
                  ] || []
                ).includes(key)
            )
            .map((key) => ({
              accessorKey: key,
              header: ({ column }) => (
                <button
                  className="flex items-center gap-2"
                  onClick={() =>
                    column.toggleSorting(column.getIsSorted() === "asc")
                  }
                >
                  {key}
                  {column.getIsSorted() &&
                    (column.getIsSorted() === "asc" ? (
                      <ArrowUp size={14} />
                    ) : (
                      <ArrowDown size={14} />
                    ))}
                </button>
              ),
            }))
        : [],
    [records, type]
  );

  const table = useReactTable({
    data: records,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <Card className="pt-2 w-full">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((row) => (
            <TableRow key={row.id}>
              {row.headers.map((cell) => (
                <TableHead>
                  {cell.isPlaceholder
                    ? ""
                    : flexRender(
                        cell.column.columnDef.header,
                        cell.getContext()
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table
              .getRowModel()
              .rows.map((row) => (
                <RowRef
                  row={row}
                  type={type}
                  columnsLength={columns.length}
                  authorDataVisible={authorDataVisible}
                  key={row.id}
                />
              ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  );
}

type TableRowProps = {
  row: Row<any>;
  columnsLength: number;
  type: string;
  authorDataVisible?: boolean;
};

const RowRef = ({
  row,
  authorDataVisible,
  columnsLength,
  type,
}: TableRowProps) => {
  // const cols = Object.keys(record).filter((col) => columns.includes(col));
  return (
    <Collapsible asChild>
      <>
        <TableRow className="even:bg-black/5 dark:even:bg-white/5">
          {row.getVisibleCells().map((cell) => (
            <TableCell key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          ))}
          <TableCell>
            <Button
              asChild
              variant="outline"
              className="h-7 transition-none text-base"
            >
              <Link to={`/module/${type}/item/${row.original.id}`}>
                Details
              </Link>
            </Button>
          </TableCell>
          {authorDataVisible && (
            <TableCell>
              <CollapsibleTrigger asChild>
                <Button
                  variant="ghost"
                  className="h-7 transition-none text-base"
                >
                  {"Author(s)"}
                  <ChevronsUpDown size={14} />
                </Button>
              </CollapsibleTrigger>
            </TableCell>
          )}
        </TableRow>
        <CollapsibleContent asChild>
          <ExpandableRowsComponent
            data={row.original}
            colSpan={columnsLength + 2}
          />
        </CollapsibleContent>
      </>
    </Collapsible>
  );
};
