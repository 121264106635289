import { useMemo } from "react";
import { ICON_TYPES } from "../../const/search";
import filterHour from "../../utils/timeline/filter-hour";

type Props = {
  hour: number;
  activeHour?: number;
  changeHour: (hour: number) => void;
  items: SearchObject[];
};

export default function HourButton({
  hour,
  activeHour,
  items,
  changeHour,
}: Props) {
  const types = useMemo(
    () =>
      filterHour(items, hour).reduce(
        (prev, { type }) => (prev.includes(type) ? prev : [...prev, type]),
        [] as string[]
      ),
    [items, hour]
  );
  return (
    <button
      onClick={() => changeHour(hour)}
      className="flex flex-col items-center relative -top-1.5 gap-2 w-5 min-h-max"
    >
      {activeHour === hour && (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 47 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute bottom-full"
        >
          <path
            d="M23.5 35L0.550327 0.500015L46.4497 0.500015L23.5 35Z"
            fill="#FF00CD"
          />
        </svg>
      )}
      <div
        className={`h-3 w-3 rounded-full ${
          activeHour === hour
            ? "bg-[var(--primary-color)]"
            : "bg-[var(--primary-color2)]"
        }`}
      />
      <p className="text-[var(--text-color)] text-base">
        {hour < 10 ? "0" : ""}
        {hour}:00
      </p>
      <div
        style={{
          gridTemplateColumns: `repeat(${
            types.length > 1 ? (types.length > 2 ? "3" : "2") : "1"
          }, 1fr)`,
        }}
        className="grid"
      >
        {types.map((type) => (
          <div className="relative rounded h-10 w-10 flex items-center justify-center overflow-hidden">
            <img
              className="max-w-[80%] relative z-10"
              src={ICON_TYPES[type as keyof typeof ICON_TYPES]}
              alt=""
            />
            <div className="absolute opacity-80 backdrop-blur-sm inset-0 w-full h-full bg-[var(--sidebar-color)]" />
          </div>
        ))}
      </div>
    </button>
  );
}
