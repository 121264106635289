type Props = {
  center_position: Coords;
  start_position: Coords;
  stop_position: Coords;
  type: string;
};

export default async function renderDirections({
  center_position,
  start_position,
  stop_position,
  type,
}: Props) {
  if (type === "sports_tracker") {
    const waypoints = [{ location: center_position, stopover: true }];
    const directionsService = new google.maps.DirectionsService();
    return directionsService.route({
      origin: start_position,
      destination: stop_position,
      travelMode: google.maps.TravelMode.WALKING,
      waypoints,
    });
  } else {
    const directionsService = new google.maps.DirectionsService();
    return directionsService.route({
      origin: start_position,
      destination: stop_position,
      travelMode: google.maps.TravelMode.WALKING,
    });
  }
}
