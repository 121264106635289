import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/utils/cn";
import { Calendar } from "@/components/ui/calendar";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { format } from "date-fns";
import { CalendarIcon, InfoIcon } from "lucide-react";
import { Label } from "@/components/ui/label";
import useFilters from "@/providers/search/filters";

type Props = {
  type: "start" | "end";
};

export default function DateInput({ type }: Props) {
  const { startDate, endDate, setStartDate, setEndDate } = useFilters();
  const date = type === "start" ? startDate : endDate;
  return (
    <div className="flex flex-col gap-2">
      <Label className="text-sm font-normal opacity-80">
        {type.charAt(0).toUpperCase() + type.substring(1)} Date
      </Label>
      <div className="flex items-center relative">
        <Popover>
          <PopoverTrigger asChild>
            <button
              className={cn(
                "flex w-full px-3 py-2 overflow-hidden border border-slate-200 bg-white dark:border-[#1F242F] dark:bg-[#0C111D] rounded-md text-base outline-none",
                date ? "text-font" : "text-slate-500 dark:text-slate-400"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {date ? format(date, "PPP") : <span>Pick start date</span>}
            </button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0 bg-white dark:bg-[#0e1a25]">
            <Calendar
              mode="single"
              selected={date}
              onSelect={type === "start" ? setStartDate : setEndDate}
              initialFocus
              disabled={false}
            />
          </PopoverContent>
        </Popover>
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger
              className={cn(
                date ? "text-font" : "text-slate-500 dark:text-slate-400",
                "absolute right-3"
              )}
            >
              <InfoIcon size={16} />
            </TooltipTrigger>
            <TooltipContent>
              <p>Some modules won't work with date filter</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  );
}
