const Pagination = ({ currentPage, totalPage, onPageChange }) => {
  const pageNumbers = [...Array(totalPage).keys()].map((num) => num + 1);
  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((page) => (
          <li
            key={page}
            className={`page-item ${page === currentPage ? "active" : ""}`}
          >
            <button
              className="page-link transition-colors"
              onClick={() => onPageChange(page)}
            >
              {page}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
