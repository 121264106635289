export const geolocateUpload = async (
  token: string,
  base64_photo: string | ArrayBuffer | null,
  filename: string,
  { from_upload }: { from_upload?: boolean }
): Promise<ServerResponse<any>> => {
  const requestData = {
    photo: base64_photo,
    title: filename,
  };

  try {
    // Send a POST request to the server with the data
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/${
        from_upload ? "uploadLocations/" : "geolocatePhoto/"
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify(requestData),
      }
    );
    if (response.ok) {
      const data = await response.json();
      return {
        results: data,
      };
    }
    const data = await response.json();
    return {
      results: [],
        error: data.message,
//       error: "Make sure format is valid and try again.",
    };
  } catch (error) {
    // Handle errors here, e.g., show an error message to the user
    return {
      results: [],
      error: (error as Error).message,
    };
  }
};
