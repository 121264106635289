export default function NoMapResultsIcon() {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33333 23.8106C5.24772 25.1718 3.33333 27.0686 3.33333 29.1667C3.33333 33.3088 10.7952 36.6667 20 36.6667C29.2047 36.6667 36.6667 33.3088 36.6667 29.1667C36.6667 27.0686 34.7523 25.1718 31.6667 23.8106M30 13.3333C30 20.1062 22.5 23.3333 20 28.3333C17.5 23.3333 10 20.1062 10 13.3333C10 7.81048 14.4771 3.33333 20 3.33333C25.5228 3.33333 30 7.81048 30 13.3333Z"
        stroke="inherit"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 10L23 16"
        stroke="inherit"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 16L23 10"
        stroke="inherit"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
