import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonHTMLAttributes, ReactNode } from "react";

type Props = {
  children: string | ReactNode | ReactNode[];
  icon?: IconProp;
  reverseFill?: boolean;
  asChild?: boolean;
  preventHover?: boolean;
  iconColor?: string;
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button({
  children,
  icon,
  reverseFill = false,
  preventHover = false,
  className = "",
  asChild = false,
  iconColor = "inherit",
  ...props
}: Props) {
  return asChild ? (
    <div
      className={`justify-center disabled:opacity-60 transition-colors rounded-sm border-[1px] px-5 py-2.5 text-sm !outline-none flex items-center gap-2 ${
        reverseFill
          ? `!bg-[var(--input-font)] !text-[var(--primary-color-light)] border-[var(--input-font)] ${
              preventHover
                ? ""
                : "hover:!bg-white hover:dark:!bg-[var(--primary-color-light)] dark:hover:border-[#376187] hover:!text-[var(--input-font)]"
            }`
          : `!text-[var(--input-font)] !bg-pink-shade dark:!bg-[var(--primary-color-light)] ${
              preventHover
                ? ""
                : "dark:hover:!bg-[var(--input-font)] dark:hover:border-[var(--input-font)] dark:hover:!text-[var(--primary-color-light)] hover:!bg-[var(--pink-color)] hover:!text-white"
            } border-[var(--pink-color)] dark:border-[#376187]`
      } ${className}`}
    >
      {icon && (
        <div className="transition-colors" style={{ color: iconColor }}>
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      {children}
    </div>
  ) : (
    <button
      {...props}
      className={`justify-center disabled:opacity-60 transition-colors rounded-sm border-[1px] px-5 py-2.5 text-sm !outline-none flex items-center gap-2 ${
        reverseFill
          ? `!bg-[var(--input-font)] !text-[var(--primary-color-light)] border-[var(--input-font)] ${
              preventHover
                ? ""
                : "hover:!bg-white hover:dark:!bg-[var(--primary-color-light)] dark:hover:border-[#376187] hover:!text-[var(--input-font)]"
            }`
          : `!text-[var(--input-font)] !bg-pink-shade dark:!bg-[var(--primary-color-light)] ${
              preventHover
                ? ""
                : "dark:hover:!bg-[var(--input-font)] dark:hover:border-[var(--input-font)] dark:hover:!text-[var(--primary-color-light)] dark:active:!text-[var(--primary-color-light)] hover:!bg-[var(--pink-color)] dark:active:!bg-blue-active dark:active:!border-blue-active hover:!text-white"
            } dark:border-[#376187] border-[var(--pink-color)]`
      } ${className}`}
    >
      {icon && (
        <div className="transition-colors" style={{ color: iconColor }}>
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      {children}
    </button>
  );
}
